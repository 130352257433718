import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { CircularProgress, Button } from '@material-ui/core';

function SCButton({
  children,
  variant,
  className,
  startIcon,
  endIcon,
  disabled,
  loading,
  color,
  onClick,
  type,
}) {
  const theme = useTheme();
  return (
    <Button
      className={className}
      variant={variant}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      size="small"
      color={color}
      onClick={onClick}
      type={type}
    >
      {loading && <CircularProgress size={theme.Spacing.S} />}
      {!loading && children}
    </Button>
  );
}

SCButton.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

SCButton.defaultProps = {
  variant: 'default',
  className: '',
  children: undefined,
  startIcon: undefined,
  endIcon: undefined,
  disabled: false,
  loading: false,
  color: undefined,
  type: undefined,
  onClick: () => {},
};

export default SCButton;
