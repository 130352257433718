import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Select, MenuItem } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AlertTriangle } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.Spacing.M,
  },
  selectInput: {
    ...theme.Font.Paragraph.Paragraph2,
  },
  textLabel: {
    ...theme.Font.Paragraph.Paragraph4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textStyle: {
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral60,
  },
  spanLabel: {
    flex: 1,
  },
  icon: {
    width: theme.Spacing.S,
    height: theme.Spacing.S,
    marginRight: theme.Spacing.XS,
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    verticalAlign: 'center',
  },
}));

function getOptionsByCategory(category, classes) {
  return category.value.categories.map((indicator) => {
    return (
      <MenuItem key={indicator.name} value={indicator.name}>
        <div className={classes.optionContainer}>
          {category.icon && <img src={category.icon} alt="icon" className={classes.icon} />}
          <span>{indicator.name || indicator.value}</span>
        </div>
      </MenuItem>
    );
  });
}

const SCSelectGroupField = ({
  label,
  value,
  disabled,
  className,
  error,
  showError,
  onChange,
  onBlur,
  onFocus,
  helperText,
  options,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectValue, setSelectValue] = useState(value);
  function handleChange(event) {
    setSelectValue(event.target.value);
    onChange(event);
  }
  useEffect(() => {
    setSelectValue(value);
  }, [value]);
  return (
    <div className={classnames(classes.textFieldContainer, className)}>
      <InputLabel classes={{ root: classes.textLabel }} error={showError}>
        {label}
      </InputLabel>

      <Select
        value={selectValue}
        classes={{ root: classes.selectInput }}
        onChange={handleChange}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <ListSubheader>{options[0].value.name}</ListSubheader>
        {getOptionsByCategory(options[0], classes)}
        <ListSubheader>{options[1].value.name}</ListSubheader>
        {getOptionsByCategory(options[1], classes)}
        <ListSubheader>{options[2].value.name}</ListSubheader>
        {getOptionsByCategory(options[2], classes)}
        <ListSubheader>{options[3].value.name}</ListSubheader>
        {getOptionsByCategory(options[3], classes)}
        <ListSubheader>{options[4].value.name}</ListSubheader>
        {getOptionsByCategory(options[4], classes)}
      </Select>
      {!showError && helperText && (
        <FormHelperText classes={{ root: classes.textLabel }}>{helperText}</FormHelperText>
      )}
      {showError && (
        <FormHelperText classes={{ root: classes.textLabel }} error={showError}>
          <span className={classes.spanLabel}>{error}</span>
          <AlertTriangle size="15" color={theme.Colors.alertColor} />
        </FormHelperText>
      )}
    </div>
  );
};

SCSelectGroupField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  showError: PropTypes.bool,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

SCSelectGroupField.defaultProps = {
  value: '',
  label: '',
  disabled: false,
  className: '',
  error: undefined,
  showError: false,
  helperText: undefined,
  options: [],
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

export default SCSelectGroupField;
