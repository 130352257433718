import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { AlertTriangle } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.Spacing.XXS,
    marginBottom: theme.Spacing.XXS,
    ...theme.Font.Paragraph.Paragraph4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(218, 41, 28, 0.1)',
  },
  textError: {
    ...theme.Font.Paragraph.Paragraph4,
    marginLeft: theme.Spacing.XS,
  },
}));

const ErrorField = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.container}>
      <AlertTriangle size="15" color={theme.Colors.alertColor} />
      <FormHelperText classes={{ root: classes.textError }} margin="dense" error>
        {children}
      </FormHelperText>
    </div>
  );
};

ErrorField.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
};

ErrorField.defaultProps = {
  children: '',
};

export default ErrorField;
