import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PublicMainComponentHeader from './PublicMainComponentHeader';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.Colors.brandAccent,
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100%',
    overflow: 'auto',
  },
  innerContainer: {
    width: '70%',
    margin: 'auto',
  },
}));
function PublicMainComponent({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <PublicMainComponentHeader />
      <div className={classes.innerContainer}>{children}</div>
    </div>
  );
}

PublicMainComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
export default PublicMainComponent;
