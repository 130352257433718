import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paths from '../../router/router';
import translationKeys from '../../utils/translations/translationKeys';
import SCTextFieldPassword from '../../components/Form/Fields/TextFields/SCTextFieldPassword';
import SCTextFieldPasswordConfirm from '../../components/Form/Fields/TextFields/SCTextFieldPasswordConfirm';
import ErrorField from '../../components/Form/Errors/ErrorField';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.Spacing.M,
  },
  textHeader: {
    textAlign: 'center',
    margin: 0,
    textTransform: 'uppercase',
    ...theme.Font.Headline.H3,
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.L,
  },
  textSubtitle: {
    textAlign: 'center',
    margin: 0,
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral80,
    marginBottom: theme.Spacing.L,
  },
  formContainer: {
    position: 'relative',
    padding: theme.Spacing.L,
    ...theme.Elevations.medium,
  },
}));

function ResetPasswordForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userModel.userInfo);

  async function onSubmit(values) {
    await dispatch.userModel.resetMyPassword(values);
    history.replace(Paths.RESET_PASSWORD_SUCCESS);
  }

  async function fetchUser() {
    if (!userProfile || !userProfile.email) {
      await dispatch.userModel.signInWithToken();
    }
  }

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, [userProfile]);

  return (
    <div className={classes.formContainer}>
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          if (values.password === values.confirmPassword) {
            return {};
          }
          return { confirmPassword: t(translationKeys.GENERAL_CONFIRM_PASSWORD_INVALID) };
        }}
      >
        {({ handleSubmit, submitError, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <h3 className={classes.textHeader}> {t(translationKeys.RESET_PASSWORD_TITLE)}</h3>
            <p className={classes.textSubtitle}>{t(translationKeys.RESET_PASSWORD_SUBTITLE)}</p>
            <SCTextFieldPassword
              placeholder={t(translationKeys.GENERAL_PASSWORD)}
              helperText={t(translationKeys.CREATE_ACCOUNT_PASSWORD_INFO)}
            />
            <SCTextFieldPasswordConfirm
              placeholder={t(translationKeys.GENERAL_CONFIRM_PASSWORD)}
              password={values.password}
            />
            {submitError && <ErrorField>{submitError}</ErrorField>}
            <div className={classes.buttons}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={submitting || pristine}
              >
                {t(translationKeys.RESET_PASSWORD_SAVE_PASSWORD)}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
}

export default ResetPasswordForm;
