import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ErrorField from '../../../../../components/Form/Errors/ErrorField';
import translationKeys from '../../../../../utils/translations/translationKeys';
import SCTextFieldDefault from '../../../../../components/Form/Fields/TextFields/SCTextFieldDefault';
import SCButton from '../../../../../components/Buttons/SCButton';
import { ANSWERTYPE } from '../../../../../utils/constants';
import SCColorpickerFieldDefault from '../../../../../components/Form/Fields/Inputs/SCColorpickerFieldDefault';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.Spacing.XL,
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.Spacing.XS,
  },
  textHeader: {
    ...theme.Font.Headline.H4,
    textTransform: 'uppercase',
  },
}));

function SurveyCreateChapterForm({ onSubmit, onCancel, initialValues }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  async function onHandleSubmit(values) {
    if (!initialValues.title) {
      let response = {};
      response = await dispatch.surveyModel.createChapter({
        description: values.description,
        title: values.title,
        type: ANSWERTYPE.CHAPTER_CARD_QUESTION,
        backgroundColour: values.backgroundColour.toUpperCase(),
        underlineSubstring: values.underlineSubstring,
      });
      if (response.message) {
        return response;
      }
      onSubmit(response);
    } else {
      let response = {};
      response = await dispatch.surveyModel.editQuestion({
        uuid: initialValues.uuid,
        description: values.description,
        title: values.title,
        type: ANSWERTYPE.CHAPTER_CARD_QUESTION,
        backgroundColour: values.backgroundColour.toUpperCase(),
        underlineSubstring: values.underlineSubstring,
      });
      if (response.message) {
        return response;
      }
      onSubmit(response);
    }
    return true;
  }

  return (
    <Form onSubmit={onHandleSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitError, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <h4 className={classes.textHeader}> {t(translationKeys.SURVEY_QUESTION_CHAPTER_CARD)}</h4>
          <SCTextFieldDefault
            name="title"
            label={t(translationKeys.GENERAL_TITLE_TEXT)}
            placeholder={t(translationKeys.GENERAL_TITLE_TEXT)}
            required
          />
          <SCTextFieldDefault
            name="description"
            label={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_BODY_TEXT)}
            placeholder={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_BODY_TEXT)}
            minLength={20}
          />
          <SCColorpickerFieldDefault
            name="backgroundColour"
            label={t(translationKeys.GENERAL_COLOR)}
            required
          />
          <SCTextFieldDefault
            name="underlineSubstring"
            label={t(translationKeys.SURVEY_QUESTION_CHAPTER_CARD_UNDERLINE)}
            placeholder={t(translationKeys.SURVEY_QUESTION_CHAPTER_CARD_UNDERLINE)}
            required
          />
          {submitError && <ErrorField>{submitError}</ErrorField>}
          <div className={classes.buttons}>
            <SCButton
              variant="outlined"
              type="button"
              color="primary"
              onClick={onCancel}
              className={classes.button}
            >
              {t(translationKeys.GENERAL_CANCEL)}
            </SCButton>
            <SCButton
              variant="contained"
              type="submit"
              color="primary"
              disabled={submitting || pristine}
              loading={submitting}
              className={classes.button}
            >
              {t(translationKeys.GENERAL_SAVE)}
            </SCButton>
          </div>
        </form>
      )}
    </Form>
  );
}

SurveyCreateChapterForm.propTypes = {
  initialValues: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    indicator: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.object),
    backgroundColour: PropTypes.string,
    underlineSubstring: PropTypes.string,
    uuid: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

SurveyCreateChapterForm.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
  onCancel: () => {},
};
export default SurveyCreateChapterForm;
