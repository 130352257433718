import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { Avatar } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
  },
  avatar: {
    color: (props) => props.color,
    background: ({ background }) => background,
    width: '36px',
    height: '36px',
    ...theme.Font.Headline.H6,
  },
}));

const IconCell = ({ key, align, background, color, children }) => {
  const classes = useStyles({ color, background });
  return (
    <TableCell key={key} align={align}>
      <Avatar className={classes.avatar} color={color} background={background} sizes="36">
        {children}
      </Avatar>
    </TableCell>
  );
};

IconCell.propTypes = {
  key: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
};

IconCell.defaultProps = {
  key: '',
  align: 'center',
  children: '',
  background: 'rgba(0, 156, 166, 0.2)',
  color: '#009ca6',
};

export default IconCell;
