import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ErrorField from '../../../../../components/Form/Errors/ErrorField';
import translationKeys from '../../../../../utils/translations/translationKeys';
import SCTextFieldDefault from '../../../../../components/Form/Fields/TextFields/SCTextFieldDefault';
import SCButton from '../../../../../components/Buttons/SCButton';
import SCSelectFieldIcon from '../../../../../components/Form/Fields/Inputs/SCSelectFieldIcon';
import SCSelectGroupFieldDefault from '../../../../../components/Form/Fields/Inputs/SCSelectGroupFieldDefault';
import SCCheckBoxFieldDefault from '../../../../../components/Form/Fields/Inputs/SCCheckBoxFieldDefault';
import SCRadioOptionsField from '../../../../../components/Form/Fields/SCRadioOptionsField';
import { ANSWERTYPE, QUESTIONAREACOMPLETE } from '../../../../../utils/constants';
import smileyIcon from '../../../../../assets/icons/smiley.svg';
import targetIcon from '../../../../../assets/icons/target.svg';
import radioIcon from '../../../../../assets/icons/list.svg';
import amountIcon from '../../../../../assets/icons/trending-up.svg';
import democracyIcon from '../../../../../assets/icons/democracy.svg';
import educationIcon from '../../../../../assets/icons/education.svg';
import healthIcon from '../../../../../assets/icons/health.svg';
import livingIcon from '../../../../../assets/icons/living.svg';
import workIcon from '../../../../../assets/icons/work.svg';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.Spacing.L,
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.Spacing.XS,
  },
  textHeader: {
    ...theme.Font.Headline.H4,
    textTransform: 'uppercase',
  },
  horizontalLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectField: {
    width: '344px',
  },
}));

function getString(options = []) {
  return options.map((o) => o.title).join(' | ');
}

function getContentByType(type, initialValues, onChange, t) {
  let value = 0;
  if (
    getString(initialValues.answers) !== t(translationKeys.SURVEY_QUESTION_QUESTION_SCALE_TITLE_A)
  ) {
    value = 1;
  }
  switch (type) {
    case ANSWERTYPE.SMILEY_QUESTION:
      return (
        <SCRadioOptionsField
          label={t(translationKeys.SURVEY_QUESTION_QUESTION_VALUE_OPTIONS)}
          indicator={initialValues.indicator}
          options={[
            { scale: 1, title: t(translationKeys.SURVEY_QUESTION_QUESTION_SCALE_TITLE_A) },
            { scale: 2, title: t(translationKeys.SURVEY_QUESTION_QUESTION_SCALE_TITLE_B) },
          ]}
          value={value}
          onChange={onChange}
          editable={false}
          expandable={false}
        />
      );
    case ANSWERTYPE.AMOUNT_QUESTION:
      return (
        <SCRadioOptionsField
          label={t(translationKeys.SURVEY_QUESTION_QUESTION_VALUE_OPTIONS)}
          indicator={initialValues.indicator}
          options={[
            { scale: 1, title: t(translationKeys.SURVEY_QUESTION_QUESTION_SCALE_TITLE_A) },
            { scale: 2, title: t(translationKeys.SURVEY_QUESTION_QUESTION_SCALE_TITLE_B) },
          ]}
          value={value}
          onChange={onChange}
          editable={false}
          expandable={false}
        />
      );
    case ANSWERTYPE.FREQUENCY_CARD_QUESTION:
      return (
        <SCRadioOptionsField
          label={t(translationKeys.SURVEY_QUESTION_QUESTION_VALUE_OPTIONS)}
          indicator={initialValues.indicator}
          options={[
            { scale: 1, title: t(translationKeys.SURVEY_QUESTION_QUESTION_SCALE_TITLE_A) },
            { scale: 2, title: t(translationKeys.SURVEY_QUESTION_QUESTION_SCALE_TITLE_B) },
          ]}
          value={value}
          onChange={onChange}
          editable={false}
          expandable={false}
        />
      );
    case ANSWERTYPE.RADIO_BUTTON_QUESTION:
      return (
        <SCRadioOptionsField
          label={t(translationKeys.SURVEY_QUESTION_QUESTION_VALUE_OPTIONS)}
          indicator={initialValues.indicator}
          options={initialValues.answers}
          onChange={onChange}
          maxOptions={9}
          selectable={false}
        />
      );
    default:
      return <div />;
  }
}

function SurveyCreateQuestionForm({ onSubmit, onCancel, initialValues }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [additionalContext, setAdditionalContext] = useState(!!initialValues.description);
  const [actualType, setActualType] = useState(initialValues.type);
  const [answers, setAnswers] = useState(initialValues.answers);

  async function onHandleSubmit(values) {
    if (!initialValues.title) {
      let answersToSend = [];
      if (values.type === ANSWERTYPE.RADIO_BUTTON_QUESTION) {
        answersToSend = answers.list.map((answer) => ({ title: answer.title }));
      } else {
        answersToSend = answers.list[answers.selected].title
          .split('|')
          .map((e, index) => ({ scale: index + 1, title: e.trim() }));
      }
      let response = {};
      response = await dispatch.surveyModel.createQuestion({
        description: values.description,
        indicator: values.indicator || t(translationKeys.GENERAL_NO_INDICATOR),
        title: values.title,
        type: values.type,
        answers: answersToSend,
      });
      if (response.message) {
        return response;
      }
      onSubmit(response);
    } else {
      let answersToSend = [];
      if (values.type === ANSWERTYPE.RADIO_BUTTON_QUESTION) {
        answersToSend = answers.list.map((answer) => ({ title: answer.title }));
      } else {
        answersToSend = answers.list[answers.selected].title
          .split('|')
          .map((e, index) => ({ scale: index + 1, title: e.trim() }));
      }
      let response = {};
      response = await dispatch.surveyModel.editQuestion({
        uuid: initialValues.uuid,
        description: values.description,
        indicator: values.indicator || t(translationKeys.GENERAL_NO_INDICATOR),
        title: values.title,
        type: values.type,
        answers: answersToSend,
      });
      if (response.message) {
        return response;
      }
      onSubmit(response);
    }
    return true;
  }

  function handleTypeChange(e) {
    setActualType(e.target.value);
  }

  function handleOptionsChange(options) {
    setAnswers(options);
  }

  function getTypes() {
    return Object.values(ANSWERTYPE).reduce((result, value) => {
      switch (value) {
        case ANSWERTYPE.SMILEY_QUESTION:
          result.push({ value, icon: smileyIcon });
          break;
        case ANSWERTYPE.RADIO_BUTTON_QUESTION:
          result.push({ value, icon: radioIcon });
          break;
        case ANSWERTYPE.AMOUNT_QUESTION:
          result.push({ value, icon: amountIcon });
          break;
        case ANSWERTYPE.CHAPTER_CARD_QUESTION:
          break;
        case ANSWERTYPE.FREQUENCY_CARD_QUESTION:
          result.push({ value, icon: targetIcon });
          break;
        default:
          result.push({ value });
          break;
      }
      return result;
    }, []);
  }

  function getAreas() {
    return Object.values(QUESTIONAREACOMPLETE).map((value) => {
      switch (value.type) {
        case QUESTIONAREACOMPLETE.HEALTH.type:
          return { value, icon: healthIcon };
        case QUESTIONAREACOMPLETE.LIVING.type:
          return { value, icon: livingIcon };
        case QUESTIONAREACOMPLETE.WORK.type:
          return { value, icon: workIcon };
        case QUESTIONAREACOMPLETE.EDUCATION.type:
          return { value, icon: educationIcon };
        case QUESTIONAREACOMPLETE.DEMOCRACY.type:
          return { value, icon: democracyIcon };
        default:
          return { value };
      }
    });
  }

  return (
    <Form
      onSubmit={onHandleSubmit}
      initialValues={{ ...initialValues, optional: !!initialValues.description }}
    >
      {({ handleSubmit, submitError, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <h4 className={classes.textHeader}>{t(translationKeys.SURVEY_QUESTION_QUESTION_CARD)}</h4>
          <SCTextFieldDefault
            name="title"
            label={t(translationKeys.SURVEY_QUESTION_QUESTION_TITLE)}
            placeholder={t(translationKeys.SURVEY_QUESTION_QUESTION_TITLE)}
            required
          />
          <div className={classes.horizontalLine}>
            <SCSelectFieldIcon
              name="type"
              label={t(translationKeys.SURVEY_QUESTION_QUESTION_TYPE)}
              options={getTypes()}
              className={classes.selectField}
              onChange={handleTypeChange}
            />

            <SCCheckBoxFieldDefault
              name="optional"
              label={t(translationKeys.SURVEY_QUESTION_QUESTION_ADITIONAL_CONTEXT)}
              onChange={(e) => setAdditionalContext(e.target.checked)}
              checked
            />
          </div>
          {additionalContext && (
            <SCTextFieldDefault
              name="description"
              label={t(translationKeys.SURVEY_QUESTION_QUESTION_ADITIONAL_CONTEXT_TITLE)}
              placeholder={t(translationKeys.SURVEY_QUESTION_QUESTION_ADITIONAL_CONTEXT_TITLE)}
              minLength={20}
            />
          )}
          {values.type && values.type !== ANSWERTYPE.RADIO_BUTTON_QUESTION && (
            <SCSelectGroupFieldDefault
              name="indicator"
              label={t(translationKeys.SURVEY_QUESTION_QUESTION_INDICATOR)}
              options={getAreas()}
              required
            />
          )}
          {getContentByType(actualType, initialValues, handleOptionsChange, t)}

          {submitError && <ErrorField>{submitError}</ErrorField>}
          <div className={classes.buttons}>
            <SCButton
              variant="outlined"
              type="button"
              color="primary"
              onClick={onCancel}
              className={classes.button}
            >
              {t(translationKeys.GENERAL_CANCEL)}
            </SCButton>
            <SCButton
              variant="contained"
              type="submit"
              color="primary"
              disabled={!answers || answers.length <= 0 || !values.title || !values.type}
              loading={submitting}
              className={classes.button}
            >
              {t(translationKeys.GENERAL_SAVE)}
            </SCButton>
          </div>
        </form>
      )}
    </Form>
  );
}

SurveyCreateQuestionForm.propTypes = {
  initialValues: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    indicator: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.object),
    uuid: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

SurveyCreateQuestionForm.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
  onCancel: () => {},
};
export default SurveyCreateQuestionForm;
