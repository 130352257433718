import CitiesSective from '../services/CitiesService';
import { handleError, Handlers } from '../services/helpers/error-handling';

const citiesModel = {
  name: 'citiesModel',
  state: {
    cities: [],
  },
  reducers: {
    setCities: (state, payload) => {
      return {
        ...state,
        cities: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getCities() {
      try {
        const response = await CitiesSective.listCities();
        dispatch.citiesModel.setCities(response);
        return response;
      } catch (error) {
        return handleError(error, {
          'list-cities-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
  }),
};

export default citiesModel;
