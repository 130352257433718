import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import translationKeys from '../../../utils/translations/translationKeys';
import ProfileChangePasswordForm from './ProfileChangePasswordForm';
import ProfileDetailsForm from './ProfileDetailsForm';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.Spacing.L} ${theme.Spacing.M}`,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '80%',
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    ...theme.Font.Headline.H2,
    textTransform: 'uppercase',
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.M,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph2,
    color: theme.Colors.neutral60,
    marginBottom: theme.Spacing.XL,
  },
  leftContainer: {
    width: theme.Grid.Width.Medium,
    marginRight: theme.Spacing.XL,
  },
  rightContainer: {
    width: theme.Grid.Width.Medium,
  },
}));

function ProfilePage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const userProfile = useSelector((state) => state.userModel.userInfo);
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>{t(translationKeys.PROFILE_TITLE)}</h2>
      <p className={classes.subtitle}>{t(translationKeys.PROFILE_SUBTITLE)}</p>
      <div className={classes.innerContainer}>
        <div className={classes.leftContainer}>
          <ProfileDetailsForm user={userProfile} />
        </div>
        <div className={classes.rightContainer}>
          <ProfileChangePasswordForm user={userProfile} />
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
