import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core/';
import TableCell from '@material-ui/core/TableCell';
import { MoreVertical } from 'react-feather';

const useStyles = makeStyles(() => ({
  optionsCell: {
    paddingLeft: ({ dense }) => dense && '0',
    paddingRight: ({ dense }) => dense && '0',
    cursor: 'pointer',
  },
}));

const OptionsCell = ({ key, align, dense, actions, rowKey }) => {
  const classes = useStyles({ dense });
  const [anchorMenu, setAnchorMenu] = useState(null);
  const cellRef = useRef(null);
  const handleOpenMenu = () => {
    setAnchorMenu(cellRef.current);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <>
      <TableCell
        classes={{ root: classes.optionsCell }}
        key={key}
        align={align}
        onClick={handleOpenMenu}
        ref={cellRef}
      >
        <MoreVertical />
      </TableCell>
      <Menu
        elevation={1}
        anchorEl={anchorMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorMenu)}
        onClose={handleCloseMenu}
        classes={{ paper: classes.menu }}
      >
        {actions.map((option) => (
          <MenuItem
            key={option.name}
            onClick={() => {
              option.onClick(rowKey);
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

OptionsCell.propTypes = {
  key: PropTypes.string,
  align: PropTypes.string,
  dense: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.object),
  rowKey: PropTypes.string,
};

OptionsCell.defaultProps = {
  key: '',
  align: 'center',
  dense: true,
  actions: [],
  rowKey: '',
};

export default OptionsCell;
