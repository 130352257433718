import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Edit2, Trash2 } from 'react-feather';
import SCCard from '../../../../components/Cards/SCCard';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.Spacing.M,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  lined: {
    background: `repeating-linear-gradient(45deg,${theme.Colors.neutral80} 3px,${theme.Colors.neutral80} 4px,${theme.Colors.background} 4px,${theme.Colors.background} 7px)`,
    width: '20px',
  },
  rightContainer: {
    flex: 1,
    padding: `${theme.Spacing.S} ${theme.Spacing.M}`,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    cursor: 'pointer',
    marginRight: theme.Spacing.XXS,
  },
  title: {
    ...theme.Font.Headline.H4,
    textTransform: 'uppercase',
    marginBottom: theme.Spacing.XS,
    color: theme.Colors.neutral100,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph1,
    marginBottom: theme.Spacing.XXS,
    color: theme.Colors.neutral100,
  },
  description: {
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral80,
  },
}));

function SurveyCardInfo({
  uid,
  handleEdit,
  handleDelete,
  title,
  subtitle,
  description,
  allowDelete,
}) {
  const classes = useStyles();
  return (
    <SCCard className={classes.card} key={uid}>
      <div className={classes.container}>
        <div className={classes.lined} />
        <div className={classes.rightContainer}>
          <div className={classes.iconContainer}>
            <Edit2 className={classes.icon} onClick={handleEdit} />
            {allowDelete && <Trash2 className={classes.icon} onClick={handleDelete} />}
          </div>
          <div className={classes.title}>{title}</div>
          <div className={classes.subtitle}>{subtitle}</div>
          <div className={classes.description}>{description}</div>
        </div>
      </div>
    </SCCard>
  );
}

SurveyCardInfo.propTypes = {
  uid: PropTypes.string,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  allowDelete: PropTypes.bool,
};

SurveyCardInfo.defaultProps = {
  uid: '',
  handleEdit: () => {},
  handleDelete: () => {},
  title: '',
  subtitle: '',
  description: '',
  allowDelete: true,
};

export default SurveyCardInfo;
