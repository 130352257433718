import CellTypes from '../../../components/Table/Cells/CellTypes';
import translationKeys from '../../../utils/translations/translationKeys';

export default function(t) {
  return [
    {
      id: 'statusIndicator',
      label: '',
      align: 'center',
      type: CellTypes.CELL_STATUS,
      width: '3px',
      dense: true,
    },
    {
      id: 'status',
      label: t(translationKeys.GENERAL_STATUS),
      align: 'left',
      type: CellTypes.CELL_TEXT,
    },
    {
      id: 'date',
      label: t(translationKeys.GENERAL_DATE),
      width: '80px',
      align: 'left',
      type: CellTypes.CELL_TEXT,
    },
    {
      id: 'name',
      label: t(translationKeys.GENERAL_SURVEY_NAME),
      align: 'left',
      type: CellTypes.CELL_TEXT,
      highlight: true,
    },
    {
      id: 'area',
      label: t(translationKeys.GENERAL_AREA),
      align: 'left',
      width: '70px',
      type: CellTypes.CELL_TEXT,
    },
    {
      id: 'forum',
      label: t(translationKeys.GENERAL_FORUM),
      align: 'left',
      width: '70px',
      type: CellTypes.CELL_TEXT,
    },
    {
      id: 'createdBy',
      label: t(translationKeys.GENERAL_CREATEDBY),
      align: 'left',
      type: CellTypes.CELL_TEXT,
    },
    {
      id: 'code',
      label: t(translationKeys.GENERAL_SURVEYCODE),
      align: 'left',
      type: CellTypes.CELL_TEXT,
    },
    {
      id: 'options',
      label: '',
      align: 'left',
      type: CellTypes.CELL_OPTION,
      dense: true,
    },
  ];
}
