/* eslint-disable import/prefer-default-export */

export const STATUS = { ACTIVE: 'Active', BLOCKED: 'Blocked' };

export const ROLE = { ADMIN: 'Admin', VIEWER: 'Viewer' };

export const SURVEYSTATUS = { DRAFT: 'draft', PUBLISHED: 'published', ARCHIVED: 'archived' };

export const ANSWERTYPE = {
  SMILEY_QUESTION: 'Smiley',
  RADIO_BUTTON_QUESTION: 'RadioButton',
  AMOUNT_QUESTION: 'Amount',
  CHAPTER_CARD_QUESTION: 'ChapterCard',
  FREQUENCY_CARD_QUESTION: 'Frequency',
};

export const QUESTIONAREA = {
  HEALTH: 'Health',
  WORK: 'Work',
  HOME: 'Home',
  EDUCATION: 'Education',
  DEMOCRACY: 'Democracy',
};

export const QUESTIONAREACOMPLETE = {
  HEALTH: {
    name: 'Hälsa',
    key: 'Hälsa',
    type: 'Health',
    categories: [
      { name: 'Känsla av god relation till sina föräldrar/vårdnadshavare (fråga till ungdomar)' },
      { name: 'Grad av upplevt fysiskt våld gentemot sig själv' },
      { name: 'Grad av upplevda kränkningar eller trakasserier gentemot sig själv' },
      { name: 'Grad av upplevt våld eller kränkningar i sin omgivning' },
      { name: 'Grad av fysisk aktivitet' },
      { name: 'Upplevd friskhet' },
      { name: 'Upplevd hälsosam kost' },
      { name: 'Upplevt välbefinnande' },
      { name: 'Förtroende för vård och omsorg i närområdet' },
    ],
  },
  EDUCATION: {
    name: 'Utbildning',
    key: 'Utbildning',
    type: 'Education',
    categories: [
      { name: 'Upplevelse att skolan i närområdet satsar på alla elever oavsett förutsättning' },
      { name: 'Trivsel i skolan' },
      { name: 'Upplevelse att vuxna i närheten stöttar skolgång och finns tillhands' },
      { name: 'Grad av studiemotivation' },
      { name: 'Upplevelse av skolans betydelse för sin framtid' },
    ],
  },
  WORK: {
    name: 'Arbete och hållbar försörjning',
    key: 'Arbete och hållbar försörjning',
    type: 'Work',
    categories: [
      {
        name:
          'Upplevd kunskap om vad som krävs för att få ett eget jobb och lyckas på arbetsmarknaden',
      },
      { name: 'Grad av arbetsrelaterade drömmar' },
      { name: 'Upplevd möjlighet att kunna förverkliga sina ambitioner på arbetsmarknaden' },
      { name: 'Upplevd möjlighet att försörja sig' },
    ],
  },
  LIVING: {
    name: 'Boende och levnadsmiljö',
    key: 'Boende och levnadsmiljö',
    type: 'Living',
    categories: [
      { name: 'Upplevelse av trygg bostadssituation' },
      { name: 'Nöjdhet med standard på boende' },
      { name: 'Upplevd trångboddhet' },
      {
        name:
          'Upplevd trivsel i utemiljöerna i närområdet (ex. parker, lekplatser, idrottsplatser)',
      },
      { name: 'Känsla av trygghet i närområdet när det är mörkt?' },
      { name: 'Upplevd förekomst av skadegörelse och olika former av brott i närområdet' },
      { name: 'Möjlighet att göra roliga saker i närområdet' },
      {
        name:
          'Förtroende för Polisen, skolan, Socialtjänsten, bostadsbolag och politiker i närområdet',
      },
    ],
  },
  DEMOCRACY: {
    name: 'Demokrati och inflytande',
    key: 'Demokrati och inflytande',
    type: 'Democracy',
    categories: [
      { name: 'Bedömd kunskap om sina rättigheter' },
      { name: 'Grad av aktivt påverkansarbete' },
      { name: 'Känsla att sina åsikter är viktiga och tas på allvar' },
      { name: 'Upplevd möjlighet att kunna påverka utvecklingen i närområdet' },
      { name: 'Upplevd möjlighet att kunna påverka sina livsvillkor till det bättre' },
      { name: 'Tro på att det gör skillnad att rösta i våra politiska val' },
    ],
  },
};

export const QUESTIONTYPE = {
  ACTIVITY_SURVEY: 'ActivitySurvey',
  LIFEJOURNEY_SURVEY: 'LifeJourney',
};

export const QUESTIONACTIVITYSUBTYPE = {
  WORKSHOP: 'Workshop',
  LECTURE: 'Föreläsning',
  TRYON_ACTIVITY: 'Prova-på aktivitet',
  TEAM_BUILDING: 'Teambuilding',
  STUDY_VISITS: 'Studiebesök',
  PROJECT_WORK: 'Projektarbete',
  PARTY: 'Fest',
  SOCIAL_ACTIVITY: 'Social aktivitet',
  OTHER: 'Annat',
};

export const FORUMOPTIONS = {
  HEALTHY_GENERATION: 'En frisk generation',
  FOLK_HIGH_SCHOOL: 'Folkhögskolan',
  YOUTH_CENTER: 'Fritidsgård',
  PARENTS_FORUM: 'Föräldraforum',
  GUY_FORUM: 'Killforum',
  WOMAN_FOR_BACKBY: 'Kvinna för Bäckby',
  GIRL_FORUM: 'Tjejforum',
  YOUTH_FORUM: 'Ungdomsforum',
  OTHERS: 'Annan verksamhet',
};
