import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../../../router/PrivateRoute';
import SurveyListPage from './SurveyListPage';
import SurveyEditPage from './SurveyEditSection/SurveyEditPage';
import Paths from '../../../router/router';

function SurveysPage() {
  return (
    <Switch>
      <PrivateRoute exact path={Paths.EDIT_SURVEYS} component={SurveyEditPage} />
      <PrivateRoute exact path={Paths.SURVEYS} component={SurveyListPage} />
      <Redirect to={Paths.SURVEYS} />
    </Switch>
  );
}

export default SurveysPage;
