import { FORM_ERROR } from 'final-form';
import SCAxios from './SCAxios';
import { FormError, passthroughNetworkErrors } from './helpers/error-handling';

const BIService = {
  async getTokens() {
    try {
      const response = await SCAxios.post('/powerbi/token', {});
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('login-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
};

export default BIService;
