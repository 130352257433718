import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ErrorField from '../../components/Form/Errors/ErrorField';
import translationKeys from '../../utils/translations/translationKeys';
import SCTextFieldEmail from '../../components/Form/Fields/TextFields/SCTextFieldEmail';
import SCButton from '../../components/Buttons/SCButton';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.Spacing.M,
  },
  linkButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textHeader: {
    textAlign: 'center',
    margin: 0,
    textTransform: 'uppercase',
    ...theme.Font.Headline.H4,
    color: theme.Colors.neutral100,
  },
  textInfo: {
    textAlign: 'center',
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral80,
    marginBottom: theme.Spacing.M,
  },
}));

function ForgotPasswordForm({ onSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  async function handleInsideSubmit(values) {
    const response = await dispatch.userModel.forgot(values);
    onSubmit(values);
    return response;
  }

  return (
    <Form onSubmit={handleInsideSubmit}>
      {({ handleSubmit, submitError, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <h4 className={classes.textHeader}> {t(translationKeys.LOGIN_FORGOT_PASSWORD_TITLE)}</h4>
          <p className={classes.textInfo}>{t(translationKeys.LOGIN_FORGOT_PASSWORD_INFO)}</p>
          <SCTextFieldEmail placeholder={t(translationKeys.GENERAL_EMAIL)} />
          {submitError && <ErrorField>{submitError}</ErrorField>}
          <div className={classes.buttons}>
            <SCButton
              variant="contained"
              type="submit"
              color="primary"
              disabled={submitting || pristine}
              loading={submitting}
            >
              {t(translationKeys.GENERAL_SEND)}
            </SCButton>
          </div>
        </form>
      )}
    </Form>
  );
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
};

ForgotPasswordForm.defaultProps = {
  onSubmit: () => {},
};

export default ForgotPasswordForm;
