import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import translationKeys from '../../../utils/translations/translationKeys';
import SCTable from '../../../components/Table/SCTable';
import tableColumns from './surveys-table-columns';
import { SURVEYSTATUS } from '../../../utils/constants';
import Paths from '../../../router/router';
import SCModalDialog from '../../../components/Modal/SCModalDialog';
import SCModalSuccess from '../../../components/Modal/SCModalSuccess';
import SCModalAlert from '../../../components/Modal/SCModalAlert';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.Spacing.L} ${theme.Spacing.M}`,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  title: {
    ...theme.Font.Headline.H2,
    textTransform: 'uppercase',
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.M,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph2,
    color: theme.Colors.neutral60,
    marginBottom: theme.Spacing.M,
  },
}));

function getOptionsByStatus(
  status,
  { handleDuplicate, handleEdit, handleDelete, handleArchive },
  t,
) {
  switch (status) {
    case SURVEYSTATUS.DRAFT:
      return [
        {
          name: t(translationKeys.GENERAL_COPY),
          onClick: handleDuplicate,
        },
        {
          name: t(translationKeys.GENERAL_EDIT),
          onClick: handleEdit,
        },
        {
          name: t(translationKeys.GENERAL_DELETE),
          onClick: handleDelete,
        },
      ];
    case SURVEYSTATUS.PUBLISHED:
      return [
        {
          name: t(translationKeys.GENERAL_COPY),
          onClick: handleDuplicate,
        },
        {
          name: t(translationKeys.GENERAL_ARCHIVE),
          onClick: handleArchive,
        },
      ];
    case SURVEYSTATUS.ARCHIVED:
      return [
        {
          name: t(translationKeys.GENERAL_COPY),
          onClick: handleDuplicate,
        },
        {
          name: t(translationKeys.GENERAL_DELETE),
          onClick: handleDelete,
        },
      ];
    default:
      return [];
  }
}

function getUserNamebyUid(uid, users) {
  const result = users.filter((user) => user.uid === uid);
  if (result.length <= 0) {
    return '';
  }
  return `${result[0].name} ${result[0].surname}`;
}

function populateRows(data, handlers, t, userList) {
  if (data) {
    return data.map(({ status, activityDate, name, cities, createdBy, pincode, uid, forum }) => ({
      statusIndicator: status,
      status: status.charAt(0).toUpperCase() + status.slice(1),
      date:
        activityDate &&
        new Date(activityDate).toLocaleString('sv', {
          year: 'numeric',
          day: 'numeric',
          month: 'short',
        }),
      name,
      area: cities && cities.length > 0 ? cities[0].name : '',
      forum,
      createdBy: getUserNamebyUid(createdBy, userList),
      code: status === SURVEYSTATUS.PUBLISHED ? pincode : '',
      key: uid,
      actions: getOptionsByStatus(status, handlers, t),
    }));
  }
  return [];
}

function SurveyListPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const surveyList = useSelector((state) => state.surveyListModel.surveyList);
  const userList = useSelector((state) => state.userListModel.userList);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    title: '',
    subtitle: '',
    acceptText: '',
    cancelText: '',
    handleAccept: () => {},
    handleCancel: () => {},
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState('');
  const [successModal, setSuccessModal] = useState({
    open: false,
    onClick: () => {},
  });

  async function fetchSurveys() {
    setLoading(true);
    await dispatch.userListModel.getUserList();
    const response = await dispatch.surveyListModel.getSurveyList();
    setLoading(false);
    return response;
  }

  useEffect(() => {
    fetchSurveys();
    // eslint-disable-next-line
  }, []);

  function handleEdit(uid) {
    history.push(`${Paths.SURVEYS}/${uid}`);
  }
  function handleDuplicate(uid) {
    setConfirmModal({
      title: t(translationKeys.SURVEYS_LIST_DUPLICATE_TITLE),
      subtitle: t(translationKeys.SURVEYS_LIST_DUPLICATE_SUBTITLE),
      acceptText: t(translationKeys.GENERAL_DUPLICATE),
      cancelText: t(translationKeys.GENERAL_CANCEL),
      handleAccept: async () => {
        const response = await dispatch.surveyListModel.duplicateSurvey({
          uid,
          prefix: t(translationKeys.GENERAL_COPY_PREFIX),
        });
        setConfirmModalOpen(false);
        if (!response.message) {
          setSuccessModal({
            title: t(translationKeys.SURVEYS_LIST_DUPLICATE_MODAL_TITLE),
            subtitle: t(translationKeys.SURVEYS_LIST_DUPLICATE_MODAL_SUBTITLE),
            buttonText: t(translationKeys.SURVEYS_LIST_DUPLICATE_MODAL_EDIT),
            open: true,
            onClick: () => {
              setSuccessModal({
                open: false,
                onClick: () => {},
              });
              history.push(`${Paths.SURVEYS}/${response.uid}`);
            },
          });
        } else {
          setAlert(response.message);
        }
      },
      handleCancel: () => setConfirmModalOpen(false),
    });
    setConfirmModalOpen(true);
  }
  function handleDelete(uid) {
    setConfirmModal({
      title: t(translationKeys.SURVEYS_LIST_DELETE_TITLE),
      subtitle: t(translationKeys.SURVEYS_LIST_DELETE_SUBTITLE),
      acceptText: t(translationKeys.GENERAL_DELETE),
      cancelText: t(translationKeys.GENERAL_CANCEL),
      handleAccept: async () => {
        await dispatch.surveyListModel.deleteSurvey({ uid });
        setConfirmModalOpen(false);
      },
      handleCancel: () => setConfirmModalOpen(false),
    });
    setConfirmModalOpen(true);
  }
  function handleArchive(uid) {
    setConfirmModal({
      title: t(translationKeys.SURVEYS_LIST_ARCHIVE_TITLE),
      subtitle: t(translationKeys.SURVEYS_LIST_ARCHIVE_SUBTITLE),
      acceptText: t(translationKeys.GENERAL_ARCHIVE),
      cancelText: t(translationKeys.GENERAL_CANCEL),
      handleAccept: async () => {
        const response = await dispatch.surveyListModel.archiveSurvey({ uid });
        setConfirmModalOpen(false);
        setAlert(response.message);
      },
      handleCancel: () => setConfirmModalOpen(false),
    });
    setConfirmModalOpen(true);
  }
  const rows = populateRows(
    surveyList.list,
    {
      handleDuplicate,
      handleEdit,
      handleDelete,
      handleArchive,
    },
    t,
    userList.list,
  );
  return (
    <div className={classes.container}>
      <SCModalSuccess
        title={successModal.title}
        subtitle={successModal.subtitle}
        buttonText={successModal.buttonText}
        isOpen={successModal.open}
        onClick={successModal.onClick}
      />
      <SCModalAlert
        title={t(translationKeys.SURVEYS_LIST_ERROR_TITLE)}
        subtitle={t(translationKeys.SURVEYS_LIST_ERROR_SUBTITLE)}
        description={alert}
        isOpen={!!alert}
        buttonText={t(translationKeys.GENERAL_ACCEPT)}
        onClick={() => setAlert('')}
      />
      <SCModalDialog
        isOpen={confirmModalOpen}
        handleClose={() => setConfirmModalOpen(false)}
        title={confirmModal.title}
        subtitle={confirmModal.subtitle}
        buttonAcceptText={confirmModal.acceptText}
        buttonCancelText={confirmModal.cancelText}
        onAcceptClick={confirmModal.handleAccept}
        onCancelClick={confirmModal.handleCancel}
      />
      <h2 className={classes.title}>{t(translationKeys.SURVEYS_TITLE)}</h2>
      <p className={classes.subtitle}>{t(translationKeys.SURVEYS_SUBTITLE)}</p>
      <div className={classes.innerContainer}>
        <SCTable
          columns={tableColumns(t)}
          rows={rows}
          loading={loading}
          emptyText={t(translationKeys.SURVEYS_LIST_NO_DATA)}
        />
      </div>
    </div>
  );
}

export default SurveyListPage;
