import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  linkStyle: {
    ...theme.Font.Paragraph.Paragraph4,
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

function LinkButton({ to, children, onClick }) {
  const classes = useStyles();
  return to ? (
    <Link to={to} component={MaterialLink} className={classes.linkStyle}>
      {children}
    </Link>
  ) : (
    <MaterialLink className={classes.linkStyle} onClick={onClick}>
      {children}
    </MaterialLink>
  );
}

LinkButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
};

LinkButton.defaultProps = {
  to: undefined,
  children: '',
  onClick: () => {},
};

export default LinkButton;
