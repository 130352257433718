import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../utils/translations/translationKeys';
import PublicMainComponent from '../PublicMainComponent/PublicMainComponent';
import CreateAccountForm from './CreateAccountForm';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    margin: `${theme.Spacing.L} auto ${theme.Spacing.L} auto`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContainer: {
    background: theme.Colors.backgroundColor,
    borderRight: `1px solid ${theme.Colors.accentColor}`,
    width: theme.Grid.Width.Medium,
    minWidth: '292px',
    margin: 'auto',
  },
  formContainer: {
    ...theme.Elevations.medium,
  },
  rightContainer: {
    marginLeft: '128px',
    lineHeight: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    color: theme.Colors.backgroundColor,
    maxWidth: theme.Grid.Width.Medium,
    '@media (max-width: 1024px)': {
      marginLeft: '32px',
    },
  },
  aText: {
    color: theme.Colors.backgroundColor,
  },
  subtitle: {
    margin: 0,
    ...theme.Font.Headline.H3,
    textTransform: 'uppercase',
  },
  title: {
    margin: `${theme.Spacing.XXS} 0`,
    ...theme.Font.Headline.H1,
  },
  paragraph: {
    ...theme.Font.Paragraph.Paragraph1,
  },
  smallParagraph: {
    ...theme.Font.Paragraph.Paragraph2,
    margin: '0',
  },
}));
function CreateAccountPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <PublicMainComponent>
      <div className={classes.container}>
        <div className={classes.background} />
        <div className={classes.leftContainer}>
          <CreateAccountForm />
        </div>
        <div className={classes.rightContainer}>
          <h3 className={classes.subtitle}>{t(translationKeys.GENERAL_SUBTITLE)}</h3>
          <h1 className={classes.title}>{t(translationKeys.GENERAL_TITLE)}</h1>
          <p className={classes.paragraph}>{t(translationKeys.LOGIN_TEXT)}</p>
          <p className={classes.smallParagraph}>
            {t(translationKeys.LOGIN_SUBTEXT)}
            <a className={classes.aText} href={t(translationKeys.LOGIN_OUR_URL)}>
              {t(translationKeys.LOGIN_OUR_URL_TEXT)}
            </a>
          </p>
        </div>
      </div>
    </PublicMainComponent>
  );
}

export default CreateAccountPage;
