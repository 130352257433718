import SCAxios from '../../services/SCAxios';
import handleError from './handleError';

export default ({ dispatch }) => {
  SCAxios.interceptors.response.use(
    async (response) => {
      return response.data;
    },
    async (error) => {
      handleError(error, dispatch);
    },
  );
  return (next) => (action) => next(action);
};
