import { FORM_ERROR } from 'final-form';
import SCAxios from './SCAxios';
import { FormError, passthroughNetworkErrors } from './helpers/error-handling';

const CitiesServices = {
  async listCities() {
    try {
      const response = await SCAxios.get(`/location/city`);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('list-cities-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
};

export default CitiesServices;
