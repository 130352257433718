import AdminService from '../services/AdminService';
import { handleError, Handlers } from '../services/helpers/error-handling';

const userListModel = {
  name: 'userListModel',
  state: {
    userList: {
      page: 1,
      itemsPerPage: 10,
      list: [],
    },
    companies: [],
  },
  reducers: {
    setUserList: (state, payload) => {
      return {
        ...state,
        userList: { page: 1, itemsPerPage: 10, list: payload },
      };
    },
    setCompanies: (state, payload) => {
      return {
        ...state,
        companies: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getUserList() {
      try {
        const response = await AdminService.listUsers();
        dispatch.userListModel.setUserList(response);

        return response;
      } catch (error) {
        return handleError(error, {
          'get-userlist-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async changeStatus({ user, disabled }) {
      try {
        await AdminService.updateUser(user, { disabled });
        const response = dispatch.userListModel.getUserList();
        return response;
      } catch (error) {
        return handleError(error, {
          'update-admin-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async changeRole({ user, role }) {
      try {
        await AdminService.updateUser(user, { role });
        const response = dispatch.userListModel.getUserList();
        return response;
      } catch (error) {
        return handleError(error, {
          'update-admin-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async getCompaniesList() {
      try {
        const response = await AdminService.listCompanies();
        dispatch.userListModel.setCompanies(response);

        return response;
      } catch (error) {
        return handleError(error, {
          'getcompanies-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async createUser(payload) {
      try {
        const response = await AdminService.createUser(payload);
        await dispatch.userListModel.getUserList();
        return response;
      } catch (error) {
        return handleError(error, {
          'create-user-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
  }),
};

export default userListModel;
