import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logoIcon from '../../assets/icons/logo-icon.svg';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    margin: theme.Spacing.M,
  },
}));
function PublicMainComponentHeader() {
  const classes = useStyles();
  return (
    <div className={classes.headerContainer}>
      <img src={logoIcon} alt="logo" />
    </div>
  );
}

export default PublicMainComponentHeader;
