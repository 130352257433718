import CompaniesService from '../services/CompaniesService';
import { handleError, Handlers } from '../services/helpers/error-handling';

const companiesModel = {
  name: 'companiesModel',
  state: {
    companies: [],
  },
  reducers: {
    setCompanies: (state, payload) => {
      return {
        ...state,
        companies: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getCompanies() {
      try {
        const response = await CompaniesService.listCompanies();
        dispatch.companiesModel.setCompanies(response);
        return response;
      } catch (error) {
        return handleError(error, {
          'list-companies-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
  }),
};

export default companiesModel;
