import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../utils/translations/translationKeys';
import SCButton from '../../components/Buttons/SCButton';
import PublicMainComponent from '../PublicMainComponent/PublicMainComponent';
import Paths from '../../router/router';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    ...theme.Font.Headline.H2,
    textTransform: 'uppercase',
    color: theme.Colors.backgroundColor,
    marginBottom: theme.Spacing.M,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph1,
    color: theme.Colors.backgroundColor,
    marginBottom: theme.Spacing.M,
    textAlign: 'center',
  },
}));

function ActionPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  function onClick() {
    history.replace(Paths.ROOT);
  }

  return (
    <PublicMainComponent>
      <div className={classes.container}>
        <h2 className={classes.title}>{t(translationKeys.ERROR_NOT_FOUND_TITLE)}</h2>
        <p className={classes.subtitle}>{t(translationKeys.ERROR_NOT_FOUND_SUBTITLE)}</p>
        <SCButton variant="contained" color="primary" onClick={onClick}>
          {t(translationKeys.ERROR_NOT_FOUND_GO_BACK_MAIN)}
        </SCButton>
      </div>
    </PublicMainComponent>
  );
}

export default ActionPage;
