import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core/';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CellBuilder from './Cells/CellBuilder';
import CellTypes from './Cells/CellTypes';
import translateKeys from '../../utils/translations/translationKeys';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
  },
  title: {
    ...theme.Font.Headline.H5,
  },
  emptyTextContainer: {
    width: '100%',
    textAlign: 'center',
  },
  emptyText: {
    ...theme.Font.Headline.H6,
  },
  header: {
    background: theme.Colors.background,
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const SCTable = ({ name, columns, rows, rowsPerPageOptions, onClickRow, loading, emptyText }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickRow = (value) => {
    onClickRow(value);
  };
  return (
    <div className={classes.container}>
      <h5 className={classes.title}>{name}</h5>
      {loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress size="120px" />
        </div>
      ) : (
        <>
          <div className={classes.tableWrapper}>
            <Table>
              <TableHead className={classes.header}>
                <TableRow>
                  {columns.map((column) => (
                    <CellBuilder
                      key={column.id}
                      align={column.align}
                      type={CellTypes.CELL_HEADER}
                      minWidth={column.minWidth}
                      width={column.width}
                      dense={column.dense}
                    >
                      {column.label}
                    </CellBuilder>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow
                      hover
                      role="button"
                      tabIndex={-1}
                      key={row.key}
                      onClick={() => handleClickRow(row)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <CellBuilder
                            key={column.id}
                            align={column.align}
                            type={column.type}
                            options={column.options}
                            highlight={column.highlight}
                            onChange={column.onChange}
                            rowKey={row.key}
                            disabled={row.disabled}
                            status={row.status}
                            statusIndicator={row.statusIndicator}
                            dense={column.dense}
                            actions={row.actions}
                          >
                            {value}
                          </CellBuilder>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {rows.length === 0 && (
              <div className={classes.emptyTextContainer}>
                <h6 className={classes.emptyText}>{emptyText}</h6>
              </div>
            )}
          </div>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t(translateKeys.GENERAL_TABLE_ROWS_PER_PAGE)}
          />
        </>
      )}
    </div>
  );
};

SCTable.propTypes = {
  name: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  onClickRow: PropTypes.func,
  loading: PropTypes.bool,
  emptyText: PropTypes.string,
};

SCTable.defaultProps = {
  name: '',
  rows: [],
  rowsPerPageOptions: [10, 25, 100],
  onClickRow: () => {},
  loading: false,
  emptyText: '',
};

export default SCTable;
