import { NetworkError, BusinessError } from '../../services/helpers/error-handling';

function handleError(error, dispatch) {
  if (error && error.response && error.response.status) {
    const err =
      error.response.data && error.response.data.error
        ? error.response.data.error
        : error.response.data;
    switch (error.response.status) {
      case 401: {
        if (
          error.config.url.includes('oauth/token') ||
          error.config.url.includes('login') ||
          error.config.url.includes('logout')
        ) {
          throw BusinessError('auth-error', err);
        } else {
          dispatch({
            type: 'userModel/refresh',
          });
          throw NetworkError('auth-error', err);
        }
      }
      case 403: {
        if (
          error.config.url.includes('oauth/token') ||
          error.config.url.includes('login') ||
          error.config.url.includes('logout')
        ) {
          throw BusinessError('forbidden-error', err);
        } else {
          dispatch({
            type: 'userModel/refresh',
          });
          throw NetworkError('forbidden-error', err);
        }
      }
      case 400: {
        throw BusinessError('backend-error', err);
      }
      case 404: {
        throw NetworkError('not-found-error', err);
      }
      case 409: {
        throw BusinessError('backend-error', err);
      }
      default: {
        if (error.response.status >= 400 && error.response.status <= 499) {
          throw NetworkError('other-backend-error', error);
        }
        throw NetworkError('default-network-error', err);
      }
    }
  }
  if (error && error.type === 'NETWORK_ERROR') {
    throw error;
  }

  throw NetworkError('no-response-or-status', error);
}

export default handleError;
