import { FORM_ERROR } from 'final-form';
import SCAxios from './SCAxios';
import { FormError, passthroughNetworkErrors } from './helpers/error-handling';

const UserServices = {
  async getInfo(userId) {
    try {
      const response = await SCAxios.get(`user/${userId}`);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('getinfo-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async updateUser(userId, data) {
    try {
      const response = await SCAxios.post(`user/${userId}`, data);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('update-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
};

export default UserServices;
