import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoginPage from '../sections/LoginSection/LoginPage';
import CreateAccount from '../sections/CreateAccountSection/CreateAccountPage';
import ResetPassword from '../sections/ResetPasswordSection/ResetPasswordPage';
import CreateAccountSuccess from '../sections/CreateAccountSection/CreateAccountPageSuccess';
import ResetPasswordSuccess from '../sections/ResetPasswordSection/ResetPasswordPageSuccess';
import ActionPage from '../sections/ActionSection/ActionPage';
import LoggedInPage from '../sections/LoggedInSection/LoggedInPage';
import ErrorNotFoundPage from '../sections/ErrorSection/ErrorNotFound';
import PrivateRoute from './PrivateRoute';
import Paths from './router';

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path={Paths.LOGIN} component={LoginPage} />
        <Route exact path={Paths.CREATE_ACCOUNT} component={CreateAccount} />
        <Route exact path={Paths.CREATE_ACCOUNT_SUCCESS} component={CreateAccountSuccess} />
        <Route exact path={Paths.RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={Paths.RESET_PASSWORD_SUCCESS} component={ResetPasswordSuccess} />
        <Route exact path={Paths.ACTION} component={ActionPage} />
        <Route exact path={Paths.NOT_FOUND} component={ErrorNotFoundPage} />
        <PrivateRoute path={Paths.ROOT} component={LoggedInPage} />
        <Redirect to={Paths.NOT_FOUND} />
      </Switch>
    </Router>
  );
}

export default Routes;
