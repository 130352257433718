import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../../../utils/translations/translationKeys';
import SCSelectField from '../SCSelectField';

const SCSelectFieldDefault = ({
  disabled,
  label,
  name,
  required,
  helperText,
  hide,
  options,
  className,
  onChange,
}) => {
  const { t } = useTranslation();

  function validate(text) {
    if (required && (!text || text === '')) {
      return t(translationKeys.GENERAL_REQUIRED);
    }
    return null;
  }
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <SCSelectField
          onChange={(e) => {
            input.onChange(e);
            onChange(e);
          }}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          value={input.value}
          label={label}
          disabled={disabled}
          showError={meta.touched && meta.error !== undefined}
          error={meta.error}
          helperText={helperText}
          hide={hide}
          options={options}
          className={className}
        />
      )}
      validate={!disabled ? validate : undefined}
    />
  );
};

SCSelectFieldDefault.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  hide: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

SCSelectFieldDefault.defaultProps = {
  disabled: false,
  label: '',
  required: false,
  helperText: undefined,
  hide: false,
  options: [],
  className: '',
  onChange: () => {},
};

export default SCSelectFieldDefault;
