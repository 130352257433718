import { FORM_ERROR } from 'final-form';
import SCAxios from './SCAxios';
import { FormError, passthroughNetworkErrors } from './helpers/error-handling';

const TOKEN_KEY = '@sc-auth-service/tokens';

function transformResponse(data) {
  return {
    accessToken: data.accessToken,
    refreshToken: data.refreshToken,
  };
}

const UserServices = {
  async login(email, password) {
    try {
      const response = await SCAxios.post('login', {
        email,
        password,
      });
      return transformResponse(response);
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('login-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async checkCurrentPassword(email, password) {
    try {
      const response = await SCAxios.post('login', {
        email,
        password,
      });
      return transformResponse(response);
    } catch (error) {
      return passthroughNetworkErrors(error, () => {
        throw FormError('current-password-error', {
          message: 'Current password is not valid',
          [FORM_ERROR]: 'Current password is not valid',
        });
      });
    }
  },
  async signin(token) {
    try {
      const response = await SCAxios.post('signin', {
        token,
      });
      return transformResponse(response);
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('singin-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async signinWithEmail(email, link) {
    try {
      const response = await SCAxios.post('signin', {
        email,
        link,
      });
      return transformResponse(response);
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('singin-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async logout() {
    try {
      return await SCAxios.post('logout');
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('logout-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async refresh(refreshToken) {
    try {
      const response = await SCAxios.post('refreshToken', {
        refreshToken,
      });
      return transformResponse(response);
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('refreshtoken-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async forgot(email) {
    try {
      const response = await SCAxios.post('user/resetPassword', {
        email,
      });
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('forgot-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  isAuthenticated() {
    const tokens = JSON.parse(localStorage.getItem(TOKEN_KEY));
    if (!tokens) {
      return false;
    }
    if (tokens.timestamp && tokens.timestamp + 3600000 * 72 > new Date().getTime()) {
      return true;
    }
    return false;
  },
};

export default UserServices;
