import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Radio, Input } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AlertTriangle, Trash2 } from 'react-feather';
import SCButtonNewListElement from '../../Buttons/SCButtonNewListElement';

const useStyles = makeStyles((theme) => ({
  radioContainer: {
    width: theme.Grid.Width.Medium,
  },
  element: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.Spacing.XS,
  },
  input: {
    flex: 1,
  },
  icon: {
    cursor: 'pointer',
  },
}));

const SCRadioOptionsField = ({
  label,
  value,
  className,
  error,
  showError,
  onChange,
  onBlur,
  onFocus,
  helperText,
  options,
  editable,
  selectable,
  expandable,
  maxOptions,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState(selectable ? value : undefined);
  const [actOptions, setActOptions] = useState(options);

  useEffect(() => {
    setActOptions(options);
  }, [options]);

  useEffect(() => {
    if (selectable) {
      setSelectedValue(value);
      onChange({ list: actOptions, selected: selectedValue });
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    onChange({ list: actOptions, selected: selectedValue });
    // eslint-disable-next-line
  }, [selectedValue]);

  const handleChange = useCallback(
    (event) => {
      if (selectable) {
        setSelectedValue(parseInt(event.target.value, 10));
      }
    },
    [selectable, setSelectedValue],
  );

  const handleInputChange = useCallback(
    (index) => (event) => {
      actOptions[index].title = event.target.value;
      onChange({ list: actOptions, selected: selectedValue });
    },
    [actOptions, onChange, selectedValue],
  );

  const removeThisOption = useCallback(
    (index) => {
      const firstQuestions = actOptions.slice(0, index);
      const lastQuestions = actOptions.slice(index + 1);
      const finalOptions = [...firstQuestions, ...lastQuestions];
      setActOptions(finalOptions);
      onChange({ list: finalOptions, selected: selectedValue });
    },
    [actOptions, onChange, selectedValue],
  );

  const getOptions = useCallback(() => {
    if (actOptions) {
      return actOptions.map((option, index) => {
        return (
          <div className={classes.element} key={option.uuid || option.scale}>
            <Radio
              checked={selectable ? selectedValue === index : false}
              onChange={handleChange}
              value={index}
              color="primary"
            />
            {editable ? (
              <>
                <Input
                  defaultValue={option.title}
                  className={classes.input}
                  onChange={handleInputChange(index)}
                />
                <Trash2
                  onClick={() => {
                    removeThisOption(index);
                  }}
                  size={24}
                  className={classes.icon}
                />
              </>
            ) : (
              <span className={classes.label}>{option.title}</span>
            )}
          </div>
        );
      });
    }
    return [];
  }, [
    actOptions,
    handleChange,
    editable,
    selectedValue,
    classes,
    selectable,
    handleInputChange,
    removeThisOption,
  ]);

  function addNewOption() {
    setActOptions([
      ...actOptions,
      { scale: actOptions.length > 0 ? actOptions[actOptions.length - 1].scale + 1 : 1, title: '' },
    ]);
    onChange({ list: actOptions, selected: selectedValue });
  }

  return (
    <div
      className={classnames(classes.textFieldContainer, className)}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <InputLabel classes={{ root: classes.textLabel }} error={showError}>
        {label}
      </InputLabel>

      <div className={classes.radioContainer}>
        {getOptions({
          options: actOptions,
          value: selectedValue,
          editable,
          classes,
          handleChange,
        })}
        {expandable && actOptions.length < maxOptions && (
          <SCButtonNewListElement onClick={addNewOption} />
        )}
      </div>

      {!showError && helperText && (
        <FormHelperText classes={{ root: classes.textLabel }}>{helperText}</FormHelperText>
      )}
      {showError && (
        <FormHelperText classes={{ root: classes.textLabel }} error={showError}>
          <span className={classes.spanLabel}>{error}</span>
          <AlertTriangle size="15" color={theme.Colors.alertColor} />
        </FormHelperText>
      )}
    </div>
  );
};

SCRadioOptionsField.propTypes = {
  value: PropTypes.number,
  label: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.string,
  showError: PropTypes.bool,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  editable: PropTypes.bool,
  selectable: PropTypes.bool,
  expandable: PropTypes.bool,
  maxOptions: PropTypes.number,
};

SCRadioOptionsField.defaultProps = {
  value: undefined,
  label: '',
  className: '',
  error: undefined,
  showError: false,
  helperText: undefined,
  options: [],
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  editable: true,
  selectable: true,
  expandable: true,
  maxOptions: 10,
};

export default SCRadioOptionsField;
