import { FORM_ERROR } from 'final-form';
import SCAxios from './SCAxios';
import { FormError, passthroughNetworkErrors } from './helpers/error-handling';
import { QUESTIONTYPE } from '../utils/constants';

const AdminServices = {
  async listSurveys() {
    try {
      const response = await SCAxios.get(`/survey`);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('list-surveys-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async getSurvey(uid) {
    try {
      const response = await SCAxios.get(`/survey/${uid}`);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('get-survey-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async duplicate(uid, name, description, type, subtype) {
    try {
      const data = {
        name,
        description,
        type,
      };
      if (type === QUESTIONTYPE.ACTIVITY_SURVEY) {
        data.subtype = subtype;
      }
      const response = await SCAxios.post(`/survey/copy/${uid}`, data);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('duplicate-survey-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async remove(uid) {
    try {
      const response = await SCAxios.delete(`/survey/${uid}`);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('remove-survey-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async edit(uid, data) {
    try {
      const response = await SCAxios.put(`/survey/${uid}`, data);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('edit-survey-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async createQuestion(data) {
    try {
      const response = await SCAxios.post(`/survey/question`, data);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('create-question-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async editQuestion(uuid, data) {
    try {
      const response = await SCAxios.put(`/survey/question/${uuid}`, data);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('edit-question-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async deleteQuestion(uid) {
    try {
      const response = await SCAxios.delete(`/survey/question/${uid}`);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('delete-question-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
};

export default AdminServices;
