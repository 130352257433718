import { init } from '@rematch/core';
import userModel from './userModel';
import userListModel from './userListModel';
import surveyListModel from './surveyListModel';
import surveyModel from './surveyModel';
import citiesModel from './citiesModel';
import companiesModel from './companiesModel';
import biModel from './biModel';
import errorInterceptor from './errorHandler/errorInterceptor';

const models = {
  user: userModel,
  userList: userListModel,
  surveyList: surveyListModel,
  survey: surveyModel,
  cities: citiesModel,
  companies: companiesModel,
  bi: biModel,
};

const store = init({
  models,
  redux: {
    middlewares: [errorInterceptor],
  },
});

export const { dispatch } = store;
export default store;
