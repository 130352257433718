import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core/';
import { useDispatch } from 'react-redux';
import PrivateMainComponentHeader from './PrivateMainComponentHeader';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.Colors.backgroundColor,
    display: 'flex',
    flexDirection: 'column',

    width: '100vw',
    height: '100vh',
  },
  innerContainer: {
    flex: '1',
  },
  scrollerContainer: {
    flex: '1',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
function PrivateMainComponent({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  async function fetchUserData() {
    await dispatch.userModel.recoverMyInfo();
    setLoading(false);
  }

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      {loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress size="140px" />
        </div>
      ) : (
        <>
          <PrivateMainComponentHeader />
          <div className={classes.scrollerContainer}>
            <div className={classes.innerContainer}>{children}</div>
          </div>
        </>
      )}
    </div>
  );
}

PrivateMainComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
export default PrivateMainComponent;
