import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import { Plus } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.Spacing.S,
    paddingBottom: theme.Spacing.S,
    background: 'transparent',
    display: 'flex',
    width: '100%',
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    height: '2px',
    flex: 1,
    background: theme.Colors.neutral20,
  },
}));

function SCButtonNewListElement({ onClick }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.separator} />
        <Fab size="small" color="secondary" className={classes.button} onClick={onClick}>
          <Plus />
        </Fab>
        <div className={classes.separator} />
      </div>
    </div>
  );
}

SCButtonNewListElement.propTypes = {
  onClick: PropTypes.func,
};

SCButtonNewListElement.defaultProps = {
  onClick: () => {},
};

export default SCButtonNewListElement;
