import { FORM_ERROR } from 'final-form';
import SCAxios from './SCAxios';
import { FormError, passthroughNetworkErrors } from './helpers/error-handling';

const CompaniesServices = {
  async listCompanies() {
    try {
      const response = await SCAxios.get(`/companies`);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('list-companies-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
};

export default CompaniesServices;
