import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import translationKeys from '../../utils/translations/translationKeys';
import SCButton from '../../components/Buttons/SCButton';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.Colors.backgroundColor,
  },
  innerContainer: {
    padding: `${theme.Spacing.XL} ${theme.Spacing.L}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    ...theme.Font.Headline.H4,
    textTransform: 'uppercase',
    margin: 0,
    marginBottom: theme.Spacing.XS,
    color: theme.Colors.neutral100,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral80,
    margin: 0,
    marginBottom: theme.Spacing.L,
  },
}));

function ForgotPasswordSuccess({ onSubmit }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <h4 className={classes.title}>{t(translationKeys.LOGIN_FORGOT_PASSWORD_TITLE)}</h4>
        <p className={classes.subtitle}>{t(translationKeys.LOGIN_FORGOT_PASSWORD_CONFIRMATION)}</p>
        <SCButton variant="contained" color="primary" onClick={onSubmit}>
          {t(translationKeys.GENERAL_GO_TO_LOGIN)}
        </SCButton>
      </div>
    </div>
  );
}

ForgotPasswordSuccess.propTypes = {
  onSubmit: PropTypes.func,
};

ForgotPasswordSuccess.defaultProps = {
  onSubmit: () => {},
};
export default ForgotPasswordSuccess;
