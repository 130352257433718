export default (theme) => ({
  container: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContainer: {
    background: theme.Colors.backgroundColor,
    borderRight: `1px solid ${theme.Colors.accentColor}`,
    width: theme.Grid.Width.Medium,
    minWidth: '292px',
    margin: 'auto',
  },
  formContainer: {
    ...theme.Elevations.medium,
  },
  rightContainer: {
    marginLeft: '128px',
    lineHeight: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.Colors.backgroundColor,
    maxWidth: theme.Grid.Width.Medium,
    '@media (max-width: 1024px)': {
      marginLeft: '32px',
    },
  },
  aText: {
    color: theme.Colors.backgroundColor,
  },
  subtitle: {
    margin: 0,
    ...theme.Font.Headline.H3,
    textTransform: 'uppercase',
  },
  title: {
    margin: `${theme.Spacing.XXS} 0`,
    ...theme.Font.Headline.H1,
  },
  paragraph: {
    ...theme.Font.Paragraph.Paragraph1,
  },
  smallParagraph: {
    ...theme.Font.Paragraph.Paragraph2,
    margin: '0',
  },
});
