import React from 'react';
import PropTypes from 'prop-types';
import SurveyCreateQuestionForm from './SurveyCreateQuestionForm';
import SurveyCreateChapterForm from './SurveyCreateChapterForm';

function SurveyCreateQuestionModal({ onSubmit, onCancel, isChapter, initialValues }) {
  return isChapter ? (
    <SurveyCreateChapterForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      initialValues={initialValues}
    />
  ) : (
    <SurveyCreateQuestionForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      initialValues={initialValues}
    />
  );
}

SurveyCreateQuestionModal.propTypes = {
  initialValues: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    indicator: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.object),
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isChapter: PropTypes.bool,
};

SurveyCreateQuestionModal.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
  onCancel: () => {},
  isChapter: true,
};
export default SurveyCreateQuestionModal;
