import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Edit2, Trash2 } from 'react-feather';
import Divider from '@material-ui/core/Divider';
import SCCard from '../../../../components/Cards/SCCard';
import democracyIcon from '../../../../assets/icons/democracy.svg';
import educationIcon from '../../../../assets/icons/education.svg';
import healthIcon from '../../../../assets/icons/health.svg';
import livingIcon from '../../../../assets/icons/living.svg';
import workIcon from '../../../../assets/icons/work.svg';
import smileyIcon from '../../../../assets/icons/smiley.svg';
import targetIcon from '../../../../assets/icons/target.svg';
import radioIcon from '../../../../assets/icons/list.svg';
import amountIcon from '../../../../assets/icons/trending-up.svg';
import { ANSWERTYPE, QUESTIONAREACOMPLETE } from '../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.Spacing.M,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.Spacing.S} ${theme.Spacing.M}`,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftContainer: {},
  rightContainer: {
    flex: 1,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    cursor: 'pointer',
    marginRight: theme.Spacing.XXS,
  },
  title: {
    ...theme.Font.Headline.H4,
    textTransform: 'uppercase',
    marginBottom: theme.Spacing.XS,
    color: theme.Colors.neutral100,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph1,
    marginBottom: theme.Spacing.XXS,
    color: theme.Colors.neutral100,
  },
  body: {
    marginTop: theme.Spacing.XXS,
    display: 'flex',
    flexDirection: 'column',
    color: theme.Colors.neutral80,
    ...theme.Font.Paragraph.Paragraph2,
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.Spacing.XXS,
    marginTop: theme.Spacing.XS,
    alignItems: 'flex-end',
  },
  option: {
    marginRight: theme.Spacing.XXS,
  },
  iconSmileContainer: {
    padding: '6px',
    background: theme.Colors.neutral20,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.Spacing.XS,
  },
  iconSmile: {
    width: theme.Spacing.XS,
    height: theme.Spacing.XS,
  },
  iconTypeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.Spacing.XS,
    width: theme.Spacing.S,
    height: theme.Spacing.S,
  },
  iconType: {
    width: theme.Spacing.S,
    height: theme.Spacing.S,
  },
}));

function SurveyCardQuestion({
  uid,
  handleEdit,
  handleDelete,
  title,
  subtitle,
  indicator,
  type,
  answers,
}) {
  const classes = useStyles();

  // Don't hate me to much for this, I promise I wanted to do it well
  function getIndicatorIcon() {
    for (let i = 0; i < QUESTIONAREACOMPLETE.EDUCATION.categories.length; i += 1) {
      if (indicator === QUESTIONAREACOMPLETE.EDUCATION.categories[i].name) {
        return <img src={educationIcon} alt="icon" className={classes.iconType} />;
      }
    }
    for (let i = 0; i < QUESTIONAREACOMPLETE.DEMOCRACY.categories.length; i += 1) {
      if (indicator === QUESTIONAREACOMPLETE.DEMOCRACY.categories[i].name) {
        return <img src={democracyIcon} alt="icon" className={classes.iconType} />;
      }
    }
    for (let i = 0; i < QUESTIONAREACOMPLETE.HEALTH.categories.length; i += 1) {
      if (indicator === QUESTIONAREACOMPLETE.HEALTH.categories[i].name) {
        return <img src={healthIcon} alt="icon" className={classes.iconType} />;
      }
    }
    for (let i = 0; i < QUESTIONAREACOMPLETE.LIVING.categories.length; i += 1) {
      if (indicator === QUESTIONAREACOMPLETE.LIVING.categories[i].name) {
        return <img src={livingIcon} alt="icon" className={classes.iconType} />;
      }
    }
    for (let i = 0; i < QUESTIONAREACOMPLETE.WORK.categories.length; i += 1) {
      if (indicator === QUESTIONAREACOMPLETE.WORK.categories[i].name) {
        return <img src={workIcon} alt="icon" className={classes.iconType} />;
      }
    }
    return <div />;
  }

  function getTypeIcon() {
    switch (type) {
      case ANSWERTYPE.SMILEY_QUESTION:
        return smileyIcon;
      case ANSWERTYPE.RADIO_BUTTON_QUESTION:
        return radioIcon;
      case ANSWERTYPE.AMOUNT_QUESTION:
        return amountIcon;
      case ANSWERTYPE.FREQUENCY_CARD_QUESTION:
        return targetIcon;
      default:
        return smileyIcon;
    }
  }

  return (
    <SCCard className={classes.card} key={uid}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.leftContainer}>
            <div className={classes.title}>{title}</div>
            <div className={classes.subtitle}>{subtitle}</div>
          </div>
          <div className={classes.rightContainer}>
            <div className={classes.iconContainer}>
              <Edit2 className={classes.icon} onClick={handleEdit} />
              <Trash2 className={classes.icon} onClick={handleDelete} />
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.body}>
          <div className={classes.options}>
            <div className={classes.iconSmileContainer}>
              <img src={getTypeIcon()} alt="icon" className={classes.iconSmile} />
            </div>
            {answers.map((answer) => (
              <span key={answer.uuid} className={classes.option}>
                {answer.title}
              </span>
            ))}
          </div>

          {indicator && (
            <div className={classes.options}>
              <div className={classes.iconTypeContainer}>{getIndicatorIcon()}</div>
              <div className={classes.description}>{indicator}</div>
            </div>
          )}
        </div>
      </div>
    </SCCard>
  );
}

SurveyCardQuestion.propTypes = {
  uid: PropTypes.string,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  indicator: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
};

SurveyCardQuestion.defaultProps = {
  uid: '',
  handleEdit: () => {},
  handleDelete: () => {},
  title: '',
  subtitle: '',
  indicator: '',
  answers: [],
  type: '',
};

export default SurveyCardQuestion;
