const sv = {
  translation: {
    GENERAL_TITLE: 'VÄLKOMMEN',
    GENERAL_SUBTITLE: '',
    GENERAL_SUBMIT: 'Logga in',
    GENERAL_USERNAME: 'Användarnamn',
    GENERAL_EMAIL: 'E-post',
    GENERAL_PASSWORD: 'Lösenord',
    GENERAL_PASSWORD_INVALID:
      'Lösenordet måste innehålla åtta tecken varav minst en siffra, en stor och en liten bokstav.',
    GENERAL_CONFIRM_PASSWORD: 'Bekräfta lösenord',
    GENERAL_CONFIRM_PASSWORD_INVALID: 'Lösenorden matchar inte',
    GENERAL_LOGIN: 'Logga in',
    GENERAL_LOGOUT: 'Logga ut',
    GENERAL_REQUIRED: 'Obligatoriskt fält',
    GENERAL_EMAIL_INVALID: 'Kontrollera att du använder en giltig e-postadress',
    GENERAL_SING_IN: 'Logga in',
    GENERAL_SEND: 'Skicka',
    GENERAL_REGISTER: 'Skapa konto',
    GENERAL_FIRSTNAME: 'Förnamn',
    GENERAL_SURNAME: 'Efternamn',
    GENERAL_ORGANIZATION: 'Organisation',
    GENERAL_ROLE: 'Roll',
    GENERAL_DONE: 'Klar!',
    GENERAL_GO_TO_LOGIN: 'Gå till inloggningssidan',
    GENERAL_GO_TO_MAINPAGE: 'Gå till startsidan',
    GENERAL_DASHBOARD: 'Översikt',
    GENERAL_SURVEYS: 'Enkäter',
    GENERAL_USERSANDPERMISSIONS: 'Användare och behörigheter',
    GENERAL_PROFILE: 'Profil',
    GENERAL_SETTINGS: 'Inställningar',
    GENERAL_USERS: 'Användare',
    GENERAL_TABLE_ROWS_PER_PAGE: 'Rader per sida:',
    GENERAL_REDIRECTING: 'Arbetar...',
    GENERAL_PERMISSION: 'Roll',
    GENERAL_DATE: 'Enkätdatum',
    GENERAL_STATUS: 'Status',
    GENERAL_SURVEY_NAME: 'Enkätnamn',
    GENERAL_AREA: 'Område',
    GENERAL_FORUM: 'Verksamhet',
    GENERAL_CREATEDBY: 'Skapad av',
    GENERAL_SURVEYCODE: 'Enkätkod',
    GENERAL_DRAFT: 'Utkast',
    GENERAL_ARCHIVE: 'Arkivera',
    GENERAL_PUBLISH: 'Publicera',
    GENERAL_DUPLICATE: 'Duplicera',
    GENERAL_COPY: 'Duplicera',
    GENERAL_EDIT: 'Redigera',
    GENERAL_DELETE: 'Radera',
    GENERAL_ME: 'Jag',
    GENERAL_EXIT: 'Tillbaka',
    GENERAL_CANCEL: 'Avbryt',
    GENERAL_SAVE: 'Spara',
    GENERAL_TITLE_TEXT: 'Rubrik',
    GENERAL_SUBTITLE_TEXT: 'Underrubrik',
    GENERAL_COPY_PREFIX: 'Kopia av',
    GENERAL_ACCEPT: 'Acceptera',
    GENERAL_COLOR: 'Färg',
    GENERAL_LOADING: 'Arbetar...',
    GENERAL_MIN_LENGTH_REQUIRED: 'Fältet måste ha minst {{number}} tecken',
    GENERAL_MAX_LENGTH_REQUIRED: 'Fältet kan som mest ha {{number}} tecken',
    GENERAL_NO_INDICATOR: 'Ingen indikator',
    ERROR_NOT_FOUND_TITLE: '404 - Sidan hittades inte.',
    ERROR_NOT_FOUND_SUBTITLE:
      'Sidan du försökte nå kunde tyvärr inte hittas. Det kan bero på att sidan har flyttats, tagits bort eller att du angivit fel adress.',

    ERROR_NOT_FOUND_GO_BACK_MAIN: 'Gå till startsidan',
    ERROR_: 'Hoppsan! Något gick fel ...',
    ERROR_SOMETHING_WENT_WRONG: 'Hoppsan! Något gick fel ...',
    ERROR_RELOAD_OR_CALL_ADMIN:
      'Försök igen. Om det fortfarande inte fungerar kontakta en administratör av Läget',
    LOGIN_TEXT:
      'Läget är ett webbaserat analysverktyg framtaget av Rädda Barnen för att mäta social hållbarhet. Verktyget hämtar in data från appen Mina Svar. Genom Läget kan du analysera hur en programverksamhet påverkar och förflyttar deltagare inom fem fokusområden över tid. Du kan även utvärdera enskilda aktiviteter inom en programverksamhet utifrån deltagarnas nöjdhet och upplevd effekt.',
    LOGIN_SUBTEXT: 'Läs mer om vår verksamhet',
    LOGIN_OUR_URL_TEXT: ' på vår hemsida.',
    LOGIN_OUR_URL: 'https://www.raddabarnen.se/',

    // Footer //

    LOGIN_FOOTER_TEXT: 'Contact details - Info pages?',
    // ---- //

    LOGIN_HEADER_TEXT: 'Analysvertyg',
    LOGIN_PASSWORD_TEXT: ' ',

    //

    LOGIN_FORGOT_PASSWORD: 'Glömt lösenord?',
    LOGIN_FORGOT_PASSWORD_TITLE: 'Glömt lösenord?',
    LOGIN_FORGOT_PASSWORD_INFO:
      'Fyll i den e-postadress som du använde vid registrering av kontot.',
    LOGIN_FORGOT_PASSWORD_CONFIRMATION:
      'Vi har skickat ett meddelande till din e-postadress med instruktioner för hur du ändrar ditt lösenord. Om du inte har fått något meddelande, kontrollera din mapp för skräppost, kontrollera angiven e-postadress eller kontakta en administratör för Läget.',
    CREATE_ACCOUNT_TITLE: 'Skapa konto',
    CREATE_ACCOUNT_SUBTITLE: 'Fyll i fälten nedan',
    CREATE_ACCOUNT_PASSWORD_INFO:
      'Lösenordet måste innehålla åtta tecken varav minst en siffra, en stor och en liten bokstav.',
    CREATE_ACCOUNT_CONFIRMATION: 'Nytt konto har skapats!',
    CREATE_ACCOUNT_TERMS_AND_CONDITIONS: 'Jag godkänner att Rädda Barnen hanterar mina uppgifter.',
    CREATE_ACCOUNT_TERMS_AND_CONDITIONS_INFO: 'Läs vår',
    CREATE_ACCOUNT_TERMS_AND_CONDITIONS_LINK:
      'https://www.raddabarnen.se/om-oss/integritetspolicy/',
    CREATE_ACCOUNT_TERMS_AND_CONDITIONS_LINK_TEXT: ' integritetspolicy',

    // Remove copy and checkbox //
    CREATE_ACCOUNT_ACCEPT_HANDLE_DATA: 'Jag accepterar att min data hanteras av Rädda Barnen.',
    // - //

    CREATE_ACCOUNT_EMAIL_INFO: 'Används som användarnamn',
    RESET_PASSWORD_TITLE: 'Skapa nytt lösenord',

    // Remove or keep empty //
    RESET_PASSWORD_SUBTITLE: 'Fyll i fälten nedan',
    RESET_PASSWORD_CONFIRMATION: 'Nytt lösenord har skapats!',
    RESET_PASSWORD_SAVE_PASSWORD: 'Spara lösenordet',

    // Konto //
    PROFILE_TITLE: 'Mitt konto',
    PROFILE_SUBTITLE:
      'Här kan du ändra uppgifter såsom namn och lösenord. Uppgifter som är gråtonade är en del av din inbjudan till verktyget och kan därför inte ändras.',
    PROFILE_CHANGE_PASSWORD_TITLE: 'Ändra lösenord',
    PROFILE_CURRENT_PASSWORD: 'Befintligt lösenord',
    PROFILE_NEW_PASSWORD: 'Nytt lösenord',
    PROFILE_SAVE_PASSWORD: 'Spara nytt lösenord',
    PROFILE_DETAILS_TITLE: 'Dina uppgifter',
    PROFILE_SAVE_DETAILS: 'Spara ändringar',
    PROFILE_PASSWORD_UPDATE_SUCCESS: 'Lösenordet har uppdaterats',
    PROFILE_PASSWORD_UPDATE_SUCCESS_SUBTITLE: 'Lösenordet har uppdaterats',
    PROFILE_DETAILS_UPDATE_SUCCESS: 'Dina uppgifter har uppdaterats',
    PROFILE_DETAILS_UPDATE_SUCCESS_SUBTITLE: 'Dina uppgifter har uppdaterats',

    // Enkäter //
    SURVEYS_TITLE: 'Enkäter',
    SURVEYS_SUBTITLE:
      'Här kan du skapa nya enkäter genom att kopiera befintliga enkäter,  publicera enkäter för att få pinkoder och arkivera enkäter.',
    SURVEYS_LIST_DELETE_TITLE: 'Radera enkät',
    SURVEYS_LIST_DELETE_SUBTITLE: 'Är du säker att du vill radera enkäten?',
    SURVEYS_LIST_DUPLICATE_TITLE: 'Duplicera enkät',
    SURVEYS_LIST_DUPLICATE_SUBTITLE: 'Är du säker att du vill duplicera enkäten?',
    SURVEYS_LIST_DUPLICATE_MODAL_TITLE: 'Enkät duplicerad',
    SURVEYS_LIST_DUPLICATE_MODAL_SUBTITLE: 'Redigera enkäten nu',
    SURVEYS_LIST_DUPLICATE_MODAL_EDIT: 'Redigera',
    SURVEYS_LIST_ARCHIVE_TITLE: 'Arkivera enkät',
    SURVEYS_LIST_ARCHIVE_SUBTITLE: 'Är du säker att du vill arkivera enkäten?',
    SURVEYS_LIST_NO_DATA: 'Ingen enkät hittades',
    SURVEYS_LIST_ERROR_TITLE: 'Hoppsan! Något gick fel ...',
    SURVEYS_LIST_ERROR_SUBTITLE:
      'Försök igen. Om det fortfarande inte fungerar kontakta en administratör av Läget',
    SURVEYS_OVERVIEW_SAVE_AS_DRAFT: 'Spara utkast',
    SURVEYS_OVERVIEW_DETAILS: 'Enkätinformation',
    SURVEYS_OVERVIEW_SURVEY_NAME: 'Enkätnamn',
    SURVEYS_OVERVIEW_SURVEY_NAME_HELPER_TEXT: 'Max 20 tecken. Enkätnamnet visas i appen',
    SURVEYS_OVERVIEW_SURVEY_DESCRIPTION: 'Beskrivning',
    SURVEYS_OVERVIEW_SURVEY_DESCRIPTION_HELPER_TEXT:
      'Max 350 tecken. Beskrivningen visas enbart i analysen',

    // What to call the date the survey is ment to be used / the date the activty is held //
    SURVEYS_OVERVIEW_SURVEY_DATE: 'Enkätdatum',
    //

    SURVEYS_OVERVIEW_SURVEY_AREA: 'Plats',
    SURVEYS_OVERVIEW_SURVEY_FORUM: 'Program',
    SURVEYS_OVERVIEW_SURVEY_BODY_TEXT: 'Brödtext',

    SURVEYS_OVERVIEW_LIFE_JOURNEY: 'Läget-enkät',
    SURVEYS_OVERVIEW_ACTIVITY_JOURNEY: 'Aktivitetsenkät',

    // Check //
    SURVEYS_OVERVIEW_CARD_ENTRY_COMPLETION: 'Kapitelkort',
    SURVEYS_OVERVIEW_CARD_ENTRY_COMPLETION_THANKYOU: 'Tackkort',
    //

    SURVEYS_OVERVIEW_CARD_QUESTION_NUMBER: 'Fråga',
    SURVEYS_OVERVIEW_ADD_CARD: 'Nytt kort',
    SURVEYS_OVERVIEW_ADD_QUESTION: 'Ny fråga',

    SURVEYS_OVERVIEW_SURVEY_TYPE: 'Enkättyp',
    SURVEYS_OVERVIEW_SURVEY_SUBTYPE: 'Aktivitetstyp',
    SURVEYS_OVERVIEW_SURVEY_SUBTYPE_WORKSHOP: 'Workshop',
    SURVEYS_OVERVIEW_SURVEY_SUBTYPE_LECTURE: 'Föreläsning',
    SURVEYS_OVERVIEW_SURVEY_SUBTYPE_TRYON_ACTIVITY: 'Prova-på aktivitet',
    SURVEYS_OVERVIEW_SURVEY_SUBTYPE_TEAM_BUILDING: 'Teambuilding',
    SURVEYS_OVERVIEW_SURVEY_SUBTYPE_STUDY_VISITS: 'Studiebesök',
    SURVEYS_OVERVIEW_SURVEY_SUBTYPE_PROJECT_WORK: 'Projektarbete',
    SURVEYS_OVERVIEW_SURVEY_SUBTYPE_PARTY: 'Fest',
    SURVEYS_OVERVIEW_SURVEY_SUBTYPE_SOCIAL_ACTIVITY: 'Social aktivitet',
    SURVEYS_OVERVIEW_SURVEY_SUBTYPE_OTHER: 'Annat',

    SURVEYS_OVERVIEW_SURVEY_PARTNERS: 'Välj utförare',
    SURVEYS_OVERVIEW_SURVEY_PARTNERS_HELPERTEXT: 'Den utförare som ansvarar för aktiviteten',
    SURVEYS_OVERVIEW_SURVEY_BADGE: 'Utmärkelse',
    SURVEYS_OVERVIEW_SURVEY_BADGE_HELPERTEXT:
      'Enkät markeras med en stjärna på användarens tidslinje i appen',
    SURVEYS_OVERVIEW_SURVEY_PUBLISH_SUBTITLE:
      'Är du säker att du vill publicera enkäten? Du kan inte ändra på enkäten efter att den är publicerad',
    SURVEY_QUESTION_OVERVIEW_FORUM_HEALTHY_GENERATION: 'En frisk generation',
    SURVEY_QUESTION_OVERVIEW_FORUM_FOLK_HIGH_SCHOOL: 'Folkhögskolan',
    SURVEY_QUESTION_OVERVIEW_FORUM_YOUTH_CENTER: 'Fritidsgård',
    SURVEY_QUESTION_OVERVIEW_FORUM_PARENTS_FORUM: 'Föräldraforum',
    SURVEY_QUESTION_OVERVIEW_FORUM_GUY_FORUM: 'Killforum',
    SURVEY_QUESTION_OVERVIEW_FORUM_WOMAN_FOR_BACKBY: 'Kvinna för Bäckby',
    SURVEY_QUESTION_OVERVIEW_FORUM_GIRL_FORUM: 'Tjejforum',
    SURVEY_QUESTION_OVERVIEW_FORUM_YOUTH_FORUM: 'Ungdomsforum',
    SURVEY_QUESTION_OVERVIEW_FORUM_OTHERS: 'Annan verksamhet',
    SURVEYS_OVERVIEW_SURVEY_PUBLISH_TITLE: 'Publicera enkät',
    SURVEY_QUESTION_CHAPTER_CARD: 'Kapitelkort',
    SURVEY_QUESTION_CHAPTER_CARD_UNDERLINE: 'Understruken text',
    SURVEY_QUESTION_QUESTION_CARD: 'Fråga',
    SURVEYS_QUESTION_DELETE_TITLE: 'Ta bort fråga',
    SURVEYS_QUESTION_DELETE_SUBTITLE: 'Är du säker att du vill ta bort frågan?',
    SURVEY_QUESTION_QUESTION_TITLE: 'Skriv frågan här',
    SURVEY_QUESTION_QUESTION_TYPE: 'Välj svarstyp',
    SURVEY_QUESTION_QUESTION_ADITIONAL_CONTEXT: 'Ytterligare information (valfritt)',
    SURVEY_QUESTION_QUESTION_ADITIONAL_CONTEXT_TITLE: 'Ytterligare information',
    SURVEY_QUESTION_QUESTION_INDICATOR: 'Indikator',
    SURVEY_QUESTION_QUESTION_VALUE_OPTIONS: 'Svarsalternativ',
    SURVEY_QUESTION_QUESTION_VALUE_OPTIONS_DESCRIPTION: 'Välj svarsalternativ som passar din fråga',
    SURVEY_QUESTION_QUESTION_TYPE_SMILEY: 'Betygsskala - Smiley',
    SURVEY_QUESTION_QUESTION_TYPE_RADIO: 'Flervalsfrågor',
    SURVEY_QUESTION_QUESTION_TYPE_MULTI: 'Flervalsfrågor',
    SURVEY_QUESTION_QUESTION_SCALE_TITLE_A:
      'Stämmer inte alls | Stämmer knappt | Stämmer undantagsvis | Stämmer i låg grad | Sådär | Stämmer till viss del | Stämmer i hög grad | Stämmer för det mesta | Stämmer helt',
    SURVEY_QUESTION_QUESTION_SCALE_TITLE_B:
      'Stämmer inte alls | Stämmer sällan | Stämmer i undantagsfall | Stämmer i låg grad | Ibland | Stämmer till viss del | Stämmer i hög grad | Stämmer för det mesta | Stämmer helt',

    // Remove? //
    DASHBOARD_TITLE: 'Översikt',
    DASHBOARD_SUBTITLE: ' ',
    //

    USERS_TITLE: 'Användare och behörigheter',
    USERS_SUBTITLE: 'Här kan du lägga till, ändra behörigheter och status på användare.',
    USERS_INVITE_NEW_USER: 'Lägg till användare',

    // Invite new users //
    INVITE_USER_TITLE: 'Skicka inbjudan',
    INVITE_USER_DESCRIPTION:
      'Fyll i nedanstående information för att bjuda in en ny användare till Läget',
    INVITE_USER_BUTTON: 'Skicka inbjudan',
    INVITE_USER_CONFIRMATION: 'Inbjudan skickad',
    INVITE_USER_BUTTON_CONFIRMATION: 'Tillbaka till föregående sida',
  },
};

export default sv;
