import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../../utils/translations/translationKeys';
import SCTable from '../../../components/Table/SCTable';
import tableColumns from './users-table-columns';
import SCButton from '../../../components/Buttons/SCButton';
import { ROLE, STATUS } from '../../../utils/constants';
import SCModal from '../../../components/Modal/SCModal';
import CreateAccountModal from './CreateAccountModal';
import CreateAccountConfirmation from './CreateAccountConfirmation';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.Spacing.L} ${theme.Spacing.M}`,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    ...theme.Font.Headline.H2,
    textTransform: 'uppercase',
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.M,
    marginTop: 0,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph2,
    color: theme.Colors.neutral60,
    marginBottom: theme.Spacing.M,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justyfiContent: 'space-between',
    marginTop: theme.Spacing.L,
  },
  headerLeftPannel: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  headerRightPannel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  button: {
    padding: `${theme.Spacing.XXS} ${theme.Spacing.S}`,
    borderRadius: '2px',
  },
}));

function getIcon(name, surname) {
  if (name) {
    if (surname) {
      return `${name[0]}${surname[0]}`;
    }
    return name[0];
  }
  return '-';
}

function getUserName(name, surname) {
  if (name) {
    if (surname) {
      return `${name} ${surname}`;
    }
    return name;
  }
  return '-';
}

function populateRows(data, userProfile, t) {
  if (data) {
    return data.map(({ name, surname, email, company, role, status, uid }) => ({
      username: uid === userProfile ? t(translationKeys.GENERAL_ME) : getUserName(name, surname),
      email,
      organization: company && company.name,
      permission: role && ROLE[role.toUpperCase()],
      status: status && STATUS[status.toUpperCase()],
      icon: getIcon(name, surname),
      key: uid,
      disabled: uid === userProfile,
    }));
  }
  return [];
}

function UsersAndPermissionsPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [createSuccessful, setCreateSuccessful] = useState(false);
  const companies = useSelector((state) => state.userListModel.companies);
  const userList = useSelector((state) => state.userListModel.userList);
  const userProfile = useSelector((state) => state.userModel.userInfo);

  function onClickInviteUser() {
    setCreateUserOpen(true);
  }

  async function fetchUserListData() {
    await dispatch.userListModel.getUserList();
  }

  async function fetchCompanies() {
    const response = await dispatch.userListModel.getCompaniesList();
    return response;
  }

  useEffect(() => {
    fetchCompanies();
    fetchUserListData();
    // eslint-disable-next-line
  }, []);

  function handlePermissionChange(value, key) {
    dispatch.userListModel.changeRole({ user: key, role: value });
  }

  function handleStatusChange(value, key) {
    dispatch.userListModel.changeStatus({ user: key, disabled: value === STATUS.BLOCKED });
  }
  function handleCreateUserSuccess(success) {
    setCreateSuccessful(success);
  }

  function handleConfirmCreateUser() {
    setCreateSuccessful(false);
    setCreateUserOpen(false);
  }

  const rows = populateRows(userList.list, userProfile.uid, t);
  return (
    <>
      <SCModal isOpen={createUserOpen} handleClose={() => setCreateUserOpen(false)}>
        {!createSuccessful ? (
          <CreateAccountModal companies={companies} onSubmit={handleCreateUserSuccess} />
        ) : (
          <CreateAccountConfirmation onSubmit={handleConfirmCreateUser} />
        )}
      </SCModal>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.headerLeftPannel}>
            <h2 className={classes.title}>{t(translationKeys.USERS_TITLE)}</h2>
            <p className={classes.subtitle}>{t(translationKeys.USERS_SUBTITLE)}</p>
          </div>
          <div className={classes.headerRightPannel}>
            <SCButton
              variant="contained"
              color="primary"
              onClick={onClickInviteUser}
              className={classes.button}
            >
              {t(translationKeys.USERS_INVITE_NEW_USER)}
            </SCButton>
          </div>
        </div>
        <div className={classes.innerContainer}>
          <SCTable
            columns={tableColumns(handlePermissionChange, handleStatusChange, t)}
            rows={rows}
          />
        </div>
      </div>
    </>
  );
}

export default UsersAndPermissionsPage;
