import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../../../utils/translations/translationKeys';
import SCTextField from '../SCTextField';

const SCTextFieldPassword = ({ disabled, placeholder, helperText, errorOnLive }) => {
  const { t } = useTranslation();
  function validatePassword(value) {
    if (!value || value === '') {
      return t(translationKeys.GENERAL_REQUIRED);
    }
    return null;
  }

  return (
    <Field
      name="confirmPassword"
      render={({ input, meta }) => (
        <SCTextField
          name={input.name}
          onChange={input.onChange}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          value={input.value}
          label={t(translationKeys.GENERAL_CONFIRM_PASSWORD)}
          hide
          disabled={disabled}
          showError={meta.touched && meta.error !== undefined && (errorOnLive || meta.submitFailed)}
          error={meta.error}
          placeholder={placeholder}
          helperText={helperText}
        />
      )}
      validate={validatePassword}
    />
  );
};

SCTextFieldPassword.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  errorOnLive: PropTypes.bool,
};

SCTextFieldPassword.defaultProps = {
  disabled: false,
  placeholder: '',
  helperText: undefined,
  errorOnLive: false,
};

export default SCTextFieldPassword;
