import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { X } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  card: {
    maxHeight: '80vh',
    overflow: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: theme.Grid.Width.Medium,
    transform: `translate(-50%, -50%)`,
    backgroundColor: theme.Colors.backgroundColor,
    ...theme.Elevations.high,
    padding: `${theme.Spacing.XL} ${theme.Spacing.L}`,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
  },
  iconContainer: {
    width: '100',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconClose: {
    cursor: 'pointer',
  },
}));

function SCModal({ isOpen, handleClose, children, className, withCloseIcon }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={classnames(classes.card, className)}>
        {withCloseIcon && (
          <div className={classes.iconContainer}>
            <X
              size="16"
              color={theme.Colors.neutral80}
              className={classes.iconClose}
              onClick={handleClose}
            />
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
}

SCModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  withCloseIcon: PropTypes.bool,
};

SCModal.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  children: {},
  className: '',
  withCloseIcon: true,
};

export default SCModal;
