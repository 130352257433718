import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, FormControl, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  textStyle: {
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral60,
  },
  textHighlighStyle: {
    ...theme.Font.Paragraph.Paragraph1,
    color: theme.Colors.neutral80,
  },
}));

const SelectCell = ({ key, align, options, children, highlight, onChange, rowKey, disabled }) => {
  const classes = useStyles();
  const [value, setValue] = useState(children);

  function handleChange(newValue) {
    setValue(newValue.target.value);
    onChange(newValue.target.value, rowKey);
  }
  return (
    <TableCell key={key} align={align}>
      <FormControl className={classes.formControl}>
        <Select
          value={value}
          onChange={handleChange}
          className={classnames(classes.textStyle, { [classes.textHighlighStyle]: highlight })}
          disabled={disabled}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TableCell>
  );
};

SelectCell.propTypes = {
  key: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  highlight: PropTypes.bool,
  onChange: PropTypes.func,
  rowKey: PropTypes.string,
  disabled: PropTypes.bool,
};

SelectCell.defaultProps = {
  key: '',
  align: 'center',
  children: '',
  options: [],
  highlight: false,
  onChange: () => {},
  rowKey: '',
  disabled: false,
};

export default SelectCell;
