import { FORM_ERROR } from 'final-form';
import SCAxios from './SCAxios';
import { FormError, passthroughNetworkErrors } from './helpers/error-handling';

const AdminServices = {
  async listUsers() {
    try {
      const response = await SCAxios.get(`user`);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('list-users-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async updateUser(userId, data) {
    try {
      const response = await SCAxios.post(`admin/${userId}`, data);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('update-admin-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async listCompanies() {
    try {
      const response = await SCAxios.get(`companies`);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('list-companies-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
  async createUser(values) {
    try {
      const response = await SCAxios.post(`user`, values);
      return response;
    } catch (error) {
      return passthroughNetworkErrors(error, (err) => {
        throw FormError('list-companies-error', {
          message: err.data.message,
          [FORM_ERROR]: err.data.message,
        });
      });
    }
  },
};

export default AdminServices;
