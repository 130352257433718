import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useParams, useHistory } from 'react-router-dom';
import translationKeys from '../../../../utils/translations/translationKeys';
import SurveyEditHeader from './SurveyEditHeader';
import SurveyCardHeader from './SurveyCardHeader';
import SurveyCardInfo from './SurveyCardInfo';
import SurveyCardQuestion from './SurveyCardQuestion';
import SurveyCardSeparator from './SurveyCardSeparator';
import SurveyCreateQuestionModal from './QuestionsSection/SurveyCreateQuestionModal';
import Paths from '../../../../router/router';
import { ANSWERTYPE, QUESTIONTYPE, SURVEYSTATUS } from '../../../../utils/constants';
import SCModal from '../../../../components/Modal/SCModal';
import SCModalDialog from '../../../../components/Modal/SCModalDialog';
import SCModalAlert from '../../../../components/Modal/SCModalAlert';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    background: theme.Colors.backgroundDark,
  },
  innerContainer: {
    padding: `${theme.Spacing.L} ${theme.Spacing.M}`,
    display: 'flex',
    justifyContent: 'center',
  },
  cardContainer: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    ...theme.Font.Headline.H3,
  },
  modal: {
    width: theme.Grid.Width.Big,
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function getHeaderValues(s) {
  if (s === {}) {
    return {};
  }
  return {
    name: s.name,
    description: s.description,
    date: s.activityDate,
    area: s.cities ? s.cities[0] : undefined,
    type: s.type,
    subtype: s.subtype,
    status: s.status,
    partners: s.partners ? s.partners[0] : undefined,
    significant: s.significant,
    forum: s.forum,
  };
}

function isDirty(initialValues, values) {
  return (
    (values.name && initialValues.name !== values.name) ||
    (values.description && initialValues.description !== values.description) ||
    (values.date && initialValues.date !== values.date) ||
    (values.type && initialValues.type !== values.type) ||
    (values.type === QUESTIONTYPE.ACTIVITY_SURVEY &&
      values.subtype &&
      initialValues.subtype !== values.subtype) ||
    (values.area && initialValues.area.uid !== values.area) ||
    (values.partners && initialValues.partners.uid !== values.partners) ||
    (values.forum && initialValues.forum !== values.forum) ||
    (values.significant !== undefined && initialValues.significant !== values.significant)
  );
}

function isAbleToPublish(values) {
  return (
    values.name &&
    values.description &&
    values.date &&
    values.type &&
    (values.type === QUESTIONTYPE.LIFEJOURNEY_SURVEY || values.subtype) &&
    values.area &&
    values.partners &&
    values.significant !== undefined
  );
}

function getInitialValues(initialValues) {
  const { area, partners, ...rest } = initialValues;
  return {
    area: area ? area.uid : undefined,
    partners: partners ? partners.uid : undefined,
    forum: 'forum',
    ...rest,
  };
}

function getCards(
  survey,
  t,
  dispatch,
  handleNewChapter,
  handleNewQuestion,
  setModalState,
  setConfirmModal,
  setAlert,
) {
  if (survey && survey.questions) {
    let actQuestionNumber = 0;
    return survey.questions.map((question, index) => {
      if (question.type === ANSWERTYPE.CHAPTER_CARD_QUESTION) {
        return (
          <React.Fragment key={`fragment${question.uuid}`}>
            {index === 0 && (
              <SurveyCardSeparator
                key={`separatorini${question.uuid}`}
                handleAddQuestion={handleNewQuestion(index)}
                handleAddCard={handleNewChapter(index)}
              />
            )}
            <SurveyCardInfo
              key={question.uuid}
              id={question.uuid}
              allowDelete={index < survey.questions.length - 1}
              title={
                index < survey.questions.length - 1
                  ? t(translationKeys.SURVEYS_OVERVIEW_CARD_ENTRY_COMPLETION)
                  : t(translationKeys.SURVEYS_OVERVIEW_CARD_ENTRY_COMPLETION_THANKYOU)
              }
              subtitle={question.title}
              description={question.description}
              handleEdit={() => {
                setModalState({
                  open: true,
                  onSubmit: async () => {
                    await dispatch.surveyModel.getSurvey({ uid: survey.uid });
                    setModalState({ open: false });
                  },
                  onCancel: () => setModalState({ open: false }),
                  isChapter: true,
                  initialValues: question,
                });
              }}
              handleDelete={() => {
                setConfirmModal({
                  open: true,
                  title: t(translationKeys.SURVEYS_QUESTION_DELETE_TITLE),
                  subtitle: t(translationKeys.SURVEYS_QUESTION_DELETE_SUBTITLE),
                  acceptText: t(translationKeys.GENERAL_DELETE),
                  cancelText: t(translationKeys.GENERAL_CANCEL),
                  handleAccept: async () => {
                    await dispatch.surveyModel.deleteQuestion({
                      uid: question.uuid,
                    });
                    const firstQuestions = survey.questions.slice(0, index);
                    const lastQuestions = survey.questions.slice(index + 1);
                    const newQuestions = [...firstQuestions, ...lastQuestions];
                    const responseInner = await dispatch.surveyModel.editQuestions({
                      uid: survey.uid,
                      questions: newQuestions.map(
                        (questionInner) => questionInner.uid || questionInner.uuid,
                      ),
                    });
                    if (!responseInner.message) {
                      setConfirmModal({ open: false });
                    } else {
                      setAlert(responseInner.message);
                      setConfirmModal({ open: false });
                    }
                  },
                  handleCancel: () => setConfirmModal({ open: false }),
                });
              }}
            />
            {index < survey.questions.length - 1 && (
              <SurveyCardSeparator
                key={`separator${question.uuid}`}
                handleAddQuestion={handleNewQuestion(index + 1)}
                handleAddCard={handleNewChapter(index + 1)}
              />
            )}
          </React.Fragment>
        );
      }
      actQuestionNumber += 1;
      return (
        <React.Fragment key={`fragment${question.uuid}`}>
          {index === 0 && (
            <SurveyCardSeparator
              key={`separatorini${question.uuid}`}
              handleAddQuestion={handleNewQuestion(index)}
              handleAddCard={handleNewChapter(index)}
            />
          )}
          <SurveyCardQuestion
            key={question.uuid}
            id={question.uuid}
            title={`${t(
              translationKeys.SURVEYS_OVERVIEW_CARD_QUESTION_NUMBER,
            )} ${actQuestionNumber}`}
            subtitle={question.title}
            description={question.description}
            answers={question.answers}
            type={question.type}
            indicator={question.indicator}
            handleEdit={() => {
              setModalState({
                open: true,
                onSubmit: async () => {
                  await dispatch.surveyModel.getSurvey({ uid: survey.uid });
                  setModalState({ open: false });
                },
                onCancel: () => setModalState({ open: false }),
                isChapter: false,
                initialValues: question,
              });
            }}
            handleDelete={() => {
              setConfirmModal({
                open: true,
                title: t(translationKeys.SURVEYS_QUESTION_DELETE_TITLE),
                subtitle: t(translationKeys.SURVEYS_QUESTION_DELETE_SUBTITLE),
                acceptText: t(translationKeys.GENERAL_DELETE),
                cancelText: t(translationKeys.GENERAL_CANCEL),
                handleAccept: async () => {
                  await dispatch.surveyModel.deleteQuestion({
                    uid: question.uuid,
                  });
                  const firstQuestions = survey.questions.slice(0, index);
                  const lastQuestions = survey.questions.slice(index + 1);
                  const newQuestions = [...firstQuestions, ...lastQuestions];
                  const responseInner = await dispatch.surveyModel.editQuestions({
                    uid: survey.uid,
                    questions: newQuestions.map(
                      (questionInner) => questionInner.uid || questionInner.uuid,
                    ),
                  });
                  if (!responseInner.message) {
                    setConfirmModal({ open: false });
                  } else {
                    setAlert(responseInner.message);
                    setConfirmModal({ open: false });
                  }
                },
                handleCancel: () => setConfirmModal({ open: false }),
              });
            }}
          />
          <SurveyCardSeparator
            key={`separator${question.uuid}`}
            handleAddQuestion={handleNewQuestion(index + 1)}
            handleAddCard={handleNewChapter(index + 1)}
            index={index + 1}
          />
        </React.Fragment>
      );
    });
  }
  return [];
}
function SurveyEditPage() {
  const history = useHistory();
  const { idSurvey } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const survey = useSelector((state) => state.surveyModel.survey);
  const [modalState, setModalState] = useState({
    open: false,
    onSubmit: () => {},
    onCancel: () => setModalState({ open: false }),
    isChapter: false,
    initialValues: {},
  });
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [ableToPublish, setAbleToPublish] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    title: '',
    subtitle: '',
    acceptText: '',
    cancelText: '',
    handleAccept: () => {},
    handleCancel: () => {},
    open: false,
  });
  const [alert, setAlert] = useState('');
  const [valuesEdited, setValuesEdited] = useState({});

  useEffect(() => {
    async function fetchSurvey() {
      setLoading(true);
      const response = await dispatch.surveyModel.getSurvey({ uid: idSurvey });
      setLoading(false);
      if (response.status !== SURVEYSTATUS.DRAFT) {
        history.replace(Paths.SURVEYS);
      }
      if (response.message) {
        setAlert(response.message);
      }
      return response;
    }
    fetchSurvey();
    // eslint-disable-next-line
  }, []);

  function handleGoBack() {
    dispatch.surveyModel.cleanSurvey();
    history.push(Paths.SURVEYS);
  }

  function handleNewChapter(index) {
    return () => {
      setModalState({
        open: true,
        onSubmit: (response) => {
          const firstQuestions = survey.questions.slice(0, index);
          const lastQuestions = survey.questions.slice(index);
          const newQuestions = [...firstQuestions, response, ...lastQuestions];
          const responseInner = dispatch.surveyModel.editQuestions({
            uid: survey.uid,
            questions: newQuestions.map((question) => question.uid || question.uuid),
          });
          if (!responseInner.message) {
            setModalState({ open: false });
          } else {
            setAlert(responseInner.message);
          }
        },

        onCancel: () => setModalState({ open: false }),
        isChapter: true,
        initialValues: {},
      });
    };
  }

  function handleNewQuestion(index) {
    return () => {
      setModalState({
        open: true,
        onSubmit: (response) => {
          const firstQuestions = survey.questions.slice(0, index);
          const lastQuestions = survey.questions.slice(index);
          const newQuestions = [...firstQuestions, response, ...lastQuestions];
          const responseInner = dispatch.surveyModel.editQuestions({
            uid: survey.uid,
            questions: newQuestions.map((question) => question.uid || question.uuid),
          });
          if (!responseInner.message) {
            setModalState({ open: false });
          } else {
            setAlert(responseInner.message);
          }
        },
        onCancel: () => setModalState({ open: false }),
        isChapter: false,
        initialValues: {},
      });
    };
  }

  function handleChange(values) {
    setValuesEdited(values);
  }

  async function onSubmit(values) {
    if (values.publishSubmit) {
      setConfirmModal({
        open: true,
        title: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_PUBLISH_TITLE),
        subtitle: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_PUBLISH_SUBTITLE),
        acceptText: t(translationKeys.GENERAL_PUBLISH),
        cancelText: t(translationKeys.GENERAL_CANCEL),
        handleAccept: async () => {
          const response = await dispatch.surveyModel.publishSurvey({
            uid: idSurvey,
            ...valuesEdited,
          });
          if (!response.message) {
            history.replace(Paths.SURVEYS);
          } else {
            setAlert(response.message);
          }
          return response;
        },
        handleCancel: () => {
          setConfirmModal({ open: false });
        },
      });
    } else {
      const response = await dispatch.surveyModel.saveAsDraftSurvey({
        uid: idSurvey,
        ...valuesEdited,
      });
      if (response.message) {
        setAlert(response.message);
      }
      return response;
    }
    return true;
  }

  return (
    <div className={classes.container}>
      {loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress size="140px" />
        </div>
      ) : (
        <>
          <Form onSubmit={onSubmit} initialValues={getInitialValues(getHeaderValues(survey))}>
            {({ handleSubmit, values, form }) => {
              handleChange(values);
              setDirty(isDirty(getHeaderValues(survey), values));
              setAbleToPublish(isAbleToPublish(values));
              return (
                <form onSubmit={handleSubmit}>
                  <SurveyEditHeader
                    handleBackButton={handleGoBack}
                    loading={loading}
                    dirty={dirty}
                    ableToPublish={ableToPublish}
                    form={form}
                  />
                  <div className={classes.innerContainer}>
                    <SCModalDialog
                      isOpen={confirmModal.open}
                      handleClose={() => setConfirmModal({ open: false })}
                      title={confirmModal.title}
                      subtitle={confirmModal.subtitle}
                      buttonAcceptText={confirmModal.acceptText}
                      buttonCancelText={confirmModal.cancelText}
                      onAcceptClick={confirmModal.handleAccept}
                      onCancelClick={confirmModal.handleCancel}
                    />
                    <SCModalAlert
                      title={t(translationKeys.SURVEYS_LIST_ERROR_TITLE)}
                      subtitle={t(translationKeys.SURVEYS_LIST_ERROR_SUBTITLE)}
                      description={alert}
                      isOpen={!!alert}
                      buttonText={t(translationKeys.GENERAL_ACCEPT)}
                      onClick={() => setAlert('')}
                    />
                    <SCModal
                      className={classes.modal}
                      withCloseIcon={false}
                      isOpen={modalState.open}
                    >
                      <SurveyCreateQuestionModal
                        onSubmit={modalState.onSubmit}
                        onCancel={modalState.onCancel}
                        isChapter={modalState.isChapter}
                        initialValues={modalState.initialValues}
                      />
                    </SCModal>
                    <div className={classes.cardContainer}>
                      <SurveyCardHeader
                        initialValues={getHeaderValues(survey)}
                        values={values}
                        setDirty={setDirty}
                        setAbleToPublish={setAbleToPublish}
                        handleChange={handleChange}
                      />
                      <h3 className={classes.title}>
                        {survey.type === QUESTIONTYPE.LIFEJOURNEY_SURVEY
                          ? t(translationKeys.SURVEYS_OVERVIEW_LIFE_JOURNEY)
                          : t(translationKeys.SURVEYS_OVERVIEW_ACTIVITY_JOURNEY)}
                      </h3>
                      {getCards(
                        survey,
                        t,
                        dispatch,
                        handleNewChapter,
                        handleNewQuestion,
                        setModalState,
                        setConfirmModal,
                        setAlert,
                      )}
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </>
      )}
    </div>
  );
}

export default SurveyEditPage;
