import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialPicker } from 'react-color';

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    marginBottom: theme.Spacing.S,
  },
  textLabel: {
    ...theme.Font.Paragraph.Paragraph4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textInfoStyle: {
    margin: 0,
    ...theme.Font.Paragraph.Paragraph4,
    color: theme.Colors.neutral60,
  },
  datepicker: {
    width: '100%',
  },
}));

const SCColorpickerField = ({
  label,
  className,
  error,
  showError,
  onChange,
  onBlur,
  onFocus,
  helperText,
  value,
}) => {
  const classes = useStyles();
  const [color, setColor] = useState(value || '');
  const handleColorChange = (colorChanged) => {
    setColor(colorChanged.hex);
    onChange(colorChanged.hex);
  };
  return (
    <div className={classnames(classes.checkboxContainer, className)}>
      <InputLabel classes={{ root: classes.textLabel }} error={showError}>
        {label}
      </InputLabel>
      <MaterialPicker
        color={color}
        onChange={handleColorChange}
        label={label}
        onFocus={onFocus}
        onBlur={onBlur}
        disableAlpha
        classes={{ root: classes.textLabel }}
      />
      {!showError && helperText && (
        <FormHelperText classes={{ root: classes.textInfoStyle }}>{helperText}</FormHelperText>
      )}
      {showError && (
        <FormHelperText classes={{ root: classes.textInfoStyle }} error={showError}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

SCColorpickerField.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.string,
  showError: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
};

SCColorpickerField.defaultProps = {
  label: '',
  className: '',
  error: undefined,
  showError: false,
  helperText: undefined,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  value: undefined,
};

export default SCColorpickerField;
