import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SCButton from '../Buttons/SCButton';
import SCModal from './SCModal';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.Font.Headline.H4,
    textTransform: 'uppercase',
    margin: 0,
    textAlign: 'center',
    marginBottom: theme.Spacing.S,
    color: theme.Colors.neutral100,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral80,
    textAlign: 'center',
    margin: 0,
    marginBottom: theme.Spacing.S,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  button: {
    marginBottom: theme.Spacing.XS,
  },
}));

function SCModalDialog({
  isOpen,
  handleClose,
  title,
  subtitle,
  buttonAcceptText,
  buttonCancelText,
  onAcceptClick,
  onCancelClick,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  async function handleAccept(e) {
    setLoading(true);
    await onAcceptClick(e);
    if (isMounted.current) {
      setLoading(false);
    }
  }
  return (
    <SCModal isOpen={isOpen} handleClose={handleClose}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>{subtitle}</div>
      <div className={classes.buttons}>
        <SCButton
          variant="outlined"
          color="primary"
          type="submit"
          loading={loading}
          onClick={handleAccept}
          className={classes.button}
          disabled={loading}
        >
          {buttonAcceptText}
        </SCButton>
        <SCButton variant="contained" color="primary" onClick={onCancelClick} disabled={loading}>
          {buttonCancelText}
        </SCButton>
      </div>
    </SCModal>
  );
}

SCModalDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonAcceptText: PropTypes.string,
  buttonCancelText: PropTypes.string,
  onAcceptClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

SCModalDialog.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  title: '',
  subtitle: '',
  buttonAcceptText: '',
  buttonCancelText: '',
  onAcceptClick: () => {},
  onCancelClick: () => {},
};

export default SCModalDialog;
