import React from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paths from '../../router/router';
import translationKeys from '../../utils/translations/translationKeys';
import SCTextFieldPassword from '../../components/Form/Fields/TextFields/SCTextFieldPassword';
import SCTextFieldEmail from '../../components/Form/Fields/TextFields/SCTextFieldEmail';
import LinkButton from '../../components/Buttons/LinkButton';
import ErrorField from '../../components/Form/Errors/ErrorField';
import SCContainedModal from '../../components/Modal/SCContainedModal';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import SCButton from '../../components/Buttons/SCButton';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.Spacing.XS,
  },
  linkButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textHeader: {
    textAlign: 'center',
    margin: 0,
    textTransform: 'uppercase',
    ...theme.Font.Headline.H3,
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.L,
  },
  forgotPasswordBackground: {
    backgroundColor: theme.Colors.neutral100,
    opacity: '0.64',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
  },
  formContainer: {
    position: 'relative',
    padding: theme.Spacing.L,
  },
  background: {
    backgroundColor: theme.Colors.neutral100,
    opacity: '0.64',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: '9',
  },
}));

function LoginForm({
  forgotPasswordOpen,
  setForgotPasswordOpen,
  forgotPasswordSuccess,
  setForgotPasswordSuccess,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  async function onSubmit(values) {
    const response = await dispatch.userModel.login(values);
    if (response.accessToken) {
      history.replace(Paths.ROOT);
    }
    return response;
  }

  return (
    <>
      {forgotPasswordOpen && <div className={classes.background} />}
      <div className={classes.formContainer}>
        {forgotPasswordOpen && (
          <SCContainedModal onClose={() => setForgotPasswordOpen(false)}>
            {!forgotPasswordSuccess ? (
              <ForgotPasswordForm onSubmit={() => setForgotPasswordSuccess(true)} />
            ) : (
              <ForgotPasswordSuccess
                onSubmit={() => {
                  setForgotPasswordSuccess(false);
                  setForgotPasswordOpen(false);
                }}
              />
            )}
          </SCContainedModal>
        )}
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitError, submitting }) => (
            <form onSubmit={handleSubmit}>
              <h3 className={classes.textHeader}> {t(translationKeys.GENERAL_SING_IN)}</h3>
              <SCTextFieldEmail
                placeholder={t(translationKeys.GENERAL_USERNAME)}
                label={t(translationKeys.GENERAL_USERNAME)}
              />
              <SCTextFieldPassword
                isLogin
                placeholder={t(translationKeys.GENERAL_PASSWORD)}
                helperText={t(translationKeys.LOGIN_PASSWORD_TEXT)}
              />
              <div className={classes.linkButtonContainer}>
                <LinkButton
                  className={classes.linkButton}
                  onClick={() => {
                    setForgotPasswordOpen(true);
                  }}
                >
                  {t(translationKeys.LOGIN_FORGOT_PASSWORD)}
                </LinkButton>
              </div>
              {submitError && <ErrorField>{submitError}</ErrorField>}
              <div className={classes.buttons}>
                <SCButton
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={submitting}
                  loading={submitting}
                >
                  {t(translationKeys.GENERAL_SING_IN)}
                </SCButton>
              </div>
            </form>
          )}
        </Form>
      </div>
    </>
  );
}

LoginForm.propTypes = {
  setForgotPasswordOpen: PropTypes.func,
  forgotPasswordOpen: PropTypes.bool,
  forgotPasswordSuccess: PropTypes.bool,
  setForgotPasswordSuccess: PropTypes.func,
};

LoginForm.defaultProps = {
  setForgotPasswordOpen: () => {},
  forgotPasswordOpen: false,
  forgotPasswordSuccess: false,
  setForgotPasswordSuccess: () => {},
};

export default LoginForm;
