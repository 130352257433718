import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ErrorField from '../../../components/Form/Errors/ErrorField';
import translationKeys from '../../../utils/translations/translationKeys';
import SCTextFieldEmail from '../../../components/Form/Fields/TextFields/SCTextFieldEmail';
import SCSelectFieldDefault from '../../../components/Form/Fields/Inputs/SCSelectFieldDefault';
import SCButton from '../../../components/Buttons/SCButton';
import { ROLE } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.Spacing.M,
  },
  linkButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textHeader: {
    textAlign: 'center',
    margin: 0,
    marginBottom: theme.Spacing.S,
    textTransform: 'uppercase',
    ...theme.Font.Headline.H4,
    color: theme.Colors.neutral100,
  },
  textInfo: {
    textAlign: 'center',
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral80,
    marginBottom: theme.Spacing.L,
  },
}));

function CreateAccountModal({ companies, onSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  async function onHandleSubmit(values) {
    const response = await dispatch.userListModel.createUser({
      email: values.email,
      company: companies.find((company) => {
        return company.name === values.company;
      }).uid,
      role: values.role || ROLE.VIEWER,
    });
    if (response.message) {
      return response;
    }
    onSubmit(true);
    return true;
  }

  return (
    <Form onSubmit={onHandleSubmit} initialValues={{ role: ROLE.VIEWER }}>
      {({ handleSubmit, submitError, submitting, errors }) => (
        <form onSubmit={handleSubmit}>
          <h4 className={classes.textHeader}> {t(translationKeys.INVITE_USER_TITLE)}</h4>
          <p className={classes.textInfo}>{t(translationKeys.INVITE_USER_DESCRIPTION)}</p>
          <SCTextFieldEmail placeholder={t(translationKeys.GENERAL_EMAIL)} />
          <SCSelectFieldDefault
            name="company"
            options={companies.map((company) => {
              return { value: company.name };
            })}
            label={t(translationKeys.GENERAL_ORGANIZATION)}
            required
          />
          <SCSelectFieldDefault
            name="role"
            options={Object.values(ROLE).map((option) => ({ value: option }))}
            label={t(translationKeys.GENERAL_ROLE)}
            required
          />
          {submitError && <ErrorField>{submitError}</ErrorField>}
          <div className={classes.buttons}>
            <SCButton
              variant="contained"
              type="submit"
              color="primary"
              disabled={submitting || !!(errors.email || errors.company || errors.role)}
              loading={submitting}
            >
              {t(translationKeys.GENERAL_SEND)}
            </SCButton>
          </div>
        </form>
      )}
    </Form>
  );
}

CreateAccountModal.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
};

CreateAccountModal.defaultProps = {
  companies: [],
  onSubmit: () => {},
};
export default CreateAccountModal;
