import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SCButton from '../Buttons/SCButton';
import SCModal from './SCModal';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.Font.Headline.H4,
    textTransform: 'uppercase',
    margin: 0,
    textAlign: 'center',
    marginBottom: theme.Spacing.S,
    color: theme.Colors.neutral100,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral80,
    textAlign: 'center',
    margin: 0,
    marginBottom: theme.Spacing.S,
  },
  description: {
    ...theme.Font.Paragraph.Paragraph4,
    color: theme.Colors.neutral60,
    textAlign: 'center',
    marginBottom: theme.Spacing.S,
  },
}));

function SCModalAlert({ isOpen, handleClose, title, subtitle, description, buttonText, onClick }) {
  const classes = useStyles();

  return (
    <SCModal isOpen={isOpen} handleClose={handleClose} withCloseIcon={false}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>{subtitle}</div>
      <div className={classes.description}>{description}</div>
      <SCButton variant="contained" color="primary" onClick={onClick}>
        {buttonText}
      </SCButton>
    </SCModal>
  );
}

SCModalAlert.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

SCModalAlert.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  title: '',
  subtitle: '',
  description: '',
  buttonText: '',
  onClick: () => {},
};

export default SCModalAlert;
