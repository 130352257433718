import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { SURVEYSTATUS } from '../../../utils/constants';

const useStyles = makeStyles(() => ({
  statusCell: {
    background: ({ color }) => color,
    width: ({ width }) => width,
    paddingLeft: ({ dense }) => dense && '0',
    paddingRight: ({ dense }) => dense && '0',
  },
}));

const StatusCell = ({ key, align, statusIndicator, width, dense }) => {
  const theme = useTheme();
  let color = theme.Colors.background;

  switch (statusIndicator) {
    case SURVEYSTATUS.ARCHIVED:
      color = theme.Colors.background;
      break;
    case SURVEYSTATUS.DRAFT:
      color = theme.Colors.neutral40;
      break;
    case SURVEYSTATUS.PUBLISHED:
      color = theme.Colors.brandAccent;
      break;
    default:
      color = theme.Colors.background;
      break;
  }

  const classes = useStyles({ color, width, dense });
  return <TableCell key={key} align={align} classes={{ root: classes.statusCell }} />;
};

StatusCell.propTypes = {
  key: PropTypes.string,
  align: PropTypes.string,
  statusIndicator: PropTypes.string,
  width: PropTypes.string,
  dense: PropTypes.bool,
};

StatusCell.defaultProps = {
  key: '',
  align: 'center',
  statusIndicator: SURVEYSTATUS.DRAFT,
  width: '3px',
  dense: true,
};

export default StatusCell;
