import CellTypes from '../../../components/Table/Cells/CellTypes';
import { ROLE, STATUS } from '../../../utils/constants';
import translationKeys from '../../../utils/translations/translationKeys';

export default function(onSavePermission, onSaveStatus, t) {
  return [
    { id: 'icon', label: '', align: 'center', type: CellTypes.CELL_ICON, width: '40px' },
    {
      id: 'username',
      label: t(translationKeys.GENERAL_USERNAME),
      align: 'left',
      type: CellTypes.CELL_TEXT,
      highlight: true,
    },
    {
      id: 'email',
      label: t(translationKeys.GENERAL_EMAIL),
      align: 'center',
      type: CellTypes.CELL_TEXT,
    },
    {
      id: 'organization',
      label: t(translationKeys.GENERAL_ORGANIZATION),
      align: 'center',
      type: CellTypes.CELL_TEXT,
    },
    {
      id: 'permission',
      label: t(translationKeys.GENERAL_PERMISSION),
      align: 'center',
      options: Object.values(ROLE),
      type: CellTypes.CELL_SELECT,
      width: '192px',
      onChange: onSavePermission,
    },
    {
      id: 'status',
      label: t(translationKeys.GENERAL_STATUS),
      align: 'center',
      options: Object.values(STATUS),
      type: CellTypes.CELL_SELECT,
      width: '192px',
      onChange: onSaveStatus,
    },
  ];
}
