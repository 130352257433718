import SurveyService from '../services/SurveyService';
import { handleError, Handlers } from '../services/helpers/error-handling';

const surveyListModel = {
  name: 'surveyListModel',
  state: {
    surveyList: {
      page: 1,
      itemsPerPage: 10,
      list: [],
    },
  },
  reducers: {
    setSurveyList: (state, payload) => {
      return {
        ...state,
        surveyList: { page: 1, itemsPerPage: 10, list: payload },
      };
    },
  },
  effects: (dispatch) => ({
    async getSurveyList() {
      try {
        const response = await SurveyService.listSurveys();
        dispatch.surveyListModel.setSurveyList(response);

        return response;
      } catch (error) {
        return handleError(error, {
          'get-surveylist-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async duplicateSurvey({ uid, prefix }) {
      try {
        const surveyResponse = await SurveyService.getSurvey(uid);
        const response = await SurveyService.duplicate(
          uid,
          `${prefix} ${new Date().toLocaleString()} ${surveyResponse.name}`,
          surveyResponse.description,
          surveyResponse.type,
          surveyResponse.subtype,
        );
        dispatch.surveyListModel.getSurveyList();

        return response;
      } catch (error) {
        return handleError(error, {
          'duplicate-surveylist-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async deleteSurvey({ uid }) {
      try {
        const response = await SurveyService.remove(uid);
        dispatch.surveyListModel.getSurveyList();

        return response;
      } catch (error) {
        return handleError(error, {
          'delete-surveylist-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async archiveSurvey({ uid }) {
      try {
        const response = await SurveyService.edit(uid, { archivedDate: new Date().toISOString() });
        dispatch.surveyListModel.getSurveyList();

        return response;
      } catch (error) {
        return handleError(error, {
          'archive-surveylist-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
  }),
};

export default surveyListModel;
