import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import translationKeys from '../../../utils/translations/translationKeys';
import SCTextFieldDefault from '../../../components/Form/Fields/TextFields/SCTextFieldDefault';
import SCTextFieldPassword from '../../../components/Form/Fields/TextFields/SCTextFieldPassword';
import SCTextFieldPasswordConfirm from '../../../components/Form/Fields/TextFields/SCTextFieldPasswordConfirm';
import ErrorField from '../../../components/Form/Errors/ErrorField';
import SCButton from '../../../components/Buttons/SCButton';
import SCModalSuccess from '../../../components/Modal/SCModalSuccess';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.Spacing.M,
  },
  textHeader: {
    textAlign: 'left',
    margin: 0,
    textTransform: 'uppercase',
    ...theme.Font.Headline.H4,
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.L,
  },
  formContainer: {},
}));

function ProfileChangePasswordForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  async function onSubmit(values) {
    const response = await dispatch.userModel.updateMyPassword({
      currentPassword: values.currentPassword,
      password: values.password,
    });
    if (!response.message) {
      setIsModalOpen(true);
    }
    return response;
  }

  function handleClickSuccess() {
    setIsModalOpen(false);
    dispatch.userModel.logout();
  }

  return (
    <div className={classes.formContainer}>
      <SCModalSuccess
        isOpen={isModalOpen}
        title={t(translationKeys.PROFILE_PASSWORD_UPDATE_SUCCESS)}
        subtitle={t(translationKeys.PROFILE_PASSWORD_UPDATE_SUCCESS_SUBTITLE)}
        buttonText={t(translationKeys.GENERAL_GO_TO_LOGIN)}
        onClick={handleClickSuccess}
      />
      <h4 className={classes.textHeader}>{t(translationKeys.PROFILE_CHANGE_PASSWORD_TITLE)}</h4>
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          if (values.password === values.confirmPassword) {
            return {};
          }
          return { confirmPassword: t(translationKeys.GENERAL_CONFIRM_PASSWORD_INVALID) };
        }}
      >
        {({ handleSubmit, submitError, submitting, values, form }) => (
          <form
            onSubmit={async (data) => {
              const response = await handleSubmit(data);
              if (response && !response.message) {
                form.reset();
              }
              if (response && response.message) {
                form.initialize({});
              }
              return response;
            }}
          >
            <SCTextFieldDefault
              placeholder={t(translationKeys.PROFILE_CURRENT_PASSWORD)}
              required
              hide
              name="currentPassword"
              label={t(translationKeys.PROFILE_CURRENT_PASSWORD)}
            />
            <SCTextFieldPassword
              placeholder={t(translationKeys.PROFILE_NEW_PASSWORD)}
              helperText={t(translationKeys.CREATE_ACCOUNT_PASSWORD_INFO)}
            />
            <SCTextFieldPasswordConfirm
              placeholder={t(translationKeys.GENERAL_CONFIRM_PASSWORD)}
              password={values.password}
            />
            {submitError && <ErrorField>{submitError}</ErrorField>}
            <div className={classes.buttons}>
              <SCButton
                variant="contained"
                type="submit"
                color="primary"
                disabled={
                  submitting ||
                  !(values.currentPassword && values.password && values.confirmPassword)
                }
                loading={submitting}
              >
                {t(translationKeys.PROFILE_SAVE_PASSWORD)}
              </SCButton>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
}

export default ProfileChangePasswordForm;
