import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PublicMainComponent from '../PublicMainComponent/PublicMainComponent';
import translationKeys from '../../utils/translations/translationKeys';
import Paths from '../../router/router';
import SCButton from '../../components/Buttons/SCButton';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.Colors.backgroundColor,
    width: theme.Grid.Width.Medium,
  },
  innerContainer: {
    padding: `${theme.Spacing.XL} ${theme.Spacing.L}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    ...theme.Font.Headline.H4,
    textTransform: 'uppercase',
    color: theme.Colors.neutral100,
    margin: 0,
    marginBottom: theme.Spacing.XS,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral80,
    margin: 0,
    marginBottom: theme.Spacing.L,
  },
}));
function CreateAccountPageSuccess() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  function onClick() {
    history.replace(Paths.LOGIN);
  }

  return (
    <PublicMainComponent>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <h4 className={classes.title}>{t(translationKeys.GENERAL_DONE)}</h4>
          <p className={classes.subtitle}>{t(translationKeys.CREATE_ACCOUNT_CONFIRMATION)}</p>
          <SCButton variant="contained" color="primary" onClick={onClick}>
            {t(translationKeys.GENERAL_GO_TO_LOGIN)}
          </SCButton>
        </div>
      </div>
    </PublicMainComponent>
  );
}

export default CreateAccountPageSuccess;
