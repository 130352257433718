// Get the params from an URL and return them as an object
export default function useQueryWithURL(url) {
  try {
    const query = new URL(url).search.substring(1);

    if (!query || query === '') {
      return {};
    }
    const params = JSON.parse(
      `{"${query.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
      (key, value) => {
        return key === '' ? value : decodeURIComponent(value);
      },
    );

    return params;
  } catch (error) {
    return false;
  }
}
