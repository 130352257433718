import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateMainComponent from '../PrivateMainComponent/PrivateMainComponent';
import PrivateRoute from '../../router/PrivateRoute';
import DashboardPage from './DashboardSection/DashboardPage';
import SurveysPage from './SurveysSection/SurveysPage';
import UsersAndPermissionsPage from './UsersAndPermissionsSection/UsersAndPermissionPage';
import ProfilePage from './ProfileSection/ProfilePage';
import Paths from '../../router/router';

function LoginPage() {
  return (
    <PrivateMainComponent>
      <Switch>
        <PrivateRoute exact path={Paths.DASHBOARD} component={DashboardPage} />
        <PrivateRoute path={Paths.SURVEYS} component={SurveysPage} />
        <PrivateRoute exact path={Paths.USERS} component={UsersAndPermissionsPage} />
        <PrivateRoute exact path={Paths.PROFILE} component={ProfilePage} />
        <PrivateRoute exact path={Paths.ROOT} component={SurveysPage} />
        <Redirect to={Paths.NOT_FOUND} />
      </Switch>
    </PrivateMainComponent>
  );
}

export default LoginPage;
