/* eslint-disable import/prefer-default-export */

export const ErrorTypes = {
  FORM_ERROR: 'FORM_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
};

export const Handlers = {
  RETHROW: 'RETHROW',
  RETURN_DATA: 'RETURN_DATA',
  RETURN_ERROR: 'RETURN_ERROR',
};

export const passthroughNetworkErrors = (error, errorHandler) => {
  throw errorHandler(error);
};

export const handleError = (error, handlers) => {
  if (error && (error instanceof TypeError || error instanceof SyntaxError)) {
    /* eslint-disable-next-line no-console */
    console.error('You need to fix this error:', error);
    throw error;
  }

  if (error.type === ErrorTypes.NETWORK_ERROR) {
    switch (error.key) {
      case 'forbidden-error':
        // eslint-disable-next-line no-console
        console.error("You don't have permissions to access the selected resource", error);
        break;
      case 'not-found-error':
        // eslint-disable-next-line no-console
        console.error('warning', 'The selected resource no longer exists', error);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(
          'There is a connectivity problem, please verify your internet connection and try again...',
          error,
        );
        break;
    }
  }

  if (error && error.key && handlers) {
    if (handlers[error.key] || handlers.default) {
      const matchKey = handlers[error.key] ? error.key : 'default';
      // error handler by key found
      if (typeof handlers[matchKey] === 'function') {
        return handlers[matchKey](error);
      }
      if (typeof handlers[matchKey] === 'string') {
        /* eslint-disable no-console */
        switch (handlers[matchKey]) {
          case Handlers.RETHROW:
            throw error;
          case Handlers.RETURN_DATA:
            return error.data;
          case Handlers.RETURN_ERROR:
            return error;
          default:
            console.warn('Not recognized handler for error, please use one of:', Handlers);
            return error;
        }
      }
    }
    // eslint-disable-next-line no-console
    console.error('Unhandled not Network error:', error);
    return false;
  }
  // eslint-disable-next-line no-console
  console.error('Unhandled general error:', error);
  return error;
};

const createError = (errorType) => (key, data = {}, originalError = {}) => {
  const origError = originalError || data instanceof Error ? data : {};
  const finalMessage = origError && origError.message ? origError.message : errorType;
  const error = new Error(finalMessage, origError.filename, origError.lineNumber);
  error.key = key;
  error.data = data;
  error.type = errorType;
  error.originalError = originalError;
  const dataString = error.data ? JSON.stringify(error.data) : '';
  error.toString = () =>
    `Error key: ${error.key}\ntype: ${error.type}\ndata: ${dataString}\nstack:${error.stack}`;
  return error;
};

export const BusinessError = createError(ErrorTypes.BUSINESS_ERROR);
export const FormError = createError(ErrorTypes.FORM_ERROR);
export const NetworkError = createError(ErrorTypes.NETWORK_ERROR);
