import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paths from '../../router/router';
import translationKeys from '../../utils/translations/translationKeys';
import SCTextFieldPassword from '../../components/Form/Fields/TextFields/SCTextFieldPassword';
import SCTextFieldEmail from '../../components/Form/Fields/TextFields/SCTextFieldEmail';
import SCTextFieldDefault from '../../components/Form/Fields/TextFields/SCTextFieldDefault';
import SCTextFieldConfirmPassword from '../../components/Form/Fields/TextFields/SCTextFieldPasswordConfirm';
import SCCheckBoxFieldDefault from '../../components/Form/Fields/Inputs/SCCheckBoxFieldDefault';
import ErrorField from '../../components/Form/Errors/ErrorField';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.Spacing.M,
  },
  textHeader: {
    textAlign: 'center',
    margin: 0,
    textTransform: 'uppercase',
    ...theme.Font.Headline.H3,
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.L,
  },
  textSubtitle: {
    textAlign: 'center',
    margin: 0,
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral80,
    marginBottom: theme.Spacing.L,
  },
  formContainer: {
    position: 'relative',
    padding: theme.Spacing.L,
    ...theme.Elevations.medium,
  },
  aText: { color: theme.Colors.neutral60 },
}));

function CreateAccountForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userModel.userInfo);

  async function fetchUser() {
    if (!userProfile || !userProfile.email) {
      await dispatch.userModel.signInWithToken();
    }
  }
  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, [userProfile]);

  async function onSubmit(values) {
    if (values.termsAndConditions && values.acceptsHandleData) {
      const response = await dispatch.userModel.createMyAccount({
        name: values.name,
        surname: values.surname,
        password: values.password,
        emailVerified: true,
        acceptedConsents: true,
      });
      if (!response.message) {
        history.replace(Paths.CREATE_ACCOUNT_SUCCESS);
        return true;
      }
      return response;
    }
    return false;
  }

  return (
    <div className={classes.formContainer}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: userProfile.email || t(translationKeys.GENERAL_LOADING) }}
        validate={(values) => {
          if (values.password === values.confirmPassword) {
            return {};
          }
          return { confirmPassword: t(translationKeys.GENERAL_CONFIRM_PASSWORD_INVALID) };
        }}
      >
        {({ handleSubmit, submitError, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <h3 className={classes.textHeader}> {t(translationKeys.CREATE_ACCOUNT_TITLE)}</h3>
            <p className={classes.textSubtitle}>{t(translationKeys.CREATE_ACCOUNT_SUBTITLE)}</p>
            <SCTextFieldDefault
              name="name"
              label={t(translationKeys.GENERAL_FIRSTNAME)}
              placeholder={t(translationKeys.GENERAL_FIRSTNAME)}
              required
            />
            <SCTextFieldDefault
              name="surname"
              label={t(translationKeys.GENERAL_SURNAME)}
              placeholder={t(translationKeys.GENERAL_FIRSTNAME)}
              required
            />
            <SCTextFieldEmail
              placeholder={t(translationKeys.GENERAL_EMAIL)}
              helperText={t(translationKeys.CREATE_ACCOUNT_EMAIL_INFO)}
              disabled
            />
            <SCTextFieldPassword
              placeholder={t(translationKeys.GENERAL_PASSWORD)}
              helperText={t(translationKeys.CREATE_ACCOUNT_PASSWORD_INFO)}
            />
            <SCTextFieldConfirmPassword
              placeholder={t(translationKeys.GENERAL_CONFIRM_PASSWORD)}
              password={values.password}
            />
            <SCCheckBoxFieldDefault
              name="termsAndConditions"
              label={t(translationKeys.CREATE_ACCOUNT_TERMS_AND_CONDITIONS)}
              required
              helperText={
                <>
                  {t(translationKeys.CREATE_ACCOUNT_TERMS_AND_CONDITIONS_INFO)}
                  <a className={classes.aText} href={t(translationKeys.LOGIN_OUR_URL)}>
                    {t(translationKeys.CREATE_ACCOUNT_TERMS_AND_CONDITIONS_LINK_TEXT)}
                  </a>
                </>
              }
            />
            <SCCheckBoxFieldDefault
              name="acceptsHandleData"
              label={t(translationKeys.CREATE_ACCOUNT_ACCEPT_HANDLE_DATA)}
              required
            />
            {submitError && <ErrorField>{submitError}</ErrorField>}
            <div className={classes.buttons}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={submitting || pristine}
              >
                {t(translationKeys.GENERAL_REGISTER)}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
}

export default CreateAccountForm;
