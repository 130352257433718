import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles((theme) => ({
  columnStyle: {
    minWidth: ({ minWidth }) => minWidth,
    width: ({ width }) => width,
    paddingLeft: ({ dense }) => dense && '0',
    paddingRight: ({ dense }) => dense && '0',
  },
  textStyle: {
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral60,
  },
  textHighlighStyle: {
    ...theme.Font.Paragraph.Paragraph1,
    color: theme.Colors.neutral80,
  },
}));
const HeaderCell = ({ key, align, children, highlight, minWidth, width, dense }) => {
  const classes = useStyles({
    minWidth,
    width,
    dense,
  });
  return (
    <TableCell
      key={key}
      align={align}
      className={classnames(classes.columnStyle, classes.textStyle, {
        [classes.textHighlighStyle]: highlight,
      })}
    >
      {children}
    </TableCell>
  );
};

HeaderCell.propTypes = {
  key: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.string,
  highlight: PropTypes.bool,
  minWidth: PropTypes.string,
  width: PropTypes.string,
  dense: PropTypes.bool,
};

HeaderCell.defaultProps = {
  key: '',
  align: 'center',
  children: '',
  highlight: false,
  minWidth: undefined,
  width: undefined,
  dense: false,
};

export default HeaderCell;
