import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PublicMainComponent from '../PublicMainComponent/PublicMainComponent';
import ResetPasswordForm from './ResetPasswordForm';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.Colors.backgroundColor,
    width: theme.Grid.Width.Medium,
    margin: 'auto',
  },
}));
function ResetPasswordPage() {
  const classes = useStyles();

  return (
    <PublicMainComponent>
      <div className={classes.container}>
        <ResetPasswordForm />
      </div>
    </PublicMainComponent>
  );
}

export default ResetPasswordPage;
