import SurveyService from '../services/SurveyService';
import { handleError, Handlers } from '../services/helpers/error-handling';

const surveyModel = {
  name: 'surveyModel',
  state: {
    survey: {},
    localQuestions: [],
  },
  reducers: {
    setSurvey: (state, payload) => {
      return {
        ...state,
        survey: payload,
        localQuestions: payload.questions,
      };
    },
    setLocalQuestions: (state, payload) => {
      return {
        ...state,
        localQuestions: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getSurvey(payload) {
      try {
        const { uid } = payload;
        const response = await SurveyService.getSurvey(uid);
        dispatch.surveyModel.setSurvey({ ...response, uid });

        return response;
      } catch (error) {
        return handleError(error, {
          'get-survey-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async cleanSurvey() {
      dispatch.surveyModel.setSurvey({ questions: [] });
    },
    async createChapter(payload) {
      try {
        const response = await SurveyService.createQuestion(payload);

        return response;
      } catch (error) {
        return handleError(error, {
          'create-chapter-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async createQuestion(payload) {
      try {
        const response = await SurveyService.createQuestion(payload);

        return response;
      } catch (error) {
        return handleError(error, {
          'create-question-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async editQuestion({ uuid, ...rest }) {
      try {
        const response = await SurveyService.editQuestion(uuid, rest);
        return response;
      } catch (error) {
        return handleError(error, {
          'edit-question-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async deleteQuestion({ uid }) {
      try {
        const response = await SurveyService.deleteQuestion(uid);

        return response;
      } catch (error) {
        return handleError(error, {
          'delete-question-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async saveAsDraftSurvey(payload) {
      const {
        uid,
        name,
        description,
        type,
        subtype,
        area,
        partners,
        date,
        significant,
        forum,
      } = payload;

      try {
        await SurveyService.edit(uid, {
          name,
          description,
          type,
          subtype,
          cities: area ? [area] : undefined,
          partners: partners ? [partners] : undefined,
          activityDate: date,
          significant,
          forum,
        });
        const response = await SurveyService.getSurvey(uid);
        dispatch.surveyModel.setSurvey({ ...response, uid });
        return response;
      } catch (error) {
        return handleError(error, {
          'saveasDraft-surveylist-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async publishSurvey(payload) {
      const {
        uid,
        name,
        description,
        type,
        subtype,
        area,
        partners,
        date,
        significant,
        forum,
      } = payload;

      try {
        await SurveyService.edit(uid, {
          name,
          description,
          type,
          subtype,
          cities: area ? [area] : undefined,
          partners: partners ? [partners] : undefined,
          activityDate: date,
          significant,
          forum,
          publishedDate: new Date().toISOString(),
        });
        const response = await SurveyService.getSurvey(uid);
        dispatch.surveyModel.setSurvey({ ...response, uid });
        return response;
      } catch (error) {
        return handleError(error, {
          'publish-surveylist-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async editQuestions({ uid, questions }) {
      try {
        await SurveyService.edit(uid, { questions });
        const response = await SurveyService.getSurvey(uid);
        dispatch.surveyModel.setSurvey({ ...response, uid });
        return response;
      } catch (error) {
        return handleError(error, {
          'list-cities-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
  }),
};

export default surveyModel;
