const Paths = {
  ROOT: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot',
  CREATE_ACCOUNT: '/create',
  CREATE_ACCOUNT_SUCCESS: '/create/success',
  RESET_PASSWORD: '/reset',
  RESET_PASSWORD_SUCCESS: '/reset/success',
  DASHBOARD: '/olddashboard',
  SURVEYS: '/surveys',
  EDIT_SURVEYS: '/surveys/:idSurvey',
  USERS: '/users',
  PROFILE: '/profile',
  ACTION: '/action',
  NOT_FOUND: '/404',
};

export default Paths;
