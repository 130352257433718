import JwtDecode from 'jwt-decode';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import { handleError, Handlers } from '../services/helpers/error-handling';
import { setSCToken, removeSCToken } from '../services/SCAxios';

const TOKEN_KEY = '@sc-auth-service/tokens';
const USER_KEY = '@sc-user-service/userInfo';

const user = {
  name: 'userModel',
  state: {
    isAuthenticated: AuthService.isAuthenticated(),
    userInfo: {},
  },
  reducers: {
    setAuthenticated: (state, payload) => {
      return {
        ...state,
        isAuthenticated: payload,
      };
    },
    setUserInfo: (state, payload) => {
      return {
        ...state,
        userInfo: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async login(payload) {
      const { email, password } = payload;

      try {
        const response = await AuthService.login(email, password);

        const { uid: uidUser, exp } = JwtDecode(response.accessToken);
        setSCToken(response.accessToken);
        localStorage.setItem(
          TOKEN_KEY,
          JSON.stringify({
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
            timestamp: exp * 1000,
          }),
        );
        const userInfoResponse = await UserService.getInfo(uidUser);
        const newUserInfo = {
          ...userInfoResponse,
        };
        dispatch.userModel.setUserInfo(newUserInfo);
        localStorage.setItem(USER_KEY, JSON.stringify(newUserInfo));
        dispatch.userModel.setAuthenticated(true);

        return response;
      } catch (error) {
        return handleError(error, {
          'login-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async signInWithToken() {
      try {
        const tokens = JSON.parse(localStorage.getItem(TOKEN_KEY));
        if (tokens.accessToken) {
          const response = await AuthService.signin(tokens.accessToken);
          const { uid: uidUser, exp } = JwtDecode(response.accessToken);
          setSCToken(response.accessToken);
          localStorage.setItem(
            TOKEN_KEY,
            JSON.stringify({
              accessToken: response.accessToken,
              refreshToken: response.refreshToken,
              timestamp: exp * 1000,
            }),
          );
          const userInfoResponse = await UserService.getInfo(uidUser);
          const newUserInfo = {
            ...userInfoResponse,
          };
          dispatch.userModel.setUserInfo(newUserInfo);
          localStorage.setItem(USER_KEY, JSON.stringify(newUserInfo));
          dispatch.userModel.setAuthenticated(true);
          return response;
        }
        await dispatch.userModel.logout();
        return false;
      } catch (error) {
        return handleError(error, {
          'sign-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async logout() {
      try {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(USER_KEY);
        dispatch.userModel.setAuthenticated(false);
        const response = await AuthService.logout();
        removeSCToken();
        return response;
      } catch (error) {
        return handleError(error, {
          'login-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async refresh() {
      try {
        const tokens = JSON.parse(localStorage.getItem(TOKEN_KEY));
        if (tokens.refreshToken && tokens.timestamp + 3600000 * 72 > new Date().getTime()) {
          const response = await AuthService.refresh(tokens.refreshToken);
          const { uid: uidUser, exp } = JwtDecode(response.accessToken);
          setSCToken(response.accessToken);
          localStorage.setItem(
            TOKEN_KEY,
            JSON.stringify({
              accessToken: response.accessToken,
              refreshToken: response.refreshToken,
              timestamp: exp * 1000,
            }),
          );
          const userInfoResponse = await UserService.getInfo(uidUser);
          const newUserInfo = {
            ...userInfoResponse,
          };
          dispatch.userModel.setUserInfo(newUserInfo);
          localStorage.setItem(USER_KEY, JSON.stringify(newUserInfo));
          dispatch.userModel.setAuthenticated(true);
          return response;
        }
        await dispatch.userModel.logout();
        return false;
      } catch (error) {
        return handleError(error, {
          'login-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async forgot(payload) {
      const { email } = payload;
      try {
        return await AuthService.forgot(email);
      } catch (error) {
        return handleError(error, {
          'forgot-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async signinWithEmail(payload) {
      const { email, link } = payload;

      try {
        const response = await AuthService.signinWithEmail(email, link);

        const { uid: uidUser, exp } = JwtDecode(response.accessToken);
        setSCToken(response.accessToken);
        localStorage.setItem(
          TOKEN_KEY,
          JSON.stringify({
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
            timestamp: exp * 1000,
          }),
        );
        const userInfoResponse = await UserService.getInfo(uidUser);
        const newUserInfo = {
          ...userInfoResponse,
        };
        dispatch.userModel.setUserInfo(newUserInfo);
        localStorage.setItem(USER_KEY, JSON.stringify(newUserInfo));
        dispatch.userModel.setAuthenticated(true);

        return response;
      } catch (error) {
        return handleError(error, {
          'login-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async recoverMyInfo() {
      const tokens = JSON.parse(localStorage.getItem(TOKEN_KEY));
      let response = {};
      if (tokens.accessToken && tokens.refreshToken && tokens.timestamp) {
        if (tokens.timestamp + 3600000 > new Date().getTime()) {
          response = await AuthService.signin(tokens.accessToken);
        } else if (tokens.timestamp + 3600000 * 72 > new Date().getTime()) {
          response = await AuthService.refresh(tokens.refreshToken);
        } else {
          await AuthService.logout();
          return false;
        }
      } else {
        return false;
      }
      setSCToken(response.accessToken);

      const { uid: uidUser, exp } = JwtDecode(response.accessToken);
      localStorage.setItem(
        TOKEN_KEY,
        JSON.stringify({
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
          timestamp: exp * 1000,
        }),
      );
      const userInfoResponse = await UserService.getInfo(uidUser);
      const newUserInfo = {
        ...userInfoResponse,
      };
      dispatch.userModel.setUserInfo(newUserInfo);
      localStorage.setItem(USER_KEY, JSON.stringify(newUserInfo));
      dispatch.userModel.setAuthenticated(true);
      return true;
    },
    async getMyInfo(payload, rootState) {
      try {
        const response = await UserService.getInfo(rootState.userModel.userInfo.uid);
        const newUserInfo = {
          ...response,
        };
        dispatch.userModel.setUserInfo(newUserInfo);
        return response;
      } catch (error) {
        return handleError(error, {
          'myinfo-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async updateMyData(payload, rootState) {
      try {
        await UserService.updateUser(rootState.userModel.userInfo.uid, payload);
        const response = await UserService.getInfo(rootState.userModel.userInfo.uid);
        const newUserInfo = {
          ...response,
        };
        dispatch.userModel.setUserInfo(newUserInfo);
        return response;
      } catch (error) {
        return handleError(error, {
          'update-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async updateMyPassword(payload, rootState) {
      try {
        const { currentPassword, password } = payload;
        const loginResponse = await AuthService.checkCurrentPassword(
          rootState.userModel.userInfo.email,
          currentPassword,
        );
        if (!loginResponse.message) {
          await UserService.updateUser(rootState.userModel.userInfo.uid, { password });
          await dispatch.userModel.login({ email: rootState.userModel.userInfo.email, password });
          const response = await UserService.getInfo(rootState.userModel.userInfo.uid);
          const newUserInfo = {
            ...response,
          };
          dispatch.userModel.setUserInfo(newUserInfo);
          return response;
        }
        return false;
      } catch (error) {
        return handleError(error, {
          'update-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async createMyAccount(payload, rootState) {
      try {
        await UserService.updateUser(rootState.userModel.userInfo.uid, payload);
        const response = await UserService.getInfo(rootState.userModel.userInfo.uid);
        const newUserInfo = {
          ...response,
        };
        dispatch.userModel.setUserInfo(newUserInfo);
        return response;
      } catch (error) {
        return handleError(error, {
          'update-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
    async resetMyPassword(payload, rootState) {
      try {
        const { password } = payload;
        await UserService.updateUser(rootState.userModel.userInfo.uid, { password });
        await dispatch.userModel.login({ email: rootState.userModel.userInfo.email, password });
        const response = await UserService.getInfo(rootState.userModel.userInfo.uid);
        const newUserInfo = {
          ...response,
        };
        dispatch.userModel.setUserInfo(newUserInfo);
        return response;
      } catch (error) {
        return handleError(error, {
          'update-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
  }),
};

export default user;
