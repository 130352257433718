import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import translationKeys from '../../utils/translations/translationKeys';
import useQuery from '../../utils/hooks/useQuery';
import useQueryWithURL from '../../utils/hooks/useQueryWithURL';
import Paths from '../../router/router';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    ...theme.Font.Headline.H2,
    textTransform: 'uppercase',
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.M,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph2,
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.M,
  },
}));

function ActionPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const params = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const continueUrl = useQueryWithURL(params.continueUrl);
  const innerConntinueUrl = useQueryWithURL(continueUrl.continueUrl);

  async function signInAndRedirect(link, email) {
    const response = await dispatch.userModel.signinWithEmail({
      link,
      email,
    });
    if (!response.message) {
      switch (params.mode) {
        case 'signIn':
          history.replace(Paths.CREATE_ACCOUNT);
          break;
        case 'resetPassword':
          history.replace(Paths.RESET_PASSWORD);
          break;
        default:
          setLoading(false);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    let url = '';
    switch (params.mode) {
      case 'signIn':
        url = window.location.href;
        break;
      case 'resetPassword':
        url = params.continueUrl;
        break;
      default:
    }
    if (innerConntinueUrl === false) {
      setLoading(false);
    }
    if (url !== '') {
      signInAndRedirect(url, innerConntinueUrl.email);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      {loading ? (
        <>
          <h2 className={classes.title}>{t(translationKeys.GENERAL_REDIRECTING)}</h2>
          <CircularProgress />
        </>
      ) : (
        <>
          <h2 className={classes.title}>{t(translationKeys.ERROR_SOMETHING_WENT_WRONG)}</h2>
          <p className={classes.subtitle}>{t(translationKeys.ERROR_RELOAD_OR_CALL_ADMIN)}</p>
        </>
      )}
    </div>
  );
}

export default ActionPage;
