import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    marginBottom: theme.Spacing.S,
  },
  textLabel: {
    ...theme.Font.Paragraph.Paragraph2,
    color: theme.Colors.neutral80,
  },
  textInfoStyle: {
    margin: 0,
    ...theme.Font.Paragraph.Paragraph4,
    color: theme.Colors.neutral60,
  },
}));

const SCCheckBoxField = ({
  label,
  disabled,
  className,
  error,
  showError,
  onChange,
  onBlur,
  onFocus,
  helperText,
  value,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(value);
  function handleChange(event) {
    setChecked(event.target.checked);
    onChange(event);
  }
  return (
    <div className={classnames(classes.checkboxContainer, className)}>
      <FormControlLabel
        value="end"
        control={
          <Checkbox
            color="primary"
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            checked={checked}
          />
        }
        label={label}
        labelPlacement="end"
        classes={{ root: classes.textLabel }}
      />
      {!showError && helperText && (
        <FormHelperText classes={{ root: classes.textInfoStyle }}>{helperText}</FormHelperText>
      )}
      {showError && (
        <FormHelperText classes={{ root: classes.textInfoStyle }} error={showError}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

SCCheckBoxField.propTypes = {
  label: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  showError: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.bool,
};

SCCheckBoxField.defaultProps = {
  label: '',
  disabled: false,
  className: '',
  error: undefined,
  showError: false,
  helperText: undefined,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  value: false,
};

export default SCCheckBoxField;
