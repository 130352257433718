import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.Colors.background,
    width: ({ width }) => width || '100%',
  },
}));

function SCCard({ key, width, children, className }) {
  const classes = useStyles({ width });
  return (
    <Paper key={key} classes={{ root: classnames(classes.card, className) }}>
      {children}
    </Paper>
  );
}

SCCard.propTypes = {
  key: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  width: PropTypes.string,
  className: PropTypes.string,
};

SCCard.defaultProps = {
  key: '',
  children: {},
  width: '100%',
  className: '',
};

export default SCCard;
