const translateKeys = {
  GENERAL_TITLE: 'GENERAL_TITLE',
  GENERAL_SUBTITLE: 'GENERAL_SUBTITLE',
  GENERAL_SUBMIT: 'GENERAL_SUBMIT',
  GENERAL_USERNAME: 'GENERAL_USERNAME',
  GENERAL_EMAIL: 'GENERAL_EMAIL',
  GENERAL_PASSWORD: 'GENERAL_PASSWORD',
  GENERAL_PASSWORD_INVALID: 'GENERAL_PASSWORD_INVALID',
  GENERAL_CONFIRM_PASSWORD: 'GENERAL_CONFIRM_PASSWORD',
  GENERAL_CONFIRM_PASSWORD_INVALID: 'GENERAL_CONFIRM_PASSWORD_INVALID',
  GENERAL_LOGIN: 'GENERAL_LOGIN',
  GENERAL_LOGOUT: 'GENERAL_LOGOUT',
  GENERAL_REQUIRED: 'GENERAL_REQUIRED',
  GENERAL_EMAIL_INVALID: 'GENERAL_EMAIL_INVALID',
  GENERAL_SING_IN: 'GENERAL_SING_IN',
  GENERAL_SEND: 'GENERAL_SEND',
  GENERAL_REGISTER: 'GENERAL_REGISTER',
  GENERAL_FIRSTNAME: 'GENERAL_FIRSTNAME',
  GENERAL_SURNAME: 'GENERAL_SURNAME',
  GENERAL_ORGANIZATION: 'GENERAL_ORGANIZATION',
  GENERAL_ROLE: 'GENERAL_ROLE',
  GENERAL_DONE: 'GENERAL_DONE',
  GENERAL_GO_TO_LOGIN: 'GENERAL_GO_TO_LOGIN',
  GENERAL_GO_TO_MAINPAGE: 'GENERAL_GO_TO_MAINPAGE',
  GENERAL_DASHBOARD: 'GENERAL_DASHBOARD',
  GENERAL_SURVEYS: 'GENERAL_SURVEYS',
  GENERAL_USERSANDPERMISSIONS: 'GENERAL_USERSANDPERMISSIONS',
  GENERAL_PROFILE: 'GENERAL_PROFILE',
  GENERAL_SETTINGS: 'GENERAL_SETTINGS',
  GENERAL_USERS: 'GENERAL_USERS',
  GENERAL_PERMISSION: 'GENERAL_PERMISSION',
  GENERAL_DATE: 'GENERAL_DATE',
  GENERAL_STATUS: 'GENERAL_STATUS',
  GENERAL_SURVEY_NAME: 'GENERAL_SURVEY_NAME',
  GENERAL_AREA: 'GENERAL_AREA',
  GENERAL_FORUM: 'GENERAL_FORUM',
  GENERAL_CREATEDBY: 'GENERAL_CREATEDBY',
  GENERAL_SURVEYCODE: 'GENERAL_SURVEYCODE',
  GENERAL_TABLE_ROWS_PER_PAGE: 'GENERAL_TABLE_ROWS_PER_PAGE',
  GENERAL_REDIRECTING: 'GENERAL_REDIRECTING',
  GENERAL_DRAFT: 'GENERAL_DRAFT',
  GENERAL_ARCHIVE: 'GENERAL_ARCHIVE',
  GENERAL_PUBLISH: 'GENERAL_PUBLISH',
  GENERAL_DUPLICATE: 'GENERAL_DUPLICATE',
  GENERAL_COPY: 'GENERAL_COPY',
  GENERAL_EDIT: 'GENERAL_EDIT',
  GENERAL_DELETE: 'GENERAL_DELETE',
  GENERAL_ME: 'GENERAL_ME',
  GENERAL_EXIT: 'GENERAL_EXIT',
  GENERAL_CANCEL: 'GENERAL_CANCEL',
  GENERAL_SAVE: 'GENERAL_SAVE',
  GENERAL_TITLE_TEXT: 'GENERAL_TITLE_TEXT',
  GENERAL_SUBTITLE_TEXT: 'GENERAL_SUBTITLE_TEXT',
  GENERAL_COPY_PREFIX: 'GENERAL_COPY_PREFIX',
  GENERAL_ACCEPT: 'GENERAL_ACCEPT',
  GENERAL_COLOR: 'GENERAL_COLOR',
  GENERAL_LOADING: 'GENERAL_LOADING',
  GENERAL_MIN_LENGTH_REQUIRED: 'GENERAL_MIN_LENGTH_REQUIRED',
  GENERAL_MAX_LENGTH_REQUIRED: 'GENERAL_MAX_LENGTH_REQUIRED',
  GENERAL_NO_INDICATOR: 'GENERAL_NO_INDICATOR',
  ERROR_NOT_FOUND_TITLE: 'ERROR_NOT_FOUND_TITLE',
  ERROR_NOT_FOUND_SUBTITLE: 'ERROR_NOT_FOUND_SUBTITLE',
  ERROR_NOT_FOUND_GO_BACK_MAIN: 'ERROR_NOT_FOUND_GO_BACK_MAIN',
  ERROR_SOMETHING_WENT_WRONG: 'ERROR_SOMETHING_WENT_WRONG',
  ERROR_RELOAD_OR_CALL_ADMIN: 'ERROR_RELOAD_OR_CALL_ADMIN',
  LOGIN_TEXT: 'LOGIN_TEXT',
  LOGIN_SUBTEXT: 'LOGIN_SUBTEXT',
  LOGIN_OUR_URL_TEXT: 'LOGIN_OUR_URL_TEXT',
  LOGIN_OUR_URL: 'LOGIN_OUR_URL',
  LOGIN_FOOTER_TEXT: 'LOGIN_FOOTER_TEXT',
  LOGIN_HEADER_TEXT: 'LOGIN_HEADER_TEXT',
  LOGIN_PASSWORD_TEXT: 'LOGIN_PASSWORD_TEXT',
  LOGIN_FORGOT_PASSWORD: 'LOGIN_FORGOT_PASSWORD',
  LOGIN_FORGOT_PASSWORD_TITLE: 'LOGIN_FORGOT_PASSWORD_TITLE',
  LOGIN_FORGOT_PASSWORD_INFO: 'LOGIN_FORGOT_PASSWORD_INFO',
  LOGIN_FORGOT_PASSWORD_CONFIRMATION: 'LOGIN_FORGOT_PASSWORD_CONFIRMATION',
  CREATE_ACCOUNT_TITLE: 'CREATE_ACCOUNT_TITLE',
  CREATE_ACCOUNT_SUBTITLE: 'CREATE_ACCOUNT_SUBTITLE',
  CREATE_ACCOUNT_PASSWORD_INFO: 'CREATE_ACCOUNT_PASSWORD_INFO',
  CREATE_ACCOUNT_CONFIRMATION: 'CREATE_ACCOUNT_CONFIRMATION',
  CREATE_ACCOUNT_TERMS_AND_CONDITIONS: 'CREATE_ACCOUNT_TERMS_AND_CONDITIONS',
  CREATE_ACCOUNT_TERMS_AND_CONDITIONS_INFO: 'CREATE_ACCOUNT_TERMS_AND_CONDITIONS_INFO',
  CREATE_ACCOUNT_TERMS_AND_CONDITIONS_LINK: 'CREATE_ACCOUNT_TERMS_AND_CONDITIONS_LINK',
  CREATE_ACCOUNT_TERMS_AND_CONDITIONS_LINK_TEXT: 'CREATE_ACCOUNT_TERMS_AND_CONDITIONS_LINK_TEXT',
  CREATE_ACCOUNT_ACCEPT_HANDLE_DATA: 'CREATE_ACCOUNT_ACCEPT_HANDLE_DATA',
  CREATE_ACCOUNT_EMAIL_INFO: 'CREATE_ACCOUNT_EMAIL_INFO',
  RESET_PASSWORD_TITLE: 'RESET_PASSWORD_TITLE',
  RESET_PASSWORD_SUBTITLE: 'RESET_PASSWORD_SUBTITLE',
  RESET_PASSWORD_CONFIRMATION: 'RESET_PASSWORD_CONFIRMATION',
  RESET_PASSWORD_SAVE_PASSWORD: 'RESET_PASSWORD_SAVE_PASSWORD',
  PROFILE_TITLE: 'PROFILE_TITLE',
  PROFILE_SUBTITLE: 'PROFILE_SUBTITLE',
  PROFILE_CHANGE_PASSWORD_TITLE: 'PROFILE_CHANGE_PASSWORD_TITLE',
  PROFILE_CURRENT_PASSWORD: 'PROFILE_CURRENT_PASSWORD',
  PROFILE_NEW_PASSWORD: 'PROFILE_NEW_PASSWORD',
  PROFILE_SAVE_PASSWORD: 'PROFILE_SAVE_PASSWORD',
  PROFILE_DETAILS_TITLE: 'PROFILE_DETAILS_TITLE',
  PROFILE_SAVE_DETAILS: 'PROFILE_SAVE_DETAILS',
  PROFILE_PASSWORD_UPDATE_SUCCESS: 'PROFILE_PASSWORD_UPDATE_SUCCESS',
  PROFILE_PASSWORD_UPDATE_SUCCESS_SUBTITLE: 'PROFILE_PASSWORD_UPDATE_SUCCESS_SUBTITLE',
  PROFILE_DETAILS_UPDATE_SUCCESS: 'PROFILE_DETAILS_UPDATE_SUCCESS',
  PROFILE_DETAILS_UPDATE_SUCCESS_SUBTITLE: 'PROFILE_DETAILS_UPDATE_SUCCESS_SUBTITLE',
  SURVEYS_TITLE: 'SURVEYS_TITLE',
  SURVEYS_SUBTITLE: 'SURVEYS_SUBTITLE',
  SURVEYS_LIST_DELETE_TITLE: 'SURVEYS_LIST_DELETE_TITLE',
  SURVEYS_LIST_DELETE_SUBTITLE: 'SURVEYS_LIST_DELETE_SUBTITLE',
  SURVEYS_LIST_DUPLICATE_TITLE: 'SURVEYS_LIST_DUPLICATE_TITLE',
  SURVEYS_LIST_DUPLICATE_SUBTITLE: 'SURVEYS_LIST_DUPLICATE_SUBTITLE',
  SURVEYS_LIST_ARCHIVE_TITLE: 'SURVEYS_LIST_ARCHIVE_TITLE',
  SURVEYS_LIST_ARCHIVE_SUBTITLE: 'SURVEYS_LIST_ARCHIVE_SUBTITLE',
  SURVEYS_LIST_NO_DATA: 'SURVEYS_LIST_NO_DATA',
  SURVEYS_LIST_ERROR_TITLE: 'SURVEYS_LIST_ERROR_TITLE',
  SURVEYS_LIST_ERROR_SUBTITLE: 'SURVEYS_LIST_ERROR_SUBTITLE',
  SURVEYS_LIST_DUPLICATE_MODAL_TITLE: 'SURVEYS_LIST_DUPLICATE_MODAL_TITLE',
  SURVEYS_LIST_DUPLICATE_MODAL_SUBTITLE: 'SURVEYS_LIST_DUPLICATE_MODAL_SUBTITLE',
  SURVEYS_LIST_DUPLICATE_MODAL_EDIT: 'SURVEYS_LIST_DUPLICATE_MODAL_EDIT',
  SURVEYS_OVERVIEW_SAVE_AS_DRAFT: 'SURVEYS_OVERVIEW_SAVE_AS_DRAFT',
  SURVEYS_OVERVIEW_DETAILS: 'SURVEYS_OVERVIEW_DETAILS',
  SURVEYS_OVERVIEW_SURVEY_NAME: 'SURVEYS_OVERVIEW_SURVEY_NAME',
  SURVEYS_OVERVIEW_SURVEY_NAME_HELPER_TEXT: 'SURVEYS_OVERVIEW_SURVEY_NAME_HELPER_TEXT',
  SURVEYS_OVERVIEW_SURVEY_DESCRIPTION: 'SURVEYS_OVERVIEW_SURVEY_DESCRIPTION',
  SURVEYS_OVERVIEW_SURVEY_DESCRIPTION_HELPER_TEXT:
    'SURVEYS_OVERVIEW_SURVEY_DESCRIPTION_HELPER_TEXT',
  SURVEYS_OVERVIEW_SURVEY_DATE: 'SURVEYS_OVERVIEW_SURVEY_DATE',
  SURVEYS_OVERVIEW_SURVEY_AREA: 'SURVEYS_OVERVIEW_SURVEY_AREA',
  SURVEYS_OVERVIEW_SURVEY_FORUM: 'SURVEYS_OVERVIEW_SURVEY_FORUM',
  SURVEYS_OVERVIEW_LIFE_JOURNEY: 'SURVEYS_OVERVIEW_LIFE_JOURNEY',
  SURVEYS_OVERVIEW_ACTIVITY_JOURNEY: 'SURVEYS_OVERVIEW_ACTIVITY_JOURNEY',
  SURVEYS_OVERVIEW_CARD_ENTRY_COMPLETION: 'SURVEYS_OVERVIEW_CARD_ENTRY_COMPLETION',
  SURVEYS_OVERVIEW_CARD_ENTRY_COMPLETION_THANKYOU:
    'SURVEYS_OVERVIEW_CARD_ENTRY_COMPLETION_THANKYOU',
  SURVEYS_OVERVIEW_CARD_QUESTION_NUMBER: 'SURVEYS_OVERVIEW_CARD_QUESTION_NUMBER',
  SURVEYS_OVERVIEW_ADD_CARD: 'SURVEYS_OVERVIEW_ADD_CARD',
  SURVEYS_OVERVIEW_ADD_QUESTION: 'SURVEYS_OVERVIEW_ADD_QUESTION',
  SURVEYS_OVERVIEW_SURVEY_TYPE: 'SURVEYS_OVERVIEW_SURVEY_TYPE',
  SURVEYS_OVERVIEW_SURVEY_SUBTYPE: 'SURVEYS_OVERVIEW_SURVEY_SUBTYPE',
  SURVEYS_OVERVIEW_SURVEY_SUBTYPE_WORKSHOP: 'SURVEYS_OVERVIEW_SURVEY_SUBTYPE_WORKSHOP',
  SURVEYS_OVERVIEW_SURVEY_SUBTYPE_LECTURE: 'SURVEYS_OVERVIEW_SURVEY_SUBTYPE_LECTURE',
  SURVEYS_OVERVIEW_SURVEY_SUBTYPE_TRYON_ACTIVITY: 'SURVEYS_OVERVIEW_SURVEY_SUBTYPE_TRYON_ACTIVITY',
  SURVEYS_OVERVIEW_SURVEY_SUBTYPE_TEAM_BUILDING: 'SURVEYS_OVERVIEW_SURVEY_SUBTYPE_TEAM_BUILDING',
  SURVEYS_OVERVIEW_SURVEY_SUBTYPE_STUDY_VISITS: 'SURVEYS_OVERVIEW_SURVEY_SUBTYPE_STUDY_VISITS',
  SURVEYS_OVERVIEW_SURVEY_SUBTYPE_PROJECT_WORK: 'SURVEYS_OVERVIEW_SURVEY_SUBTYPE_PROJECT_WORK',
  SURVEYS_OVERVIEW_SURVEY_SUBTYPE_PARTY: 'SURVEYS_OVERVIEW_SURVEY_SUBTYPE_PARTY',
  SURVEYS_OVERVIEW_SURVEY_SUBTYPE_SOCIAL_ACTIVITY:
    'SURVEYS_OVERVIEW_SURVEY_SUBTYPE_SOCIAL_ACTIVITY',
  SURVEYS_OVERVIEW_SURVEY_SUBTYPE_OTHER: 'SURVEYS_OVERVIEW_SURVEY_SUBTYPE_OTHER',
  SURVEYS_OVERVIEW_SURVEY_PARTNERS: 'SURVEYS_OVERVIEW_SURVEY_PARTNERS',
  SURVEYS_OVERVIEW_SURVEY_PARTNERS_HELPERTEXT: 'SURVEYS_OVERVIEW_SURVEY_PARTNERS_HELPERTEXT',
  SURVEYS_OVERVIEW_SURVEY_BADGE: 'SURVEYS_OVERVIEW_SURVEY_BADGE',
  SURVEYS_OVERVIEW_SURVEY_BADGE_HELPERTEXT: 'SURVEYS_OVERVIEW_SURVEY_BADGE_HELPERTEXT',
  SURVEYS_OVERVIEW_SURVEY_BODY_TEXT: 'SURVEYS_OVERVIEW_SURVEY_BODY_TEXT',
  SURVEYS_OVERVIEW_SURVEY_PUBLISH_SUBTITLE: 'SURVEYS_OVERVIEW_SURVEY_PUBLISH_SUBTITLE',
  SURVEYS_OVERVIEW_SURVEY_PUBLISH_TITLE: 'SURVEYS_OVERVIEW_SURVEY_PUBLISH_TITLE',
  SURVEY_QUESTION_OVERVIEW_FORUM_HEALTHY_GENERATION:
    'SURVEY_QUESTION_OVERVIEW_FORUM_HEALTHY_GENERATION',
  SURVEY_QUESTION_OVERVIEW_FORUM_FOLK_HIGH_SCHOOL:
    'SURVEY_QUESTION_OVERVIEW_FORUM_FOLK_HIGH_SCHOOL',
  SURVEY_QUESTION_OVERVIEW_FORUM_YOUTH_CENTER: 'SURVEY_QUESTION_OVERVIEW_FORUM_YOUTH_CENTER',
  SURVEY_QUESTION_OVERVIEW_FORUM_PARENTS_FORUM: 'SURVEY_QUESTION_OVERVIEW_FORUM_PARENTS_FORUM',
  SURVEY_QUESTION_OVERVIEW_FORUM_GUY_FORUM: 'SURVEY_QUESTION_OVERVIEW_FORUM_GUY_FORUM',
  SURVEY_QUESTION_OVERVIEW_FORUM_WOMAN_FOR_BACKBY:
    'SURVEY_QUESTION_OVERVIEW_FORUM_WOMAN_FOR_BACKBY',
  SURVEY_QUESTION_OVERVIEW_FORUM_GIRL_FORUM: 'SURVEY_QUESTION_OVERVIEW_FORUM_GIRL_FORUM',
  SURVEY_QUESTION_OVERVIEW_FORUM_YOUTH_FORUM: 'SURVEY_QUESTION_OVERVIEW_FORUM_YOUTH_FORUM',
  SURVEY_QUESTION_OVERVIEW_FORUM_OTHERS: 'SURVEY_QUESTION_OVERVIEW_FORUM_OTHERS',
  SURVEY_QUESTION_CHAPTER_CARD: 'SURVEY_QUESTION_CHAPTER_CARD',
  SURVEY_QUESTION_CHAPTER_CARD_UNDERLINE: 'SURVEY_QUESTION_CHAPTER_CARD_UNDERLINE',
  SURVEY_QUESTION_QUESTION_CARD: 'SURVEY_QUESTION_QUESTION_CARD',
  SURVEYS_QUESTION_DELETE_TITLE: 'SURVEYS_QUESTION_DELETE_TITLE',
  SURVEYS_QUESTION_DELETE_SUBTITLE: 'SURVEYS_QUESTION_DELETE_SUBTITLE',
  SURVEY_QUESTION_QUESTION_TITLE: 'SURVEY_QUESTION_QUESTION_TITLE',
  SURVEY_QUESTION_QUESTION_TYPE: 'SURVEY_QUESTION_QUESTION_TYPE',
  SURVEY_QUESTION_QUESTION_ADITIONAL_CONTEXT: 'SURVEY_QUESTION_QUESTION_ADITIONAL_CONTEXT',
  SURVEY_QUESTION_QUESTION_ADITIONAL_CONTEXT_TITLE:
    'SURVEY_QUESTION_QUESTION_ADITIONAL_CONTEXT_TITLE',
  SURVEY_QUESTION_QUESTION_INDICATOR: 'SURVEY_QUESTION_QUESTION_INDICATOR',
  SURVEY_QUESTION_QUESTION_VALUE_OPTIONS: 'SURVEY_QUESTION_QUESTION_VALUE_OPTIONS',
  SURVEY_QUESTION_QUESTION_VALUE_OPTIONS_DESCRIPTION:
    'SURVEY_QUESTION_QUESTION_VALUE_OPTIONS_DESCRIPTION',
  SURVEY_QUESTION_QUESTION_TYPE_SMILEY: 'SURVEY_QUESTION_QUESTION_TYPE_SMILEY',
  SURVEY_QUESTION_QUESTION_TYPE_RADIO: 'SURVEY_QUESTION_QUESTION_TYPE_RADIO',
  SURVEY_QUESTION_QUESTION_TYPE_MULTI: 'SURVEY_QUESTION_QUESTION_TYPE_MULTI',
  SURVEY_QUESTION_QUESTION_SCALE_TITLE_A: 'SURVEY_QUESTION_QUESTION_SCALE_TITLE_A',
  SURVEY_QUESTION_QUESTION_SCALE_TITLE_B: 'SURVEY_QUESTION_QUESTION_SCALE_TITLE_B',
  DASHBOARD_TITLE: 'DASHBOARD_TITLE',
  DASHBOARD_SUBTITLE: 'DASHBOARD_SUBTITLE',
  USERS_TITLE: 'USERS_TITLE',
  USERS_SUBTITLE: 'USERS_SUBTITLE',
  USERS_INVITE_NEW_USER: 'USERS_INVITE_NEW_USER',
  INVITE_USER_TITLE: 'INVITE_USER_TITLE',
  INVITE_USER_DESCRIPTION: 'INVITE_USER_DESCRIPTION',
  INVITE_USER_BUTTON: 'INVITE_USER_BUTTON',
  INVITE_USER_CONFIRMATION: 'INVITE_USER_CONFIRMATION',
  INVITE_USER_BUTTON_CONFIRMATION: 'INVITE_USER_BUTTON_CONFIRMATION',
};

export default translateKeys;
