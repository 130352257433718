import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { X } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: '10',
    backgroundColor: theme.Colors.backgroundColor,
    ...theme.Elevations.high,
    padding: theme.Spacing.M,
    margin: 'auto',
  },
  iconClose: {
    width: '100',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    cursor: 'pointer',
  },
}));

function SCContainedModal({ children, onClose }) {
  const classes = useStyles();
  const theme = useTheme();
  const handleClose = () => {
    onClose();
  };
  return (
    <div className={classes.container}>
      <div className={classes.iconClose}>
        <X
          size="16"
          color={theme.Colors.neutral80}
          onClick={handleClose}
          className={classes.icon}
        />
      </div>
      {children}
    </div>
  );
}

SCContainedModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onClose: PropTypes.func,
};

SCContainedModal.defaultProps = {
  onClose: () => {},
};
export default SCContainedModal;
