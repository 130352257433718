const types = {
  CELL_TEXT: 'CELL_TEXT',
  CELL_SELECT: 'CELL_SELECT',
  CELL_ICON: 'CELL_ICON',
  CELL_HEADER: 'CELL_HEADER',
  CELL_STATUS: 'CELL_STATUS',
  CELL_OPTION: 'CELL_OPTION',
};

export default types;
