import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles((theme) => ({
  textStyle: {
    ...theme.Font.Paragraph.Paragraph3,
    color: theme.Colors.neutral60,
  },
  textHighlighStyle: {
    ...theme.Font.Paragraph.Paragraph1,
    color: theme.Colors.neutral80,
  },
}));

const TextCell = ({ key, align, children, highlight }) => {
  const classes = useStyles();
  return (
    <TableCell
      key={key}
      align={align}
      className={classnames(classes.textStyle, { [classes.textHighlighStyle]: highlight })}
    >
      {children}
    </TableCell>
  );
};

TextCell.propTypes = {
  key: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.string,
  highlight: PropTypes.bool,
};

TextCell.defaultProps = {
  key: '',
  align: 'center',
  children: '',
  highlight: false,
};

export default TextCell;
