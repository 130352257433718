import axios from 'axios';

const TOKEN_KEY = '@sc-auth-service/tokens';

if (!process.env.REACT_APP_STC_URL) {
  // eslint-disable-next-line no-console
  console.warn('StC server url is not set.');
}
const baseURL = process.env.REACT_APP_STC_URL
  ? `${process.env.REACT_APP_STC_URL}/api/`
  : 'https://stc.backend.com/api/';

const SCAxios = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// SSR - Need to check if localStorage if available
if (window.localStorage) {
  const tokens = JSON.parse(localStorage.getItem(TOKEN_KEY));
  if (tokens) {
    SCAxios.defaults.headers.Authorization = `Bearer ${tokens.accessToken}`;
  }
}

export function setSCToken(token) {
  SCAxios.defaults.headers.Authorization = `Bearer ${token}`;
}

export function removeSCToken() {
  SCAxios.defaults.headers.Authorization = null;
}

export default SCAxios;
