import React from 'react';
import PropTypes from 'prop-types';
import Report from 'powerbi-report-component';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  biSection: {
    width: '100%',
    height: '2500px',
    '& .report': {
      width: '100%',
      height: '100%',
    },
    '& iframe': {
      borderWidth: '0px',
    },
  },
}));

function PowerBISection({ accessToken, embedUrl, embedId }) {
  const classes = useStyles();

  const extraSettings = {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
  };
  return (
    <div className={classes.biSection}>
      <Report
        embedType="report"
        tokenType="Embed"
        accessToken={accessToken}
        embedUrl={embedUrl}
        embedId={embedId}
        extraSettings={extraSettings}
        permissions="View"
      />
    </div>
  );
}

PowerBISection.propTypes = {
  accessToken: PropTypes.string.isRequired,
  embedUrl: PropTypes.string.isRequired,
  embedId: PropTypes.string.isRequired,
};

export default PowerBISection;
