import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../../../utils/translations/translationKeys';
import SCButton from '../../../../components/Buttons/SCButton';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.Spacing.S,
    paddingBottom: theme.Spacing.S,
    background: 'transparent',
    display: 'flex',
    width: '100%',
  },
  innerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    height: '2px',
    flex: 1,
    background: theme.Colors.neutral20,
  },
  leftButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    ...theme.Font.Headline.H6,
    padding: theme.Spacing.XXS,
  },
  rightButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    ...theme.Font.Headline.H6,
    padding: theme.Spacing.XXS,
  },
}));

function SurveyCardSeparator({ handleAddQuestion, handleAddCard }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.separator} />
        <SCButton
          variant="outlined"
          color="primary"
          className={classes.leftButton}
          onClick={handleAddQuestion}
        >
          {t(translationKeys.SURVEYS_OVERVIEW_ADD_QUESTION)}
        </SCButton>
        <SCButton
          variant="outlined"
          color="primary"
          className={classes.rightButton}
          onClick={handleAddCard}
        >
          {t(translationKeys.SURVEYS_OVERVIEW_ADD_CARD)}
        </SCButton>
        <div className={classes.separator} />
      </div>
    </div>
  );
}

SurveyCardSeparator.propTypes = {
  handleAddQuestion: PropTypes.func,
  handleAddCard: PropTypes.func,
};

SurveyCardSeparator.defaultProps = {
  handleAddQuestion: () => {},
  handleAddCard: () => {},
};

export default SurveyCardSeparator;
