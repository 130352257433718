/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import SelectCell from './SelectCell';
import TextCell from './TextCell';
import IconCell from './IconCell';
import HeaderCell from './HeaderCell';
import CellTypes from './CellTypes';
import StatusCell from './StatusCell';
import OptionsCell from './OptionsCell';

function CellBuilder({ type, children, ...params }) {
  switch (type) {
    case CellTypes.CELL_TEXT:
      return <TextCell {...params}>{children}</TextCell>;
    case CellTypes.CELL_SELECT:
      return <SelectCell {...params}>{children}</SelectCell>;
    case CellTypes.CELL_ICON:
      return <IconCell {...params}>{children}</IconCell>;
    case CellTypes.CELL_HEADER:
      return <HeaderCell {...params}>{children}</HeaderCell>;
    case CellTypes.CELL_STATUS:
      return <StatusCell {...params} />;
    case CellTypes.CELL_OPTION:
      return <OptionsCell {...params}>{children}</OptionsCell>;
    default:
      return <TextCell {...params}>{children}</TextCell>;
  }
}

CellBuilder.propTypes = {
  type: PropTypes.oneOf(Object.keys(CellTypes)),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

CellBuilder.defaultProps = {
  type: CellTypes.CELL_TEXT,
  children: '',
};

export default CellBuilder;
