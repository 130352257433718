import { createMuiTheme } from '@material-ui/core/styles';

const colors = {
  mainColor: '#ececec',
  secondaryColor: '#c3c3c3',
  accentColor: '#ababab',
  backgroundColor: '#FFFFFF',

  alertColor: '#f44336',

  brandAccent: '#da291c',
  neutral100: '#27262d',
  neutral80: '#67676c',
  neutral60: '#a9a8ab',
  neutral40: '#d4d4d5',
  neutral20: '#e9e9ea',
  background: '#FFFFFF',
  highlight: '#009ca6',
  highlightOpacity: 'rgba(0, 156, 166, 0.2)',
  indicatorPlum: '#9a3324',
  inditacorOrange: '#fc4c02',
  indicatorMustard: '#f2a900',

  backgroundDark: '#FBFBFB',
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.highlight,
    },
    secondary: {
      main: colors.background,
    },
  },
  typography: {
    fontFamily: [
      'GillSans',
      'TradeGothicLT',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  Colors: {
    ...colors,
  },
  Font: {
    Hero: {
      fontFamily: 'TradeGothicLT',
      fontSize: '90px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      lineHeight: '1.11',
      letterSpacing: 'normal',
    },
    Headline: {
      H1: {
        fontSize: '56px',
        lineHeight: '1.07',
        letterSpacing: 'normal',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'TradeGothicLT',
      },
      H2: {
        fontSize: '44px',
        lineHeight: '1.09',
        letterSpacing: 'normal',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'TradeGothicLT',
      },
      H3: {
        fontSize: '32px',
        lineHeight: '1.13',
        letterSpacing: 'normal',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'TradeGothicLT',
      },
      H4: {
        fontSize: '24px',
        lineHeight: '1.17',
        letterSpacing: 'normal',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'TradeGothicLT',
      },
      H5: {
        fontSize: '20px',
        lineHeight: '1.1',
        letterSpacing: 'normal',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'TradeGothicLT',
      },
      H6: {
        fontSize: '16px',
        lineHeight: '1.13',
        letterSpacing: 'normal',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'TradeGothicLT',
      },
    },
    Paragraph: {
      Paragraph1: {
        fontFamily: 'GillSans',
        fontSize: '20px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: 'normal',
      },
      Paragraph2: {
        fontFamily: 'GillSans',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '20px',
        letterSpacing: 'normal',
      },
      Paragraph3: {
        fontFamily: 'GillSans',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '18px',
        letterSpacing: 'normal',
      },
      Paragraph4: {
        fontFamily: 'GillSans',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '16px',
        letterSpacing: 'normal',
      },
    },
    Buttons: {
      Button1: {
        fontFamily: 'TradeGothicLT',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: '1.14',
        letterSpacing: '0.6px',
      },
    },
  },
  Spacing: {
    XXS: '8px',
    XS: '12px',
    S: '24px',
    M: '28px',
    L: '40px',
    XL: '48px',
  },
  Grid: {
    Width: {
      Medium: '392px',
      Big: '808px',
    },
  },
  Elevations: {
    small: {
      boxShadow: '0 2px 2px 0 rgba(39, 38, 45, 0.12)',
    },
    medium: {
      boxShadow: '0 8px 8px 0 rgba(39, 38, 45, 0.12)',
    },
    high: {
      boxShadow: '0 16px 16px 0 rgba(39, 38, 45, 0.12)',
    },
  },
});

export default theme;
