import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import translationKeys from '../../../utils/translations/translationKeys';
import SCTextFieldDefault from '../../../components/Form/Fields/TextFields/SCTextFieldDefault';
import ErrorField from '../../../components/Form/Errors/ErrorField';
import SCButton from '../../../components/Buttons/SCButton';
import SCModalSuccess from '../../../components/Modal/SCModalSuccess';
import Paths from '../../../router/router';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.Spacing.M,
  },
  textHeader: {
    textAlign: 'left',
    margin: 0,
    textTransform: 'uppercase',
    ...theme.Font.Headline.H4,
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.L,
  },
  formContainer: {},
}));

function ProfileDetailsForm({ user }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  async function onSubmit(values) {
    const response = await dispatch.userModel.updateMyData({
      name: values.name,
      surname: values.surname,
    });
    if (!response.message) {
      setIsModalOpen(true);
    }
    return response;
  }

  function handleClickSuccess() {
    setIsModalOpen(false);
    history.push(Paths.ROOT);
  }

  function isActive(values) {
    return (
      values.name !== undefined &&
      values.name !== '' &&
      values.surname !== undefined &&
      values.surname !== ''
    );
  }

  return (
    <div className={classes.formContainer}>
      <SCModalSuccess
        isOpen={isModalOpen}
        title={t(translationKeys.PROFILE_DETAILS_UPDATE_SUCCESS)}
        subtitle={t(translationKeys.PROFILE_DETAILS_UPDATE_SUCCESS_SUBTITLE)}
        buttonText={t(translationKeys.GENERAL_GO_TO_MAINPAGE)}
        onClick={handleClickSuccess}
      />
      <h4 className={classes.textHeader}>{t(translationKeys.PROFILE_DETAILS_TITLE)}</h4>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          name: user.name,
          surname: user.surname,
          email: user.email,
          organization: user.company,
        }}
      >
        {({ handleSubmit, submitError, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <SCTextFieldDefault
              placeholder={t(translationKeys.GENERAL_FIRSTNAME)}
              name="name"
              value={user.name}
              label={t(translationKeys.GENERAL_FIRSTNAME)}
            />
            <SCTextFieldDefault
              placeholder={t(translationKeys.GENERAL_SURNAME)}
              name="surname"
              value={user.surname}
              label={t(translationKeys.GENERAL_SURNAME)}
            />
            <SCTextFieldDefault
              placeholder={t(translationKeys.GENERAL_EMAIL)}
              helperText={t(translationKeys.CREATE_ACCOUNT_EMAIL_INFO)}
              disabled
              name="email"
              label={t(translationKeys.GENERAL_EMAIL)}
            />
            <SCTextFieldDefault
              placeholder={t(translationKeys.GENERAL_ORGANIZATION)}
              disabled
              name="organization"
              label={t(translationKeys.GENERAL_ORGANIZATION)}
            />
            {submitError && <ErrorField>{submitError}</ErrorField>}
            <div className={classes.buttons}>
              <SCButton
                variant="contained"
                type="submit"
                color="primary"
                disabled={submitting || pristine || !isActive(values)}
                loading={submitting}
              >
                {t(translationKeys.PROFILE_SAVE_DETAILS)}
              </SCButton>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
}

ProfileDetailsForm.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    role: PropTypes.string,
    company: PropTypes.string,
    status: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default ProfileDetailsForm;
