import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SCCard from '../../../../components/Cards/SCCard';
import SCSelectFieldDefault from '../../../../components/Form/Fields/Inputs/SCSelectFieldDefault';
import SCSelectFieldIcon from '../../../../components/Form/Fields/Inputs/SCSelectFieldIcon';
import SCTextFieldDefault from '../../../../components/Form/Fields/TextFields/SCTextFieldDefault';
import SCDatepickerFieldDefault from '../../../../components/Form/Fields/Inputs/SCDatepickerFieldDefault';
import SCCheckBoxFieldDefault from '../../../../components/Form/Fields/Inputs/SCCheckBoxFieldDefault';
import translationKeys from '../../../../utils/translations/translationKeys';
import { QUESTIONTYPE, QUESTIONACTIVITYSUBTYPE, FORUMOPTIONS } from '../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.Spacing.L,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.Spacing.L} ${theme.Spacing.XL}`,
  },
  selectField: {
    width: '344px',
  },
  firstSelectField: {
    marginRight: theme.Spacing.S,
  },
  horizontalField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textHeader: {
    ...theme.Font.Headline.H4,
    textTransform: 'uppercase',
  },
}));

function SurveyCardHeader({ initialValues, values }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const citiesList = useSelector((state) => state.citiesModel.cities);
  const companiesList = useSelector((state) => state.companiesModel.companies);

  async function fetchCities() {
    const response = await dispatch.citiesModel.getCities();
    return response;
  }

  async function fetchCompanies() {
    const response = await dispatch.companiesModel.getCompanies();
    return response;
  }

  useEffect(() => {
    fetchCities();
    fetchCompanies();
    // eslint-disable-next-line
  }, []);

  return (
    <SCCard className={classes.card}>
      <div className={classes.container}>
        <h3 className={classes.textHeader}>{t(translationKeys.SURVEYS_OVERVIEW_DETAILS)}</h3>
        <SCTextFieldDefault
          name="name"
          label={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_NAME)}
          placeholder={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_NAME)}
          helperText={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_NAME_HELPER_TEXT)}
          required
        />
        <SCTextFieldDefault
          name="description"
          label={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_DESCRIPTION)}
          placeholder={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_DESCRIPTION)}
          helperText={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_DESCRIPTION_HELPER_TEXT)}
          minLength={20}
        />
        <div className={classes.horizontalField}>
          <SCSelectFieldIcon
            name="type"
            label={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_TYPE)}
            className={classnames(classes.selectField, classes.firstSelectField)}
            options={[
              {
                value: QUESTIONTYPE.ACTIVITY_SURVEY,
                text: t(translationKeys.SURVEYS_OVERVIEW_ACTIVITY_JOURNEY),
              },
              {
                value: QUESTIONTYPE.LIFEJOURNEY_SURVEY,
                text: t(translationKeys.SURVEYS_OVERVIEW_LIFE_JOURNEY),
              },
            ]}
            required
          />
          <SCDatepickerFieldDefault
            name="date"
            label={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_DATE)}
            className={classes.selectField}
          />
        </div>
        {values.type === QUESTIONTYPE.ACTIVITY_SURVEY && (
          <SCSelectFieldIcon
            name="subtype"
            label={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_SUBTYPE)}
            className={classnames(classes.selectField, classes.firstSelectField)}
            options={[
              {
                value: QUESTIONACTIVITYSUBTYPE.WORKSHOP,
                text: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_SUBTYPE_WORKSHOP),
              },
              {
                value: QUESTIONACTIVITYSUBTYPE.LECTURE,
                text: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_SUBTYPE_LECTURE),
              },
              {
                value: QUESTIONACTIVITYSUBTYPE.TRYON_ACTIVITY,
                text: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_SUBTYPE_TRYON_ACTIVITY),
              },
              {
                value: QUESTIONACTIVITYSUBTYPE.TEAM_BUILDING,
                text: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_SUBTYPE_TEAM_BUILDING),
              },
              {
                value: QUESTIONACTIVITYSUBTYPE.STUDY_VISITS,
                text: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_SUBTYPE_STUDY_VISITS),
              },
              {
                value: QUESTIONACTIVITYSUBTYPE.PROJECT_WORK,
                text: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_SUBTYPE_PROJECT_WORK),
              },
              {
                value: QUESTIONACTIVITYSUBTYPE.PARTY,
                text: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_SUBTYPE_PARTY),
              },
              {
                value: QUESTIONACTIVITYSUBTYPE.SOCIAL_ACTIVITY,
                text: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_SUBTYPE_SOCIAL_ACTIVITY),
              },
              {
                value: QUESTIONACTIVITYSUBTYPE.OTHER,
                text: t(translationKeys.SURVEYS_OVERVIEW_SURVEY_SUBTYPE_OTHER),
              },
            ]}
            required
          />
        )}
        <div className={classes.horizontalField}>
          <SCSelectFieldDefault
            name="area"
            label={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_AREA)}
            className={classnames(classes.selectField, classes.firstSelectField)}
            text={initialValues.area && initialValues.area.name}
            options={citiesList.map((city) => {
              return { value: city.id, text: city.name };
            })}
            required
          />
          <SCSelectFieldDefault
            name="forum"
            label={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_FORUM)}
            className={classes.selectField}
            options={[
              {
                value: FORUMOPTIONS.HEALTHY_GENERATION,
                text: t(translationKeys.SURVEY_QUESTION_OVERVIEW_FORUM_HEALTHY_GENERATION),
              },
              {
                value: FORUMOPTIONS.FOLK_HIGH_SCHOOL,
                text: t(translationKeys.SURVEY_QUESTION_OVERVIEW_FORUM_FOLK_HIGH_SCHOOL),
              },
              {
                value: FORUMOPTIONS.YOUTH_CENTER,
                text: t(translationKeys.SURVEY_QUESTION_OVERVIEW_FORUM_YOUTH_CENTER),
              },
              {
                value: FORUMOPTIONS.PARENTS_FORUM,
                text: t(translationKeys.SURVEY_QUESTION_OVERVIEW_FORUM_PARENTS_FORUM),
              },
              {
                value: FORUMOPTIONS.GUY_FORUM,
                text: t(translationKeys.SURVEY_QUESTION_OVERVIEW_FORUM_GUY_FORUM),
              },
              {
                value: FORUMOPTIONS.WOMAN_FOR_BACKBY,
                text: t(translationKeys.SURVEY_QUESTION_OVERVIEW_FORUM_WOMAN_FOR_BACKBY),
              },
              {
                value: FORUMOPTIONS.GIRL_FORUM,
                text: t(translationKeys.SURVEY_QUESTION_OVERVIEW_FORUM_GIRL_FORUM),
              },
              {
                value: FORUMOPTIONS.YOUTH_FORUM,
                text: t(translationKeys.SURVEY_QUESTION_OVERVIEW_FORUM_YOUTH_FORUM),
              },
              {
                value: FORUMOPTIONS.OTHERS,
                text: t(translationKeys.SURVEY_QUESTION_OVERVIEW_FORUM_OTHERS),
              },
            ]}
            required
          />
        </div>
        <div className={classes.horizontalField}>
          <SCSelectFieldDefault
            name="partners"
            label={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_PARTNERS)}
            helperText={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_PARTNERS_HELPERTEXT)}
            className={classnames(classes.selectField, classes.firstSelectField)}
            text={initialValues.partners && initialValues.partners.name}
            options={companiesList.map((company) => {
              return { value: company.uid, text: company.name };
            })}
            required
          />
          <SCCheckBoxFieldDefault
            name="significant"
            label={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_BADGE)}
            className={classes.selectField}
            helperText={t(translationKeys.SURVEYS_OVERVIEW_SURVEY_BADGE_HELPERTEXT)}
          />
        </div>
      </div>
    </SCCard>
  );
}

SurveyCardHeader.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.string,
    area: PropTypes.object,
    forum: PropTypes.string,
    partners: PropTypes.object,
    cities: PropTypes.string,
    significant: PropTypes.bool,
    type: PropTypes.string,
  }),
  values: PropTypes.shape({
    type: PropTypes.string,
  }),
};

SurveyCardHeader.defaultProps = {
  initialValues: {},
  values: {},
};

export default SurveyCardHeader;
