import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import PowerBISection from './PowerBISection';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },
  innerContainer: {
    padding: `${theme.Spacing.L} ${theme.Spacing.M}`,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  biContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    ...theme.Font.Headline.H2,
    textTransform: 'uppercase',
    color: theme.Colors.neutral100,
    marginBottom: theme.Spacing.M,
  },
  subtitle: {
    ...theme.Font.Paragraph.Paragraph2,
    color: theme.Colors.neutral60,
    marginBottom: theme.Spacing.XL,
  },
  biSection: {
    width: '100%',
    height: '100%',
  },
}));

function DashBoardPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const biTokens = useSelector((state) => state.biModel.tokens);
  useEffect(() => {
    dispatch.biModel.getTokens();
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.biContainer}>
          {biTokens.token && (
            <PowerBISection
              accessToken={biTokens.token}
              embedUrl={process.env.REACT_APP_POWERBI_URL}
              embedId={process.env.REACT_APP_POWERBI_ID}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DashBoardPage;
