import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../utils/translations/translationKeys';
import styles from './login-styles';
import PublicMainComponent from '../PublicMainComponent/PublicMainComponent';
import LoginForm from './LoginForm';

const useStyles = makeStyles(styles);
function LoginPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);

  return (
    <PublicMainComponent>
      <div className={classes.container}>
        <div className={classes.background} />
        <div className={classes.leftContainer}>
          <LoginForm
            setForgotPasswordOpen={setForgotPasswordOpen}
            forgotPasswordOpen={forgotPasswordOpen}
            forgotPasswordSuccess={forgotPasswordSuccess}
            setForgotPasswordSuccess={setForgotPasswordSuccess}
          />
        </div>
        <div className={classes.rightContainer}>
          <h3 className={classes.subtitle}>{t(translationKeys.GENERAL_SUBTITLE)}</h3>
          <h1 className={classes.title}>{t(translationKeys.GENERAL_TITLE)}</h1>
          <p className={classes.paragraph}>{t(translationKeys.LOGIN_TEXT)}</p>
          <p className={classes.smallParagraph}>
            {t(translationKeys.LOGIN_SUBTEXT)}
            <a className={classes.aText} href={t(translationKeys.LOGIN_OUR_URL)}>
              {t(translationKeys.LOGIN_OUR_URL_TEXT)}
            </a>
          </p>
        </div>
      </div>
    </PublicMainComponent>
  );
}

export default LoginPage;
