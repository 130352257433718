import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tabs, Tab, Avatar, Menu, MenuItem } from '@material-ui/core/';
import { Settings, Power } from 'react-feather';
import Paths from '../../router/router';
import translationKeys from '../../utils/translations/translationKeys';
import logoIconSimple from '../../assets/icons/logo-icon-light.svg';
import { ROLE } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    padding: `0`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: theme.Colors.backgroundColor,
    ...theme.Elevations.small,
  },
  leftHeaderContainer: {
    padding: `${theme.Spacing.XS} 0`,
    marginLeft: theme.Spacing.M,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  text: {
    color: 'black',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  title: {
    ...theme.Font.Headline.H5,
    margin: 0,
    marginLeft: theme.Spacing.XS,
    color: theme.Colors.neutral100,
    textTransform: 'uppercase',
  },
  subtitle: {
    ...theme.Font.Headline.H5,
    margin: 0,
    marginLeft: theme.Spacing.S,
    color: theme.Colors.neutral100,
    fontWeight: '100',
  },
  tabsContainerRoot: {
    height: '100%',
  },
  tabsContainerFlexContainer: {
    height: '100%',
  },
  tab: {
    ...theme.Font.Paragraph.Paragraph2,
    color: theme.Colors.neutral80,
    textTransform: 'none',
    minWidth: '120px',
  },
  tabSelected: {
    color: theme.Colors.brandAccent,
  },
  iconTab: {
    minWidth: '40px',
    display: 'flex',
    justifyContent: 'center',
    padding: `0 ${theme.Spacing.S} 0 ${theme.Spacing.S}`,
  },
  beforeDividerTab: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
  divider: {
    margin: 0,
  },
  avatar: {
    width: '44px',
    height: '44px',
    cursor: 'pointer',
    color: theme.Colors.highlight,
    background: theme.Colors.highlightOpacity,
    ...theme.Font.Headline.H5,
  },
  menu: {
    background: theme.Colors.neutral100,
    color: theme.Colors.neutral60,
    borderRadius: '0',
    right: 0,
    '& li:hover': {
      color: theme.Colors.backgroundColor,
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  menuItemSelected: {
    color: theme.Colors.backgroundColor,
  },
  menuItemText: {
    margin: 0,
    marginLeft: theme.Spacing.XXS,
    ...theme.Font.Paragraph.Paragraph2,
  },
  tabsIndicator: {
    background: theme.Colors.brandAccent,
  },
}));
function getActualTab(path) {
  switch (path) {
    case Paths.DASHBOARD:
      return 0;
    case Paths.SURVEYS:
    case Paths.ROOT:        
      return 1;
    case Paths.USERS:
      return 2;
    case Paths.PROFILE:
      return 3;
    default:
      return false;
  }
}
function PrivateMainComponentHeader() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userModel.userInfo);
  const history = useHistory();
  const [actualTab, setActualTab] = useState(getActualTab(history.location.pathname));
  const [anchorMenu, setAnchorMenu] = useState(null);
  const topBarRef = useRef(null);
  const tabPaths = {
    0: Paths.DASHBOARD,
    1: Paths.SURVEYS,
    2: Paths.USERS,
    3: Paths.PROFILE,
  };
  useEffect(() => {
    setActualTab(getActualTab(history.location.pathname));
  }, [history.location.pathname]);

  const handleChangeTab = (event, newValue) => {
    if (newValue < 3) {
      setActualTab(newValue);
      history.push(tabPaths[newValue]);
    }
  };

  const handleOpenMenu = () => {
    setAnchorMenu(topBarRef.current);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  const handleClickSettings = () => {
    handleCloseMenu();
    setActualTab(3);
    history.push(Paths.PROFILE);
  };

  const handleClickLogout = async () => {
    handleCloseMenu();
    const response = await dispatch.userModel.logout();
    if (response) {
      history.replace(Paths.LOGIN);
    }
  };

  function getIcon(name, surname) {
    if (name) {
      if (surname) {
        return `${name[0]}${surname[0]}`;
      }
      return name[0];
    }
    return '-';
  }

  return (
    <div className={classes.headerContainer} ref={topBarRef}>
      <div className={classes.leftHeaderContainer}>
        <img src={logoIconSimple} alt="logo" />
        <div className={classes.logoText}>
          <p className={classes.subtitle}>{t(translationKeys.GENERAL_SUBTITLE)}</p>
        </div>
      </div>
      <div className={classes.rightHeaderContainer}>
        <Tabs
          classes={{
            root: classes.tabsContainerRoot,
            indicator: classes.tabsIndicator,
            flexContainer: classes.tabsContainerFlexContainer,
          }}
          value={actualTab}
          onChange={handleChangeTab}
        >
          {userProfile.role === ROLE.ADMIN && (
            <Tab
              classes={{ root: classes.tab, selected: classes.tabSelected }}
              label={t(translationKeys.GENERAL_SURVEYS)}
              value={1}
            />
          )}
          {userProfile.role === ROLE.ADMIN && (
            <Tab
              classes={{
                root: classnames(classes.tab, classes.beforeDividerTab),
                selected: classes.tabSelected,
              }}
              label={t(translationKeys.GENERAL_USERSANDPERMISSIONS)}
              value={2}
            />
          )}
          <Tab
            classes={{ root: classes.iconTab, selected: classes.tabSelected }}
            onClick={handleOpenMenu}
            label={
              <Avatar className={classes.avatar} sizes="44">
                {getIcon(userProfile.name, userProfile.surname)}
              </Avatar>
            }
            value={3}
          />
        </Tabs>
        <Menu
          elevation={1}
          anchorEl={anchorMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          open={Boolean(anchorMenu)}
          onClose={handleCloseMenu}
          classes={{ paper: classes.menu }}
        >
          <MenuItem onClick={handleClickSettings}>
            <div
              className={classnames(classes.menuItem, {
                [classes.menuItemSelected]: history.location.pathname === Paths.PROFILE,
              })}
            >
              <Settings size={16} />
              <p className={classes.menuItemText}>{t(translationKeys.GENERAL_SETTINGS)}</p>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClickLogout}>
            <div className={classes.menuItem}>
              <Power size={16} />
              <p className={classes.menuItemText}>{t(translationKeys.GENERAL_LOGOUT)}</p>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default PrivateMainComponentHeader;
