import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../../../utils/translations/translationKeys';
import SCButton from '../../../../components/Buttons/SCButton';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    padding: `${theme.Spacing.XS} ${theme.Spacing.M}`,
    background: theme.Colors.highlight,
    color: theme.Colors.background,
  },
  leftContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftText: {
    ...theme.Font.Paragraph.Paragraph1,
    marginLeft: theme.Spacing.L,
  },
  icon: {
    cursor: 'pointer',
  },
  button: {
    width: '184px',
    height: '40px',
    borderRadius: '2px',
    ...theme.Font.Headline.H6,
  },
  buttonSecondary: {
    marginLeft: theme.Spacing.S,
    color: theme.Colors.highlight,
  },
}));

function SurveyEditHeader({ handleBackButton, dirty, ableToPublish, form }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <ArrowLeft onClick={handleBackButton} className={classes.icon} />
        <p className={classes.leftText}>{t(translationKeys.GENERAL_EXIT)}</p>
      </div>
      <div className={classes.rightContainer}>
        <SCButton
          variant="outlined"
          type="submit"
          color="secondary"
          className={classes.button}
          onClick={() => {
            form.change('publishSubmit', false);
          }}
          disabled={!dirty}
        >
          {t(translationKeys.SURVEYS_OVERVIEW_SAVE_AS_DRAFT)}
        </SCButton>
        <SCButton
          variant="contained"
          type="submit"
          color="secondary"
          className={classnames(classes.button, classes.buttonSecondary)}
          onClick={() => {
            form.change('publishSubmit', true);
          }}
          disabled={!ableToPublish}
        >
          {t(translationKeys.GENERAL_PUBLISH)}
        </SCButton>
      </div>
    </div>
  );
}

SurveyEditHeader.propTypes = {
  handleBackButton: PropTypes.func,
  dirty: PropTypes.bool,
  ableToPublish: PropTypes.bool,
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
};

SurveyEditHeader.defaultProps = {
  handleBackButton: () => {},
  dirty: false,
  ableToPublish: false,
  form: {},
};

export default SurveyEditHeader;
