import BIService from '../services/BIService';
import { handleError, Handlers } from '../services/helpers/error-handling';

const biModel = {
  name: 'biModel',
  state: {
    tokens: {},
  },
  reducers: {
    setBITokens: (state, payload) => {
      return {
        ...state,
        tokens: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getTokens() {
      try {
        const response = await BIService.getTokens();
        dispatch.biModel.setBITokens(response);
        return response;
      } catch (error) {
        return handleError(error, {
          'bi-token-error': Handlers.RETURN_DATA,
          default: Handlers.RETURN_DATA,
        });
      }
    },
  }),
};

export default biModel;
