import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../../../utils/translations/translationKeys';
import { isEmail } from '../../../../utils/validators';
import SCTextField from '../SCTextField';

const SCTextFieldEmail = ({ disabled, placeholder, value, helperText, label, errorOnLive }) => {
  const { t } = useTranslation();

  function validateEmail(text) {
    if (!text || text === '') {
      return t(translationKeys.GENERAL_REQUIRED);
    }
    if (!isEmail(text)) {
      return t(translationKeys.GENERAL_EMAIL_INVALID);
    }
    return null;
  }

  return (
    <Field
      name="email"
      render={({ input, meta }) => (
        <SCTextField
          name={input.name}
          onChange={input.onChange}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          value={value || input.value}
          label={label || t(translationKeys.GENERAL_EMAIL)}
          disabled={disabled}
          showError={meta.touched && meta.error !== undefined && (errorOnLive || meta.submitFailed)}
          placeholder={placeholder}
          error={meta.error}
          helperText={helperText}
        />
      )}
      validate={!disabled ? validateEmail : undefined}
    />
  );
};

SCTextFieldEmail.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  errorOnLive: PropTypes.bool,
  helperText: PropTypes.string,
};

SCTextFieldEmail.defaultProps = {
  disabled: false,
  placeholder: '',
  value: undefined,
  label: undefined,
  errorOnLive: false,
  helperText: '',
};

export default SCTextFieldEmail;
